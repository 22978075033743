import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons/faInfoCircle";


export const InfoMessage = (props) => {

    return (
        <div className="p-message p-component p-message-info enter-done" data-pc-name="messages" data-pc-section="root">
            <div className="p-message-wrapper" data-pc-section="wrapper">
                <span className="p-message-summary" data-pc-section="summary"><FontAwesomeIcon icon={faInfoCircle} /></span>
                <span className="p-message-detail" data-pc-section="detail">{props.message}</span>
            </div>
        </div>
    )
}