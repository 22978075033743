import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';

import userAuth from './AuthReducer';
import config from './ConfigReducer';
import topup from './MopriPaymentReducer';
import mopriSettings from './MopriSettingReducer';
import reportData from './ReportReducer';
import theme from './ThemeReducer'


const mainReducer = combineReducers({
    userAuth,
    config,
    topup,
    mopriSettings,
    reportData,
    theme
});

export default reduceReducers(mainReducer)

