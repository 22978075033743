import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


export const DailyPrintChart = (props) => {

    const getLast12Months = () => {
        const months = [];
        const currentDate = new Date();

        for (let i = 0; i < 12; i++) {
            let month = currentDate.getMonth() - i;
            let year = currentDate.getFullYear();

            // Adjust month and year if necessary
            if (month < 0) {
                month += 12;
                year -= 1;
            }

            const monthName = new Date(year, month).toLocaleString('default', { month: 'long' });
            const monthYear = `${monthName} ${year}`;

            months.push(monthYear);
        }

        return months;
    }

    const getPrintedData = (labels, history) => {
        let data = [];
        labels.forEach((lbl) => {
            history && history.forEach((item) => {
                if (lbl === item.Month + ' ' + item.Year) {
                    data.push(item.qty)
                } else {
                    data.push(0)
                }
            })
        })

        if (data.length === 0) {
            data = [0,0,0,0,0,0,0,0,0,0,0,0]
        }

        return data
    }

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: '',
            },
        },
    };

    const labels = getLast12Months();

    const data = {
        labels,
        datasets: [
            {
                label: 'Printed',
                data: getPrintedData(labels, props.printHistory),
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Copied',
                data: getPrintedData(labels, props.copyHistory),
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };

    return(
        <Bar options={options} data={data} />
    )
}

export default DailyPrintChart;
