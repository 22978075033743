import React, {useRef, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Toast} from "primereact/toast";
import {Controller, useForm} from "react-hook-form";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {faCreditCard} from "@fortawesome/free-solid-svg-icons/faCreditCard";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import qs from "qs";
import {isBoolean} from "../../../../Utils/HelperFunctions";
import {updateGatewaySettings} from "../../../../Actions/MopriSettingActions";


const payFastStatuses = [{value: true, description: 'Active'}, {value: false, description: 'Inactive'}];
const payFastEnvironments = [{value: true, description: 'Sandbox Environment'}, {value: false, description: 'Live Environment'}];
const boolSelect = [{value: true, description: 'Yes'}, {value: false, description: 'No'}];

export const PayFastSettings= (props) =>  {
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const themeSettings = useSelector(state => state.theme);
    const gatewaySettings = useSelector(state => state.mopriSettings.gatewaySettings);
    const apiURL = useSelector(state => state.mopriSettings.mopriAPIURL);
    const myToast = useRef(null)
    const dispatch = useDispatch();



    const { control, handleSubmit, watch,
        formState: { errors } } = useForm({
        defaultValues: {
            payFastActive: gatewaySettings.payFastActive ? isBoolean(gatewaySettings.payFastActive) : false,
            payFastSandbox: gatewaySettings.payFastSandbox ? isBoolean(gatewaySettings.payFastSandbox) : false,
            payFastMerchId: gatewaySettings.payFastMerchId,
            payFastAPIKey: gatewaySettings.payFastAPIKey
            /*payFastEFTActive: true,
            payFastCcardActive: true,
            payFastMobicredActive: true,
            payFastZapperActive: true,
            payFastMasterPassActive: true,
            payFastScodeActive: true,
            payFastVisaElectronActive: true*/
        }
    })

    const onSubmit = (data) => {
        setSubmitButtonDisabled(true)
        axios({
            method: 'post',
            url: `${apiURL}/api/gateway/updatePayFast`,
            data: qs.stringify({
                ...data
            }),
            config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
        })
            .then((response) => {
                if (response && response.data.error) {
                    myToast.current.show({severity: 'error', summary: 'ERROR', detail: response.data.errorMessage, life: 5000});
                } else {
                    myToast.current.show({severity: 'success', summary: 'Success', detail: 'PayFast settings updated', life: 5000});
                    dispatch(updateGatewaySettings(data))
                }
                setSubmitButtonDisabled(false)
            })
            .catch((e) => {
                setSubmitButtonDisabled(false)
                myToast.current.show({severity: 'error', summary: 'ERROR', detail: `An unexpected error took place`, life: 5000});
                console.log(e);
            });
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const payFastServiceActive = watch("payFastActive")

    return (
        <>
            <Toast ref={myToast} />
            <div className="p-fluid grid formgrid">
                <div className="field col-12">
                    <h4><FontAwesomeIcon icon={faCreditCard} style={{paddingRight: '10px'}}/> PayFast Settings</h4>
                </div>
            </div>

            <div className="p-fluid grid formgrid">
                <div className="field col-12">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="p-fluid grid formgrid" >
                            <div className="field col-6">
                                <span className="p-float-label" style={{fontSize: '12px', color: "#6c757d", paddingBottom: '5px'}}>
                                     PayFast Status
                                </span>
                                <Controller name="payFastActive" control={control}  //rules={{ required: 'Required.' }}
                                            render={({ field, fieldState }) => (
                                                <Dropdown value={field.value} id={field.name}
                                                          options={payFastStatuses} optionLabel="description"
                                                          optionValue="value"
                                                          onChange={(e) => {
                                                              field.onChange(e.value)
                                                          }}
                                                />
                                            )}
                                />
                                {getFormErrorMessage('payFastActive')}
                            </div>
                            <div className="field col-6">
                                {payFastServiceActive &&
                                    <>
                                        <span className="p-float-label" style={{fontSize: '12px', color: "#6c757d", paddingBottom: '5px'}}>
                                             PayFast Sandbox Test Environment
                                        </span>
                                        <Controller name="payFastSandbox" control={control}  //rules={{ required: 'Required.' }}
                                                    render={({ field, fieldState }) => (
                                                        <Dropdown value={field.value} id={field.name}
                                                                  options={payFastEnvironments} optionLabel="description"
                                                                  optionValue="value"
                                                                  onChange={(e) => {
                                                                      field.onChange(e.value)
                                                                  }}
                                                        />
                                                    )}
                                        />
                                        {getFormErrorMessage('payFastSandbox')}
                                    </>
                                }
                            </div>
                        </div>
                        {payFastServiceActive &&
                            <>
                                <div className="p-fluid grid formgrid" >
                                    <div className="field col-6" style={{paddingTop: '27px'}}>
                                        <span className="p-float-label">
                                            <Controller name="payFastMerchId" control={control}
                                                        render={({ field, fieldState }) => (
                                                            <InputText id={field.name} {...field}
                                                            />
                                                        )}
                                            />
                                            <label htmlFor="payFastMerchId">PayFast Merchant ID</label>
                                        </span>
                                        {getFormErrorMessage('payFastMerchId')}
                                    </div>
                                    <div className="field col-6" style={{paddingTop: '27px'}}>
                                    <span className="p-float-label">
                                        <Controller name="payFastAPIKey" control={control}
                                                    render={({ field, fieldState }) => (
                                                        <InputText id={field.name} {...field}
                                                        />
                                                    )}
                                        />
                                        <label htmlFor="payFastAPIKey">PayFast API Key</label>
                                    </span>
                                        {getFormErrorMessage('payFastAPIKey')}
                                    </div>
                                </div>

                                {/*<h5>PayFast Payment Types</h5>
                                <p>Please ensure that the payments options below are enabled in your PayFast account before activating them on Mopri</p>


                                <div className="p-fluid grid formgrid" >
                                    <div className="field col-3">
                                        <span className="p-float-label" style={{fontSize: '12px', color: "#6c757d", paddingBottom: '5px'}}>
                                             Check & Credit Cards
                                        </span>
                                        <Controller name="payFastCcardActive" control={control}  rules={{ required: 'Required.' }}
                                                    render={({ field, fieldState }) => (
                                                        <Dropdown value={field.value} id={field.name}
                                                                  options={boolSelect} optionLabel="description"
                                                                  optionValue="value"
                                                                  onChange={(e) => {
                                                                      field.onChange(e.value)
                                                                  }}
                                                        />
                                                    )}
                                        />
                                        {getFormErrorMessage('payFastCcardActive')}
                                    </div>

                                    <div className="field col-3">
                                        <span className="p-float-label" style={{fontSize: '12px', color: "#6c757d", paddingBottom: '5px'}}>
                                             Instant EFT
                                        </span>
                                        <Controller name="payFastEFTActive" control={control}  rules={{ required: 'Required.' }}
                                                    render={({ field, fieldState }) => (
                                                        <Dropdown value={field.value} id={field.name}
                                                                  options={boolSelect} optionLabel="description"
                                                                  optionValue="value"
                                                                  onChange={(e) => {
                                                                      field.onChange(e.value)
                                                                  }}
                                                        />
                                                    )}
                                        />
                                        {getFormErrorMessage('payFastEFTActive')}
                                    </div>

                                    <div className="field col-3">
                                        <span className="p-float-label" style={{fontSize: '12px', color: "#6c757d", paddingBottom: '5px'}}>
                                             Instant EFT
                                        </span>
                                        <Controller name="payFastEFTActive" control={control}  rules={{ required: 'Required.' }}
                                                    render={({ field, fieldState }) => (
                                                        <Dropdown value={field.value} id={field.name}
                                                                  options={boolSelect} optionLabel="description"
                                                                  optionValue="value"
                                                                  onChange={(e) => {
                                                                      field.onChange(e.value)
                                                                  }}
                                                        />
                                                    )}
                                        />
                                        {getFormErrorMessage('payFastEFTActive')}
                                    </div>

                                    <div className="field col-3">
                                        <span className="p-float-label" style={{fontSize: '12px', color: "#6c757d", paddingBottom: '5px'}}>
                                             Mobicred
                                        </span>
                                        <Controller name="payFastMobicredActive" control={control}  rules={{ required: 'Required.' }}
                                                    render={({ field, fieldState }) => (
                                                        <Dropdown value={field.value} id={field.name}
                                                                  options={boolSelect} optionLabel="description"
                                                                  optionValue="value"
                                                                  onChange={(e) => {
                                                                      field.onChange(e.value)
                                                                  }}
                                                        />
                                                    )}
                                        />
                                        {getFormErrorMessage('payFastMobicredActive')}
                                    </div>

                                    <div className="field col-3">
                                        <span className="p-float-label" style={{fontSize: '12px', color: "#6c757d", paddingBottom: '5px'}}>
                                             Zapper
                                        </span>
                                        <Controller name="payFastZapperActive" control={control}  rules={{ required: 'Required.' }}
                                                    render={({ field, fieldState }) => (
                                                        <Dropdown value={field.value} id={field.name}
                                                                  options={boolSelect} optionLabel="description"
                                                                  optionValue="value"
                                                                  onChange={(e) => {
                                                                      field.onChange(e.value)
                                                                  }}
                                                        />
                                                    )}
                                        />
                                        {getFormErrorMessage('payFastZapperActive')}
                                    </div>

                                    <div className="field col-3">
                                        <span className="p-float-label" style={{fontSize: '12px', color: "#6c757d", paddingBottom: '5px'}}>
                                             Masterpass
                                        </span>
                                        <Controller name="payFastMasterPassActive" control={control}  rules={{ required: 'Required.' }}
                                                    render={({ field, fieldState }) => (
                                                        <Dropdown value={field.value} id={field.name}
                                                                  options={boolSelect} optionLabel="description"
                                                                  optionValue="value"
                                                                  onChange={(e) => {
                                                                      field.onChange(e.value)
                                                                  }}
                                                        />
                                                    )}
                                        />
                                        {getFormErrorMessage('payFastMasterPassActive')}
                                    </div>

                                    <div className="field col-3">
                                        <span className="p-float-label" style={{fontSize: '12px', color: "#6c757d", paddingBottom: '5px'}}>
                                             SCode
                                        </span>
                                        <Controller name="payFastScodeActive" control={control}  rules={{ required: 'Required.' }}
                                                    render={({ field, fieldState }) => (
                                                        <Dropdown value={field.value} id={field.name}
                                                                  options={boolSelect} optionLabel="description"
                                                                  optionValue="value"
                                                                  onChange={(e) => {
                                                                      field.onChange(e.value)
                                                                  }}
                                                        />
                                                    )}
                                        />
                                        {getFormErrorMessage('payFastScodeActive')}
                                    </div>

                                    <div className="field col-3">
                                        <span className="p-float-label" style={{fontSize: '12px', color: "#6c757d", paddingBottom: '5px'}}>
                                             ATM Debit Cards (Visa Electron & Maestro)
                                        </span>
                                        <Controller name="payFastVisaElectronActive" control={control}  rules={{ required: 'Required.' }}
                                                    render={({ field, fieldState }) => (
                                                        <Dropdown value={field.value} id={field.name}
                                                                  options={boolSelect} optionLabel="description"
                                                                  optionValue="value"
                                                                  onChange={(e) => {
                                                                      field.onChange(e.value)
                                                                  }}
                                                        />
                                                    )}
                                        />
                                        {getFormErrorMessage('payFastVisaElectronActive')}
                                    </div>

                                </div>*/}
                            </>
                        }

                        <div className="col-12">
                            <div className="p-fluid grid">
                                <div className="field col-12 md:col-9"></div>
                                <div className="field col-12 md:col-3">
                                    <Button label="Update PayFast Settings"
                                            severity="warning"
                                            disabled={submitButtonDisabled}
                                            style={themeSettings.buttonColor ? {background: `#${themeSettings.buttonColor}`} : {background: '#f97316'}}
                                    />
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </>
    )
}