import React, {useRef, useState} from 'react';
//import {updateElavonSettings} from "../../../../Actions/MopriSettingActions";
import {Toast} from "primereact/toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Controller, useForm} from "react-hook-form";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {faCreditCard} from "@fortawesome/free-solid-svg-icons/faCreditCard";
import {useSelector} from "react-redux";

const elevonStatuses = [{value: true, description: 'Active'}, {value: false, description: 'Inactive'}];
const elevonEnvironments = [{value: true, description: 'Sandbox Environment'}, {value: false, description: 'Live Environment'}];

export const ElavonSettings = (props) => {
    const baseUrl = window.location.origin.toString();
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
    const myToast = useRef(null)
    const themeSettings = useSelector(state => state.theme);

    const { control, handleSubmit, watch, getValues,
        formState: { errors } } = useForm({
        defaultValues: {
            elavonActive: true,
            elavonSandbox: true,
            elavonMerchId: '',
            convergeUserID: '',
            merchantPin: '',
            successReturnUrl: '',
            cancelReturnUrl: '',
        }
    })

    const onSubmit = (data) => {
        setSubmitButtonDisabled(true)

    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const elevonServiceActive = watch("elavonActive")

    return (
        <>
            <Toast ref={myToast} />
            <div className="p-fluid grid formgrid">
                <div className="field col-12">
                    <h4><FontAwesomeIcon icon={faCreditCard} style={{paddingRight: '10px'}}/> Elavon Settings</h4>
                </div>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="p-fluid grid formgrid">
                    <div className="field col-12">
                        <div className="p-fluid grid formgrid" >
                            <div className="field col-6">
                                <span className="p-float-label" style={{fontSize: '12px', color: "#6c757d", paddingBottom: '5px'}}>
                                     Elavon Status
                                </span>
                                <Controller name="elavonActive" control={control}  rules={{ required: 'Required.' }}
                                            render={({ field, fieldState }) => (
                                                <Dropdown value={field.value} id={field.name}
                                                          options={elevonStatuses} optionLabel="description"
                                                          optionValue="value"
                                                          onChange={(e) => {
                                                              field.onChange(e.value)
                                                          }}
                                                />
                                            )}
                                />
                                {getFormErrorMessage('elavonActive')}
                            </div>
                            <div className="field col-6">
                                {elevonServiceActive &&
                                    <>
                                        <span className="p-float-label" style={{fontSize: '12px', color: "#6c757d", paddingBottom: '5px'}}>
                                         Elavon Status
                                        </span>
                                        <Controller name="elavonSandbox" control={control}  rules={{ required: 'Required.' }}
                                                    render={({ field, fieldState }) => (
                                                        <Dropdown value={field.value} id={field.name}
                                                                  options={elevonEnvironments} optionLabel="description"
                                                                  optionValue="value"
                                                                  onChange={(e) => {
                                                                      field.onChange(e.value)
                                                                  }}
                                                        />
                                                    )}
                                        />
                                        {getFormErrorMessage('elavonSandbox')}
                                    </>
                                }
                            </div>
                        </div>

                        {elevonServiceActive &&
                            <>
                                <div className="p-fluid grid formgrid" >
                                    <div className="field col-6" style={{paddingTop: '27px'}}>
                                            <span className="p-float-label">
                                                <Controller name="elavonMerchId" control={control}
                                                            render={({ field, fieldState }) => (
                                                                <InputText id={field.name} {...field}
                                                                />
                                                            )}
                                                />
                                                <label htmlFor="elavonMerchId">Elevon Merchant ID</label>
                                            </span>
                                        {getFormErrorMessage('elavonMerchId')}
                                    </div>
                                    <div className="field col-6" style={{paddingTop: '27px'}}>
                                            <span className="p-float-label">
                                                <Controller name="convergeUserID" control={control}
                                                            render={({ field, fieldState }) => (
                                                                <InputText id={field.name} {...field}
                                                                />
                                                            )}
                                                />
                                                <label htmlFor="convergeUserID">Converge User ID</label>
                                            </span>
                                        {getFormErrorMessage('convergeUserID')}
                                    </div>
                                </div>

                                <div className="p-fluid grid formgrid" >
                                    <div className="field col-6" style={{paddingTop: '27px'}}>
                                            <span className="p-float-label">
                                                <Controller name="merchantPin" control={control}
                                                            render={({ field, fieldState }) => (
                                                                <InputText id={field.name} {...field}
                                                                />
                                                            )}
                                                />
                                                <label htmlFor="merchantPin">Merchant Pin</label>
                                            </span>
                                        {getFormErrorMessage('merchantPin')}
                                    </div>
                                    <div className="field col-6" style={{paddingTop: '27px'}}>  </div>
                                </div>
                            </>
                        }

                        <div className="col-12">
                            <div className="p-fluid grid">
                                <div className="field col-12 md:col-9"></div>
                                <div className="field col-12 md:col-3">
                                    <Button label="Update Elavon Settings"
                                            severity="warning"
                                            disabled={submitButtonDisabled}
                                            style={themeSettings.buttonColor ? {background: `#${themeSettings.buttonColor}`} : {background: '#f97316'}}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </form>

            {elevonServiceActive &&
                <>
                    <hr />

                    <h5>Elavon Settings To Update In Elavon Portal</h5>
                    <p>Copy these url's to update your Elavon Hosted Service Gateway return url's</p>

                    <div className="p-fluid grid formgrid" >
                        <div className="field col-12" style={{paddingTop: '27px'}}>
                            <span className="p-float-label">
                                <Controller name="successReturnUrl" control={control}
                                            render={({ field, fieldState }) => (
                                                <InputText id={field.name} {...field} value={`${baseUrl}/api/elavon/elavonresponse.asp`}
                                                />
                                            )}
                                />
                                <label htmlFor="successReturnUrl">Merchant Pin</label>
                            </span>
                        </div>
                        <div className="field col-12" style={{paddingTop: '27px'}}>
                            <span className="p-float-label">
                                <Controller name="cancelReturnUrl" control={control}
                                            render={({ field, fieldState }) => (
                                                <InputText id={field.name} {...field} value={`${baseUrl}/elavon-cancel`}
                                                />
                                            )}
                                />
                                <label htmlFor="cancelReturnUrl">Cancel Return URL</label>
                            </span>
                        </div>
                    </div>
                </>
            }
        </>
    )
}
