import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from 'react-router-dom';
import axios from "axios";
import {Controller, useForm} from "react-hook-form";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";
import {LogRegSlider} from "../Widgets/LogRegSlider";
import useWindowSize from "../../../Utils/Auth/UseWindowSize";


export const ForgotPassword = () => {
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [logoURL, setLogoURL] = useState('mopri-logo-large.png');
    const [showLogo, setShowLogo] = useState(true);
    const [fixFormPosition, setFixFormPosition] = useState(false)
    //const dispatch = useDispatch();
    const myToast = useRef(null)
    const userAuth = useSelector(state => state.userAuth);
    const navigate = useNavigate();
    const size = useWindowSize();
    const apiURL = useSelector(state => state.mopriSettings.mopriAPIURL);
    const themeSettings = useSelector(state => state.theme);


    useEffect(() => {
        //getMopriLogoUrl()
        if (window.innerWidth >= 769 && window.innerWidth <=991) {
            setFixFormPosition(true)
        }

    }, []);

    /*const getMopriLogoUrl = () => {
        // dispatch(clearLogin());  // Clear any redux state that can affect the login process
        axios.get(`/api/settings/getmopriLogoUrl.asp`)
            .then((response) => {
                if (!response.data.error) {
                    setLogoURL(response.data.logoURL)
                    setShowLogo(true)
                } else {
                    setShowLogo(true)
                }
            })
            .catch((e) => {
                console.log(e);
                setShowLogo(true)
            });
    }*/

    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            email: ''
        }
    })

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const onSubmit = (data) => {
        data.appUrl = window.location.origin;
        setSubmitButtonDisabled(true)
        setShowErrorMessage(false)
        setErrorMessage('')


        fetch(`${apiURL}/api/pwreset`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then((result) => {
                if (result.error) {
                    setShowErrorMessage(true)
                    setErrorMessage(result.errorMessage)
                    setSubmitButtonDisabled(false)
                    myToast.current.show({severity: 'error', summary: 'ERROR', detail: result.errorMessage, life: 5000});
                } else {
                    myToast.current.show({severity: 'info', summary: 'INFO', detail: result.message, life: 5000});

                    setTimeout(() => {
                        navigate('/login')
                    }, 3000)
                }
            })
            .catch((e) => {
                setSubmitButtonDisabled(false)
                console.log(e)
                myToast.current.show({severity: 'error', summary: 'ERROR', detail: 'An unspecified error took place while resetting your password.  Please contact support for assistance.', life: 5000});
            })

    }

    return (
        <>
            <Toast ref={myToast} />
            <div className="layout-main">
                <div className="grid">
                    {(size.width >= 620) &&
                        <div className="col-fixed" style={{paddingRight: '200px', padding: '0px', width: '300px'}}>
                            <LogRegSlider />
                        </div>
                    }
                    <div className="col-6 md:col-12 lg:col-3" style={{paddingLeft: '40px'}}>
                        <div style={{position: 'absolute', top: '50%', transform: `${fixFormPosition ? 'translate(310px, -50%)' : 'translate(0px, -50%)'}`}}>
                            <div className="p-fluid grid formgrid" style={{paddingTop: '20px'}}>
                                <div className="field col-12">
                                    <div className="h5 modal-title text-center">
                                        {showLogo &&
                                            <>
                                                {logoURL ?
                                                    <img src={`img/${logoURL}`} alt="Logo"/>
                                                    :
                                                    <img src="img/mopri-logo-large.png" alt="Mopri"/>
                                                }
                                            </>
                                        }
                                        <h4 className="mt-2">
                                            <div>Forgot your Password?</div>
                                            <span>Use the form below to recover it.</span>
                                        </h4>
                                    </div>

                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="p-fluid grid formgrid" style={{paddingTop: '20px'}}>
                                            <div className="field col-12">
                                                <span className="p-float-label">
                                                    <Controller name="email" control={control} rules={{required: 'Required.'}}
                                                                render={({field, fieldState}) => (
                                                                    <InputText id={field.name} {...field} type="email"
                                                                               className={errors.email && 'p-invalid block'}
                                                                    />
                                                                )}
                                                    />
                                                    <label htmlFor="email">Username</label>
                                                </span>
                                                {getFormErrorMessage('email')}
                                            </div>
                                        </div>


                                        <div className="p-fluid grid" style={{paddingTop: '20px'}}>
                                            <div className="field col-12 md:col-12">
                                                <Button label="RESET PASSWORD"
                                                        disabled={submitButtonDisabled}
                                                        style={themeSettings.buttonColor ? {background: `#${themeSettings.buttonColor}`} : {background: '#f97316'}}
                                                />
                                            </div>
                                        </div>
                                    </form>

                                    <div style={{paddingTop: '25px', overflow: "hidden"}}>
                                        <div style={{float: "left", cursor: 'pointer', color: themeSettings.buttonColor ? `#${themeSettings.buttonColor}` : '#f97316', fontWeight: 'bolder'}}
                                             onClick={() => navigate('/register')}
                                        >
                                            Register Account
                                        </div>
                                        <div style={{float: "right", marginLeft: 'auto', marginRight: 0, cursor: 'pointer', color: themeSettings.buttonColor ? `#${themeSettings.buttonColor}` : '#f97316', fontWeight: 'bolder'}}
                                             onClick={() => navigate('/login')}
                                        >
                                            Login
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="md:col-12 lg:col-3"></div>
                </div>
            </div>
        </>
    );
}