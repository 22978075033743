import React from 'react';
import {Card} from "primereact/card";
import {UserManagementSettings} from "./Widgets/UserManagementSettings";

export const UserManagement = () => {

    return (
        <Card title="User Management">
            <div className="grid">
                <div className="col-12">
                    <UserManagementSettings />
                </div>
            </div>
        </Card>
    )
}
