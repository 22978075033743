import React, {useRef, useState} from 'react';
//import {updateSticittSettings} from "../../../../Actions/MopriSettingActions";
import {Toast} from "primereact/toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Controller, useForm} from "react-hook-form";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {faCreditCard} from "@fortawesome/free-solid-svg-icons/faCreditCard";
import {useSelector} from "react-redux";

const sticittStatuses = [{value: true, description: 'Active'}, {value: false, description: 'Inactive'}];
const sticittEnvironments = [{value: true, description: 'Sandbox Environment'}, {value: false, description: 'Live Environment'}];

export const SticittSettings = (props) => {
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
    const themeSettings = useSelector(state => state.theme);
    const myToast = useRef(null)

    const { control, handleSubmit, watch, getValues,
        formState: { errors } } = useForm({
        defaultValues: {
            sticittActive: true,
            sticittSandbox: true,
            sticittMerchantID: '',
            sticittMerchantName: '',
            sticittMerchantAccountRef: '',
        }
    })

    const onSubmit = (data) => {
        setSubmitButtonDisabled(true)

    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const sticittServiceActive = watch("sticittActive")

    return (
        <>
            <Toast ref={myToast} />
            <div className="p-fluid grid formgrid">
                <div className="field col-12">
                    <h4><FontAwesomeIcon icon={faCreditCard} style={{paddingRight: '10px'}}/> Sticitt Settings</h4>
                </div>
            </div>

            <div className="p-fluid grid formgrid">
                <div className="field col-12">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="p-fluid grid formgrid" >
                            <div className="field col-6">
                                <span className="p-float-label" style={{fontSize: '12px', color: "#6c757d", paddingBottom: '5px'}}>
                                     Sticitt Status
                                </span>
                                <Controller name="sticittActive" control={control}  rules={{ required: 'Required.' }}
                                            render={({ field, fieldState }) => (
                                                <Dropdown value={field.value} id={field.name}
                                                          options={sticittStatuses} optionLabel="description"
                                                          optionValue="value"
                                                          onChange={(e) => {
                                                              field.onChange(e.value)
                                                          }}
                                                />
                                            )}
                                />
                                {getFormErrorMessage('sticittActive')}
                            </div>
                            <div className="field col-6">
                                {sticittServiceActive &&
                                    <>
                                        <span className="p-float-label" style={{fontSize: '12px', color: "#6c757d", paddingBottom: '5px'}}>
                                             Sticitt Sandbox Test Environment
                                        </span>
                                        <Controller name="sticittSandbox" control={control}  rules={{ required: 'Required.' }}
                                                    render={({ field, fieldState }) => (
                                                        <Dropdown value={field.value} id={field.name}
                                                                  options={sticittEnvironments} optionLabel="description"
                                                                  optionValue="value"
                                                                  onChange={(e) => {
                                                                      field.onChange(e.value)
                                                                  }}
                                                        />
                                                    )}
                                        />
                                        {getFormErrorMessage('sticittSandbox')}
                                    </>
                                }
                            </div>
                        </div>

                        {sticittServiceActive &&
                            <>
                                <div className="p-fluid grid formgrid" >
                                    <div className="field col-6" style={{paddingTop: '27px'}}>
                                            <span className="p-float-label">
                                                <Controller name="sticittMerchantID" control={control}
                                                            render={({ field, fieldState }) => (
                                                                <InputText id={field.name} {...field}
                                                                />
                                                            )}
                                                />
                                                <label htmlFor="sticittMerchantID">Sticitt Merchant ID</label>
                                            </span>
                                        {getFormErrorMessage('sticittMerchantID')}
                                    </div>
                                    <div className="field col-6" style={{paddingTop: '27px'}}>
                                        <span className="p-float-label">
                                            <Controller name="sticittMerchantName" control={control}
                                                        render={({ field, fieldState }) => (
                                                            <InputText id={field.name} {...field}
                                                            />
                                                        )}
                                            />
                                            <label htmlFor="sticittMerchantName">Sticitt Merchant Name</label>
                                        </span>
                                        {getFormErrorMessage('sticittMerchantName')}
                                    </div>
                                </div>

                                <div className="p-fluid grid formgrid" >
                                    <div className="field col-6" style={{paddingTop: '27px'}}>
                                        <span className="p-float-label">
                                            <Controller name="sticittMerchantAccountRef" control={control}
                                                        render={({ field, fieldState }) => (
                                                            <InputText id={field.name} {...field}
                                                            />
                                                        )}
                                            />
                                            <label htmlFor="sticittMerchantAccountRef">Sticitt Merchant Account Reference</label>
                                        </span>
                                        {getFormErrorMessage('sticittMerchantAccountRef')}
                                    </div>
                                    <div className="field col-6"> </div>
                                </div>
                            </>
                        }

                        <div className="col-12">
                            <div className="p-fluid grid">
                                <div className="field col-12 md:col-9"> </div>
                                <div className="field col-12 md:col-3">
                                    <Button label="Update Sticitt Settings"
                                            severity="warning"
                                            disabled={submitButtonDisabled}
                                            style={themeSettings.buttonColor ? {background: `#${themeSettings.buttonColor}`} : {background: '#f97316'}}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}