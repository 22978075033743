import React, {useEffect, useState} from 'react';


export const PrintSummary = (props) =>  {
    const [currency, setCurrency] = useState('')

    useEffect(() => {
        switch (props.localizationCurrency) {
            case 'Euro':
                setCurrency('€')
                break;
            case 'Pound Sterling':
                setCurrency('£')
                break;
            case 'South African Rand':
                setCurrency('R')
                break;
            case 'Namibian Dollar':
                setCurrency('N$')
                break;
            default:
                setCurrency('R')
                break;
        }
    }, [])

    const totPagesPrintCopy = props.printStats.totPagesCopied + props.printStats.totPagesPrinted;
    const totPagesPrintCopyCost = props.printStats.totPagesCopiedCost + props.printStats.totPagesPrintedCost;
    const totSevenDayPagesPrintCopy = props.printStats.totSevenDayPagesCopied + props.printStats.totSevenDayPagesPrinted;
    const totSevenDayPagesPrintCopyCost = props.printStats.totSevenDayPagesCopiedCost + props.printStats.totSevenDayPagesPrintedCost;
    const totTodayPagesPrintCopy = props.printStats.totTodayPagesCopied + props.printStats.totTodayPagesPrinted;
    const totTodayPagesPrintCopyCost = props.printStats.totTodayPagesCopiedCost + props.printStats.totTodayPagesPrintedCost;


    return (
        <>
            <div className="grid">
                <div className="col-4">
                    <div className="stat-card mb-0">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">Total Pages Printed & Copied</span>
                                <div style={{fontSize: '30px', fontWeight: 'bold'}}>{totPagesPrintCopy ? totPagesPrintCopy : 0}</div>
                                <div style={{fontSize: '20px', fontWeight: 'bold'}}>{currency + ' ' + (totPagesPrintCopyCost ? totPagesPrintCopyCost.toFixed(2) : 0.00)}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-4">
                    <div className="stat-card mb-0">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">Printed & Copied Last 7 Days</span>
                                <div style={{fontSize: '30px', fontWeight: 'bold'}}>{totSevenDayPagesPrintCopy ? totSevenDayPagesPrintCopy : 0}</div>
                                <div style={{fontSize: '20px', fontWeight: 'bold'}}>{currency + ' ' + (totSevenDayPagesPrintCopyCost ? totSevenDayPagesPrintCopyCost.toFixed(2) : 0.00)}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-4">
                    <div className="stat-card mb-0">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">Printed & Copied Today</span>
                                <div style={{fontSize: '30px', fontWeight: 'bold'}}>{totTodayPagesPrintCopy ? totTodayPagesPrintCopy : 0}</div>
                                <div style={{fontSize: '20px', fontWeight: 'bold'}}>{currency + ' ' + (totTodayPagesPrintCopyCost ? totTodayPagesPrintCopyCost.toFixed(2) : 0.00)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
