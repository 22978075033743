import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from 'react-router-dom';
import {Controller, useForm} from "react-hook-form";
import PasswordChecklist from "react-password-checklist"
import {InputText} from "primereact/inputtext";
import {useSelector} from "react-redux";
import {Button} from "primereact/button";
import {ErrorMessage} from "../../../Components/common/Messages/ErrorMessage";
import {Toast} from "primereact/toast";
import useWindowSize from "../../../Utils/Auth/UseWindowSize";
import {LogRegSlider} from "../Widgets/LogRegSlider";
import {Dropdown} from "primereact/dropdown";


export const Register = () => {
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [logoURL, setLogoURL] = useState('mopri-logo-large.png');
    const [showLogo, setShowLogo] = useState(true);
    const [fixFormPosition, setFixFormPosition] = useState(false)
    const userAuth = useSelector(state => state.userAuth);
    const mopriSettings = useSelector(state => state.mopriSettings);
    const navigate = useNavigate();
    const myToast = useRef(null)
    const size = useWindowSize();
    const booleanSelect = [{description: 'No', value: 'no'}, {description: 'Yes', value: 'yes'}];
    const apiURL = useSelector(state => state.mopriSettings.mopriAPIURL);
    const themeSettings = useSelector(state => state.theme);


    useEffect(() => {
        if (window.innerWidth >= 769 && window.innerWidth <=991) {
            setFixFormPosition(true)
        }
    }, []);

    const getMopriLogoUrl = () => {
        /*axios.get(`/api/moprisettings/getmopriLogoUrl.asp`)
            .then((response) => {
                if (!response.data.error) {
                    setLogoURL(response.data.logoURL)
                    setShowLogo(true)
                } else {
                    setShowLogo(true)
                }
            })
            .catch((e) => {
                console.log(e);
                setShowLogo(true)
            });*/
    }

    const { control, handleSubmit, watch, formState: { errors } } = useForm({
        defaultValues: {
            firstNames: '',
            surname: '',
            pdUserName: '',
            email: '',
            registerPassword: '',
            password2: '',
            hasStudentNumber: 'no'
        }
    })

    const watchHasStudentNumber = watch('hasStudentNumber', 'no');
    const newPasswordWatch = watch("registerPassword")
    const newPasswordAgainWatch = watch("password2")


    const onSubmit = (data) => {
        if (data.registerPassword !== data.password2) {
            setShowErrorMessage(true)
            setErrorMessage('Passwords do not match.')
        } else {
            setSubmitButtonDisabled(true)
            setShowErrorMessage(false)
            setErrorMessage('')

            fetch(`${apiURL}/api/register`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...data,
                    userRegistrationType: mopriSettings.systemIntegration.userRegistrationType,
                    pinRegister: mopriSettings.systemIntegration.pinRegister,
                    selectedUserGroup: mopriSettings.systemIntegration.selectedUserGroup,
                    email: data.email.toLowerCase(),
                    pdUserName: data.pdUserName.toLowerCase(),
                    appUrl: window.location.origin
                })
            })
                .then(res => res.json())
                .then((result) => {
                    if (result.error) {
                        setShowErrorMessage(true)
                        setErrorMessage(result.errorMessage)
                        setSubmitButtonDisabled(false)
                    } else {
                        myToast.current.show({severity: 'success', summary: 'Success', detail: result.message, life: 10000});
                        setTimeout(() => {
                            navigate('/login')
                        }, 3000)
                    }

                })
                .catch((e) => {
                    setSubmitButtonDisabled(false)
                    console.log(e)
                })

        }
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };


    const capitalize = value => value.charAt(0).toUpperCase() + value.slice(1);
    return (
        <>
            <Toast ref={myToast} />
            <div className="layout-main">
                <div className="grid">
                    {(size.width >= 620) &&
                        <div className="col-fixed" style={{paddingRight: '200px', padding: '0px', width: '300px'}}>
                            <LogRegSlider/>
                        </div>
                    }
                    <div className="col-6 md:col-12 lg:col-3" style={{paddingLeft: '40px'}}>
                        <div style={{position: 'absolute', top: '50%', transform: `${fixFormPosition ? 'translate(310px, -50%)' : 'translate(0px, -50%)'}`}}>
                            <div className="p-fluid grid formgrid" style={{paddingTop: '20px'}}>
                                <div className="field col-12" style={{width: '310px'}}>
                                    <div className="h5 modal-title text-center">
                                        {showLogo &&
                                            <>
                                                {logoURL ?
                                                    <img src={`img/${logoURL}`} alt="Logo"/>
                                                :
                                                    <img src="img/mopri-logo-large.png" alt="Mopri"/>
                                                }
                                            </>
                                        }
                                        <h4 className="mt-2">
                                            <div>Welcome...</div>
                                            <span>Please register your account below.</span>
                                        </h4>
                                    </div>

                                    {showErrorMessage &&
                                        <ErrorMessage message={errorMessage}/>
                                    }
                                    {userAuth.accCreated &&
                                        <div className="alert alert-success fade show" role="alert">
                                            {this.props.accCreatedMsg}
                                        </div>
                                    }
                                    {userAuth.accountExists &&
                                        <div className="alert alert-danger fade show" role="alert">
                                            The email address you entered is already in use.
                                        </div>
                                    }

                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="p-fluid grid formgrid" style={{paddingTop: '20px'}}>
                                            <div className="field col-12">
                                                <span className="p-float-label">
                                                    <Controller name="firstNames" control={control} rules={{required: 'Required.'}}
                                                                render={({field, fieldState}) => (
                                                                    <InputText id={field.name} {...field}
                                                                               className={errors.firstNames && 'p-invalid block'}
                                                                    />
                                                                )}
                                                    />
                                                    <label htmlFor="firstNames">First Name(s)</label>
                                                </span>
                                                {getFormErrorMessage('firstNames')}
                                            </div>
                                        </div>
                                        <div className="p-fluid grid formgrid" style={{paddingTop: '20px'}}>
                                            <div className="field col-12">
                                                <span className="p-float-label">
                                                    <Controller name="surname" control={control} rules={{required: 'Required.'}}
                                                                render={({field, fieldState}) => (
                                                                    <InputText id={field.name} {...field}
                                                                               className={errors.surname && 'p-invalid block'}
                                                                    />
                                                                )}
                                                    />
                                                    <label htmlFor="surname">Surname</label>
                                                </span>
                                                {getFormErrorMessage('surname')}
                                            </div>
                                        </div>
                                        {(mopriSettings.systemIntegration?.userRegistrationType === "Close") &&
                                            <>
                                                {mopriSettings.systemIntegration?.allowGuestRegistration ?
                                                    <>
                                                        <div className="p-fluid grid formgrid" style={{paddingTop: '20px'}}>
                                                            <div className="field col-12">
                                                            <span className="p-float-label" style={{fontSize: '12px', color: "#6c757d", paddingBottom: '5px'}}>
                                                                 Do you have a student number or staff username?
                                                            </span>
                                                                <Controller name="hasStudentNumber" control={control} rules={{required: 'Required.'}}
                                                                            render={({field, fieldState}) => (
                                                                                <Dropdown value={field.value} id={field.name}
                                                                                          options={booleanSelect} optionLabel="description"
                                                                                          optionValue="value"
                                                                                          onChange={(e) => {
                                                                                              field.onChange(e.value)
                                                                                          }}
                                                                                />
                                                                            )}
                                                                />
                                                                {getFormErrorMessage('hasStudentNumber')}
                                                            </div>
                                                        </div>

                                                        {(watchHasStudentNumber === 'yes') &&
                                                            <div className="p-fluid grid formgrid"
                                                                 style={{paddingTop: '20px'}}>
                                                                <div className="field col-12">
                                                                    <span className="p-float-label">
                                                                        <Controller name="pdUserName" control={control}
                                                                                    rules={{required: 'Required.'}}
                                                                                    render={({field, fieldState}) => (
                                                                                        <InputText id={field.name} {...field}
                                                                                                   className={errors.pdUserName && 'p-invalid block'}
                                                                                        />
                                                                                    )}
                                                                        />
                                                                        <label htmlFor="pdUserName">Student Number / Staff Username</label>
                                                                    </span>
                                                                    {getFormErrorMessage('pdUserName')}
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                    :
                                                    <div className="p-fluid grid formgrid" style={{paddingTop: '20px'}}>
                                                        <div className="field col-12">
                                                                <span className="p-float-label">
                                                                    <Controller name="pdUserName" control={control}
                                                                                rules={{required: 'Required.'}}
                                                                                render={({field, fieldState}) => (
                                                                                    <InputText id={field.name} {...field}
                                                                                               className={errors.pdUserName && 'p-invalid block'}
                                                                                    />
                                                                                )}
                                                                    />
                                                                    <label htmlFor="pdUserName">Student Number</label>
                                                                </span>
                                                            {getFormErrorMessage('pdUserName')}
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        }
                                        <div className="p-fluid grid formgrid" style={{paddingTop: '20px'}}>
                                            <div className="field col-12">
                                                <span className="p-float-label">
                                                    <Controller name="email" control={control} rules={{required: 'Required.'}}
                                                                render={({field, fieldState}) => (
                                                                    <InputText id={field.name} {...field} type="email"
                                                                               className={errors.email && 'p-invalid block'}
                                                                    />
                                                                )}
                                                    />
                                                    <label htmlFor="email">Email Address</label>
                                                </span>
                                                {getFormErrorMessage('email')}
                                            </div>
                                        </div>


                                        <div className="p-fluid grid formgrid" style={{paddingTop: '20px'}}>
                                            <div className="field col-12">
                                                <span className="p-float-label">
                                                    <Controller name="registerPassword" control={control}
                                                                rules={{
                                                                    required: 'Required.', minLength: {
                                                                        value: 8,
                                                                        message: "Length must be 8 or more characters."
                                                                    }
                                                                }}
                                                                render={({field, fieldState}) => (
                                                                    <InputText id={field.name} {...field} type="password"
                                                                               className={errors.registerPassword && 'p-invalid block'}
                                                                    />
                                                                )}
                                                    />
                                                    <label htmlFor="registerPassword">Password</label>
                                                </span>
                                                {getFormErrorMessage('registerPassword')}
                                            </div>
                                        </div>
                                        <div className="p-fluid grid formgrid" style={{paddingTop: '20px'}}>
                                            <div className="field col-12">
                                                <span className="p-float-label">
                                                    <Controller name="password2" control={control}
                                                                rules={{
                                                                    required: 'Required.', minLength: {
                                                                        value: 8,
                                                                        message: "Length must be 8 or more characters."
                                                                    }
                                                                }}
                                                                render={({field, fieldState}) => (
                                                                    <InputText id={field.name} {...field} type="password"
                                                                               className={errors.password2 && 'p-invalid block'}
                                                                    />
                                                                )}
                                                    />
                                                    <label htmlFor="password2">Re-enter Password</label>
                                                </span>
                                                {getFormErrorMessage('password2')}
                                            </div>
                                        </div>

                                        <div className="p-fluid grid formgrid" style={{paddingTop: '20px'}}>
                                            <div className="field col-12">
                                                    <span className="p-float-label">
                                                        <PasswordChecklist
                                                            rules={["minLength", "specialChar", "number", "capital", "match"]}
                                                            minLength={6}
                                                            value={newPasswordWatch}
                                                            valueAgain={newPasswordAgainWatch}
                                                            onChange={(isValid) => {
                                                                isValid ? setSubmitButtonDisabled(false) : setSubmitButtonDisabled(true)
                                                            }}
                                                        />
                                                    </span>
                                            </div>
                                        </div>

                                        {/*<div className="p-fluid grid" style={{paddingTop: '20px'}}>
                                            <div className="field col-12 md:col-12">
                                                <span className="p-float-label">
                                                    <Controller name="tos" control={control}  rules={{ required: 'Required.' }}
                                                                render={({ field, fieldState }) => (
                                                                    <>
                                                                        <Checkbox inputId={field.name} checked={field.value} inputRef={field.ref} onChange={(e) => field.onChange(e.checked)} />
                                                                        <label htmlFor={field.name} style={{paddingLeft: '25px'}} >I accept the Terms & Conditions</label>
                                                                    </>
                                                                )}
                                                    />
                                                </span>
                                                {getFormErrorMessage('tos')}
                                            </div>
                                        </div>*/}

                                        <div className="p-fluid grid" style={{paddingTop: '20px'}}>
                                            <div className="field col-12 md:col-12">
                                                <Button label="SAVE"
                                                        disabled={submitButtonDisabled}
                                                        style={themeSettings.buttonColor ? {background: `#${themeSettings.buttonColor}`} : {background: '#f97316'}}
                                                />
                                            </div>
                                        </div>
                                    </form>

                                    <div style={{paddingTop: '25px', overflow: "hidden"}}>
                                        <div style={{float: "left", cursor: 'pointer', color: themeSettings.buttonColor ? `#${themeSettings.buttonColor}` : '#f97316', fontWeight: 'bolder'}}
                                             onClick={() => navigate('/login')}
                                        >
                                            Login
                                        </div>
                                        <div style={{float: "right", marginLeft: 'auto', marginRight: 0, cursor: 'pointer', color: themeSettings.buttonColor ? `#${themeSettings.buttonColor}` : '#f97316', fontWeight: 'bolder'}}
                                             onClick={() => navigate('/forgot')}
                                        >
                                            Forgot Password
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="md:col-12 lg:col-3"></div>
                </div>
            </div>
        </>
    );

}