import axios from "axios";
import configureStore from "../Store/store";

export const UPDATE_PAYMENT_HISTORY = 'UPDATE_PAYMENT_HISTORY';
export const START_FETCH_PRINT_HISTORY = 'START_FETCH_PRINT_HISTORY';
export const FETCH_PRINT_HISTORY_COMPLETE = 'FETCH_PRINT_HISTORY_COMPLETE';
export const FETCH_PRINT_HISTORY_ERROR = 'FETCH_PRINT_HISTORY_ERROR';

export function fetchPrintHistory(values) {
    let userName = configureStore.getState().userAuth.userName;
    //dispatch(createAction(START_FETCH_PRINT_HISTORY, null));
    return dispatch => {
        const url = `/api/printdirector/printHistory.asp?userName=${userName}&startDate=${values.startDate}&endDate=${values.endDate}`;
        axios.get(url)
            .then( function (response) {
                dispatch({
                    type: FETCH_PRINT_HISTORY_COMPLETE,
                    payload: response.data
                })
            })
            .catch(function (error) {
                console.log(error)
            });
    }
}

export const updatePaymentHistory = (values) =>
    ({ type: UPDATE_PAYMENT_HISTORY, values: values })







//create_action
export const createAction = (type, values) => {
    return {
        type,
        values
    };
};
