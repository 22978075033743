import React from "react";
import SimpleImageSlider from "react-simple-image-slider";


export const LogRegSlider = () => {
    const images = [
        {url: `${window.location.origin}/img/student-lifestyle.jpg`},
        {url: `${window.location.origin}/img/integration.jpg`}
    ];

    return (
        <SimpleImageSlider
            width={300}
            height="100vh"
            images={images}
            showBullets={true}
            showNavs={false}
            slideDuration={0.5}
            autoPlay={true}
        />
    )
}
