import React, {useRef, useState} from 'react';
import {updatePrinterIntegrationSettings} from '../../../../Actions/MopriSettingActions';
import {Toast} from "primereact/toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Controller, useForm} from "react-hook-form";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {faPrint} from "@fortawesome/free-solid-svg-icons/faPrint";
import axios from "axios";
import qs from "qs";
import {useDispatch, useSelector} from "react-redux";
import {InputText} from "primereact/inputtext";


const printManagementSoftware = [{description: 'Print Director', value: 1}];
const printReleaseType = [{description: 'Pin Code', value: 1}, {description: 'NFC', value: 2}];
const registrationType = [{description: 'Users do not exist on Print Director, create new users on Print Director and Mopri.', value: 'Open'}, {description: 'Users already exist on Print Director, link existing users to Mopri.', value: 'Close'}];
const booleanSelect = [{description: 'No', value: 0}, {description: 'Yes', value: 1}];
const boolSelect = [{description: 'No', value: false}, {description: 'Yes', value: true}];

export const PrinterIntegrationSettings = (props) => {
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
    const myToast = useRef(null)
    const userAuth = useSelector(state => state.userAuth);
    const dispatch = useDispatch();
    const apiURL = useSelector(state => state.mopriSettings.mopriAPIURL);
    const themeSettings = useSelector(state => state.theme);


    const { control, handleSubmit, setValue, watch,
        formState: { errors } } = useForm({
        defaultValues: {
            printerIntegrationID: props.integrationSettings.printerIntegrationID,
            printReleaseJobType: props.integrationSettings.printReleaseJobType,
            userRegType: props.integrationSettings.userRegistrationType,
            selectedUserGroup: props.integrationSettings.selectedUserGroup,
            generatePinCode: props.integrationSettings.pinGenerate,
            changePinCode: props.integrationSettings.pinChange,
            allowGuestRegistration: props.integrationSettings.allowGuestRegistration
        }
    })

    // Watch for changes in the form
    const watchPrintReleaseJobType = watch('printReleaseJobType', props.integrationSettings.printReleaseJobType);
    const watchUserRegType = watch('userRegType', props.integrationSettings.userRegType);

    const onSubmit = (data) => {
        setSubmitButtonDisabled(true)

        axios({
            method: 'post',
            url: `${apiURL}/api/savePrinterIntegrationSettings`,
            data: qs.stringify({
                ...data,
                adminUpdated: userAuth.mopriUID,
                selectedUserGroup: data.selectedUserGroup ? data.selectedUserGroup : 0,
                uid: userAuth.mopriUID
            }),
            config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
        })
            .then((response) => {
                if (response && response.data.error) {
                    myToast.current.show({severity: 'error', summary: 'ERROR', detail: response.data.errorMessage, life: 5000});
                } else {
                    myToast.current.show({severity: 'success', summary: 'SUCCESS', detail: `The printer integration settings have been successfully updated.`, life: 5000});
                    dispatch(updatePrinterIntegrationSettings(data));
                }
                setSubmitButtonDisabled(false)
            })
            .catch((error) => {
                myToast.current.show({severity: 'error', summary: 'ERROR', detail: 'There was an error while updating the printer integration settings.', life: 5000});
                setSubmitButtonDisabled(false)
                console.log(error);
            });

    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };


    return (
        <>
            <Toast ref={myToast} />
            <div className="p-fluid grid formgrid">
                <div className="field col-12">
                    <h4><FontAwesomeIcon icon={faPrint} style={{paddingRight: '10px'}}/> Printer Integration Settings</h4>
                </div>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="p-fluid grid formgrid" >
                    <div className="field col-6">
                        <span className="p-float-label" style={{fontSize: '12px', color: "#6c757d", paddingBottom: '5px'}}>
                             Print Management Software
                        </span>
                        <Controller name="printerIntegrationID" control={control}  rules={{ required: 'Required.' }}
                                    render={({ field, fieldState }) => (
                                        <Dropdown value={field.value} id={field.name}
                                                  options={printManagementSoftware} optionLabel="description"
                                                  optionValue="value"
                                                  onChange={(e) => {
                                                      field.onChange(e.value)
                                                  }}
                                        />
                                    )}
                        />
                        {getFormErrorMessage('printerIntegrationID')}
                    </div>

                    <div className="field col-6">
                        <span className="p-float-label" style={{fontSize: '12px', color: "#6c757d", paddingBottom: '5px'}}>
                            Print Management Print Release Job Type
                        </span>
                        <Controller name="printReleaseJobType" control={control}  rules={{ required: 'Required.' }}
                                    render={({ field, fieldState }) => (
                                        <Dropdown value={field.value} id={field.name}
                                                  options={printReleaseType} optionLabel="description"
                                                  optionValue="value"
                                                  onChange={(e) => {
                                                      field.onChange(e.value)
                                                  }}
                                        />
                                    )}
                        />
                        {getFormErrorMessage('printReleaseJobType')}
                    </div>
                </div>

                <div className="p-fluid grid formgrid" >
                    <div className="field col-12">
                        <span className="p-float-label" style={{fontSize: '12px', color: "#6c757d", paddingBottom: '5px'}}>
                             Registration Type
                        </span>
                        <Controller name="userRegType" control={control}  rules={{ required: 'Required.' }}
                                    render={({ field, fieldState }) => (
                                        <Dropdown value={field.value} id={field.name}
                                                  options={registrationType} optionLabel="description"
                                                  optionValue="value"
                                                  onChange={(e) => {
                                                      field.onChange(e.value)
                                                  }}
                                        />
                                    )}
                        />
                        {getFormErrorMessage('userRegType')}
                    </div>


                    {/*<div className="field col-6">
                        {watchUserRegType === 'Open' &&
                            <>
                                <span className="p-float-label" style={{fontSize: '12px', color: "#6c757d", paddingBottom: '5px'}}>
                                    Open Registration User Group
                                </span>
                                <Controller name="selectedUserGroup" control={control}  rules={{ required: 'Required.' }}
                                            render={({ field, fieldState }) => (
                                                <Dropdown value={field.value} id={field.name}
                                                          options={props.activeSiteGroups} optionLabel="siteGroupDescription"
                                                          optionValue="sitesGroupID" filter
                                                          onChange={(e) => {
                                                              field.onChange(e.value)
                                                          }}
                                                />
                                            )}
                                />
                                {getFormErrorMessage('selectedUserGroup')}
                            </>
                        }
                    </div>*/}
                </div>

                {watchPrintReleaseJobType === 1 &&
                    <>
                        <div className="p-fluid grid formgrid" >
                            <div className="field col-6">
                                <span className="p-float-label" style={{fontSize: '12px', color: "#6c757d", paddingBottom: '5px'}}>
                                     Generate new printer PIN on registration?
                                </span>
                                <Controller name="generatePinCode" control={control}  rules={{ required: 'Required.' }}
                                            render={({ field, fieldState }) => (
                                                <Dropdown value={field.value} id={field.name}
                                                          options={booleanSelect} optionLabel="description"
                                                          optionValue="value"
                                                          onChange={(e) => {
                                                              field.onChange(e.value)
                                                          }}
                                                />
                                            )}
                                />
                                {getFormErrorMessage('generatePinCode')}
                            </div>

                            <div className="field col-6">
                                <span className="p-float-label" style={{fontSize: '12px', color: "#6c757d", paddingBottom: '5px'}}>
                                    Allow user to change their printer PIN code?
                                </span>
                                <Controller name="changePinCode" control={control}  rules={{ required: 'Required.' }}
                                            render={({ field, fieldState }) => (
                                                <Dropdown value={field.value} id={field.name}
                                                          options={booleanSelect} optionLabel="description"
                                                          optionValue="value"
                                                          onChange={(e) => {
                                                              field.onChange(e.value)
                                                          }}
                                                />
                                            )}
                                />
                                {getFormErrorMessage('changePinCode')}
                            </div>
                        </div>
                    </>
                }


                <div className="p-fluid grid formgrid">
                    {watchUserRegType === 'Close' &&
                        <div className="field col-6">
                            <span className="p-float-label"
                                  style={{fontSize: '12px', color: "#6c757d", paddingBottom: '5px'}}>
                                 Allow guest registrations?
                            </span>
                            <Controller name="allowGuestRegistration" control={control} //rules={{required: 'Required.'}}
                                        render={({field, fieldState}) => (
                                            <Dropdown value={field.value} id={field.name}
                                                      options={boolSelect} optionLabel="description"
                                                      optionValue="value"
                                                      onChange={(e) => {
                                                          field.onChange(e.value)
                                                      }}
                                            />
                                        )}
                            />
                            {getFormErrorMessage('allowGuestRegistration')}
                        </div>
                    }
                    <div className="field col-6">
                        {/*<div style={{paddingTop: '20px'}}>
                            <span className="p-float-label">
                                <Controller name="apiURL" control={control} rules={{required: 'Required.'}}
                                            render={({ field, fieldState }) => (
                                                <InputText id={field.name} {...field}
                                                />
                                            )}
                                />
                                <label htmlFor="apiURL">API Base URL</label>
                            </span>
                            {getFormErrorMessage('apiURL')}
                        </div>*/}
                    </div>
                </div>

                <div className="col-12">
                    <div className="p-fluid grid">
                        <div className="field col-12 md:col-9"></div>
                        <div className="field col-12 md:col-3">
                            <Button label="Save" severity="warning"
                                    disabled={submitButtonDisabled}
                                    style={themeSettings.buttonColor ? {background: `#${themeSettings.buttonColor}`} : {background: '#f97316'}}
                            />
                        </div>
                    </div>
                </div>

            </form>
        </>
    )

}


