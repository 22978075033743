import React, {useState, useEffect, useRef} from 'react';
import axios from "axios";
import qs from "qs";
import {useDispatch, useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import {Toast} from "primereact/toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faKey} from "@fortawesome/free-solid-svg-icons";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {updateCountryLocalization} from "../../../../Actions/MopriSettingActions";

const countries = [
    {value: "IRL", description: "Ireland", currency: "Euro"},
    {value: "NAD", description: "Namibia", currency: "Namibian Dollar"},
    {value: "ZAF", description: "South Africa", currency: "South African Rand"},
    {value: "GBR", description: "United Kingdom", currency: "Pound Sterling"}
]

export const Localization = () => {
    const [country, setCountry] = useState(useSelector(state => state.mopriSettings.internationalSettings.localizationCountry));
    const [currency, setCurrency] = useState(useSelector(state => state.mopriSettings.internationalSettings.localizationCurrency));
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const userAuth = useSelector(state => state.userAuth);
    const myToast = useRef(null)
    const dispatch = useDispatch();
    const apiURL = useSelector(state => state.mopriSettings.mopriAPIURL);
    const themeSettings = useSelector(state => state.theme);


    useEffect(() => {
        setCountry(userAuth.localizationCountry);
        setCurrency(userAuth.localizationCurrency);
        setIsLoading(false)
    }, []);

    const { control, handleSubmit, setValue,
        formState: { errors } } = useForm({
        defaultValues: {
            country: country,
            currency: currency
        }
    })

    const onSubmit = (data) => {
        setSubmitButtonDisabled(true)

        let postData = {
            country: data.country,
            currency: data.currency,
            uid: userAuth.mopriUID
        }

        axios({
            method: 'post',
            url: `${apiURL}/api/updateLocalizationSettings`,
            data: qs.stringify({
                ...postData
            }),
            config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
        })
            .then((response) => {
                if (response.data.error) {
                    myToast.current.show({severity: 'error', summary: 'ERROR', detail: response.data.errorMessage, life: 5000});
                } else {
                    dispatch(updateCountryLocalization({
                        countryCode: data.country,
                        countryCurrency: data.currency,
                    }))
                }
                myToast.current.show({severity: 'success', summary: 'SUCCESS', detail: 'Country Localization Updated.', life: 5000});
                setSubmitButtonDisabled(false)
            })
            .catch((error) => {
                myToast.current.show({severity: 'error', summary: 'ERROR', detail: 'There was an error while updating the Country Localization.', life: 5000});
                console.log(error);
            });
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const handleCountryChange = (country) => {
        switch (country) {
            case "GBR":
                setCountry('GBR');
                setCurrency('Pound Sterling');
                setValue('currency', 'Pound Sterling');
                break;

            case "IRL":
                setCountry('IRL');
                setCurrency('Euro');
                setValue('currency', 'Euro')
                break;

            case "ZAF":
                setCountry('ZAF');
                setCurrency('South African Rand');
                setValue('currency', 'South African Rand')
                break;

            case "NAD":
                setCountry('NAD');
                setCurrency('Namibian Dollar');
                setValue('currency', 'Namibian Dollar')
                break;

            default:
                break;
        }
    }

    return (
        <>
            <Toast ref={myToast} />
            <div className="p-fluid grid formgrid">
                <div className="field col-12">
                    <h4><FontAwesomeIcon icon={faKey} style={{paddingRight: '10px'}}/> Localization</h4>
                </div>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="p-fluid grid formgrid">
                    <div className="field col-6">
                        <span className="p-float-label" style={{fontSize: '12px', color: "#6c757d", paddingBottom: '5px'}}>
                             Country
                        </span>
                        <Controller name="country" control={control} rules={{required: 'Required.'}}
                                    render={({field, fieldState}) => (
                                        <Dropdown value={field.value} id={field.name}
                                                  options={countries} optionLabel="description"
                                                  optionValue="value"
                                                  onChange={(e) => {
                                                      handleCountryChange(e.value)
                                                      field.onChange(e.value)
                                                  }}
                                        />
                                    )}
                        />
                        {getFormErrorMessage('tempFileDays')}
                    </div>

                    <div className="field col-6" style={{paddingTop: '20px'}}>
                        <span className="p-float-label">
                            <Controller name="currency" control={control}
                                        render={({field, fieldState}) => (
                                            <InputText id={field.name} {...field}
                                                       disabled={true}
                                            />
                                        )}
                            />
                            <label htmlFor="currency">Currency</label>
                        </span>
                        {getFormErrorMessage('currency')}
                    </div>
                </div>

                {/*<div className="col-12">
                    <div className="p-fluid grid">
                        <div className="field col-12 md:col-9"></div>
                        <div className="field col-12 md:col-3">
                            <Button label="Save"// size="small"
                                    severity="warning"
                                    disabled={submitButtonDisabled}
                                    style={themeSettings.buttonColor ? {background: `#${themeSettings.buttonColor}`} : {background: '#f97316'}}
                            />
                        </div>
                    </div>
                </div>*/}

                <div className="p-fluid grid formgrid">
                    <div className="field col-10"></div>
                    <div className="field col-2">
                        <Button label="Save"
                                style={themeSettings.buttonColor ? {background: `#${themeSettings.buttonColor}`} : {background: '#f97316'}}
                        />
                    </div>
                </div>

            </form>
        </>
    )
}
