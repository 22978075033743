import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Card} from "primereact/card";
import {GatewaySettings} from './Widgets/GatewaySettings';
import {SnapScanSettings} from "./Widgets/SnapScanSettings";
import {PayFastSettings} from './Widgets/PayFastSettings';
import {SticittSettings} from './Widgets/SticittSettings';
import {ElavonSettings} from "./Widgets/ElavonSettings";
import {OpayoSettings} from "./Widgets/OpayoSettings";
import axios from "axios";
import {Toast} from "primereact/toast";
import {updateMopriGatewaySettings} from "../../../Actions/MopriSettingActions";
import {PayGateSettings} from "./Widgets/PayGateSettings";



export const PaymentSettings = () => {
    const baseUrl = window.location.origin.toString();
    const [successReturnUrl, setSuccessReturnUrl] = useState(`${baseUrl}/elavon-success`);
    const [cancelReturnUrl, setCancelReturnUrl] = useState(`${baseUrl}/elavon-cancel`);
    const [declinedReturnUrl, setDeclinedReturnUrl] = useState(`${baseUrl}/elavon-decline`);
    const mopriSettings = useSelector(state => state.mopriSettings);
    const user = useSelector(state => state.userAuth);
    const dispatch = useDispatch();
    const myToast = useRef(null)
    const apiURL = useSelector(state => state.mopriSettings.mopriAPIURL);


    useEffect(() => {
        fetchMopriGatewaySettings();
    }, []);

    const fetchMopriGatewaySettings = () => {
        const url = `${apiURL}/api/getMopriGatewaySettings`;
        axios.get(url)
            .then((response) => {
                if (response && response.data.error) {
                    myToast.current.show({severity: 'error', summary: 'ERROR', detail: response.data.errorMessage, life: 5000});
                } else {
                    dispatch(updateMopriGatewaySettings(response.data.gatewaySettings));
                }
                /*dispatch({
                    type:  FETCH_MOPRI_GATEWAY_DATA,
                    payload: response.data
                });*/
            })
            .catch((error) => {
                console.log(error);
                myToast.current.show({severity: 'error', summary: 'ERROR', detail: 'An error took place while retrieving the payment gateway data.', life: 5000});
            });
    }


        return (
            <Card title="Payment Gateway Settings">
                <Toast ref={myToast} />
                <div className="grid">
                    <div className="col-12">
                        <GatewaySettings gatewaySettings={mopriSettings.gatewaySettings} />

                        {(mopriSettings.internationalSettings.localizationCountry === 'ZAF' || mopriSettings.internationalSettings.localizationCountry === 'NAD') &&
                            <PayGateSettings />
                        }
                        {mopriSettings.internationalSettings.localizationCountry === 'ZAF' &&
                            <>
                                <PayFastSettings gatewaySettings={mopriSettings.gatewaySettings} payFastActive={mopriSettings.gatewaySettings.payFastActive} />
                                <SnapScanSettings gatewaySettings={mopriSettings.gatewaySettings} snapScanActive={mopriSettings.gatewaySettings.snapScanActive} />
                                <SticittSettings gatewaySettings={mopriSettings.gatewaySettings} sticittActive={mopriSettings.gatewaySettings.sticittActive} />
                            </>
                        }

                        {(mopriSettings.internationalSettings.localizationCountry === 'IRL' || mopriSettings.internationalSettings.localizationCountry === 'GBR') &&
                            <>
                                <ElavonSettings
                                    gatewaySettings={mopriSettings.gatewaySettings}
                                    successReturnUrl={successReturnUrl}
                                    cancelReturnUrl={cancelReturnUrl}
                                    declinedReturnUrl={declinedReturnUrl}
                                />

                                <OpayoSettings
                                    gatewaySettings={mopriSettings.gatewaySettings}
                                />
                            </>
                        }
                        
                    </div>
                </div>
            </Card>
        )

}
