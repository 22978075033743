import React, {useEffect, useRef, useState} from "react";
import {Toast} from "primereact/toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {ColorPicker} from "primereact/colorpicker";
import {faPaintbrush} from "@fortawesome/free-solid-svg-icons/faPaintbrush";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {updateThemeSettings} from "../../../../Actions/ThemeActions";
import axios from "axios";
import qs from "qs";


export const ThemeSettings = () => {
    const [colorHEX, setColorHEX] = useState('f97316');
    const myToast = useRef(null)
    const dispatch = useDispatch();
    const themeSettings = useSelector(state => state.theme);
    const apiURL = useSelector(state => state.mopriSettings.mopriAPIURL);

    useEffect(() => {
        if (themeSettings) {
            setColorHEX(themeSettings.buttonColor)
        }
    }, []);

    const updateWebsiteThemeSettings = () => {
        dispatch(updateThemeSettings(colorHEX))

        axios({
            method: 'post',
            url: `${apiURL}/api/updateThemeSettings`,
            data: qs.stringify({
                buttonColor: colorHEX
            }),
            config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
        })
            .then((response) => {
                if (response.data.error) {
                    myToast.current.show({severity: 'error', summary: 'ERROR', detail: response.data.errorMessage, life: 5000});
                } else {
                    myToast.current.show({severity: 'success', summary: 'SUCCESS', detail: response.data.message, life: 5000});
                }
            })
            .catch((error) => {
                myToast.current.show({severity: 'error', summary: 'ERROR', detail: 'There was an error while updating the theme settings.', life: 5000});
                console.log(error);
            });
    }

    return (
        <>
            <Toast ref={myToast} />
            <div className="p-fluid grid formgrid">
                <div className="field col-12">
                    <h4><FontAwesomeIcon icon={faPaintbrush} style={{paddingRight: '10px'}}/> Theme Settings</h4>
                    <div className="col-6">
                        <h6>Button Color (Hex)</h6>
                        <div className="grid">
                            <div className="col-2" style={{marginTop: '7px'}}>
                                <ColorPicker inputId="cp-hex" format="hex" value={colorHEX} style={{height: '25px'}}
                                             onChange={(e) => setColorHEX(e.value)}
                                />
                            </div>
                            <div className="col-6">
                                <InputText value={colorHEX}
                                           onChange={(e) => setColorHEX(e.target.value)}
                                />
                            </div>
                            <div className="col-4">
                                <Button style={{background: `#${colorHEX}`, cursor: 'none'}} label="Sample" />
                            </div>
                        </div>
                    </div>
                    <div className="col-6"> </div>
                </div>
            </div>
            <div className="p-fluid grid formgrid">
                <div className="field col-10"> </div>
                <div className="field col-2">
                    <Button label="Save" type="button"
                        onClick={(e) => updateWebsiteThemeSettings()}
                        style={themeSettings.buttonColor ? {background: `#${themeSettings.buttonColor}`} : {background: '#f97316'}}
                    />
                </div>
            </div>
        </>
    )
}