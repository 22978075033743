import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import qs from "qs";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Card} from "primereact/card";
import {useSelector} from "react-redux";
import {Toast} from "primereact/toast";
import dayjs from "dayjs";


export const LoginHistory = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [loginHistory, setLoginHistory] = useState([]);
    const [userID, setUserId] = useState(useSelector(state => state.userAuth).mopriUID);
    const myToast = useRef(null);
    const apiURL = useSelector(state => state.mopriSettings.mopriAPIURL);


    useEffect(() => {
        getLoginHistory()
    }, []);

    const getLoginHistory = () => {
        axios({
            method: 'post',
            url: `${apiURL}/api/getLoginHistory`,
            data: qs.stringify({
                userID: userID
            }),
            config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
        })
            .then((response) => {
                if (response.data.error) {
                    myToast.current.show({severity: 'error', summary: 'Error Message', detail: response.data.errorMessage, life: 5000});
                } else {
                    setLoginHistory(response.data.loginHistory);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error)
                setIsLoading(false);
            })
    }

    const loginDateBody = (rowData) => {
        return dayjs(rowData.loginDate).format('dddd, MMMM D, YYYY h:mm A');
    }

    return (
        <Card title="Login History">
            <Toast ref={myToast} />
            <div className="grid">
                <div className="col-12">
                    <div style={{marginTop: '10px'}}>
                        <DataTable value={loginHistory} tableStyle={{ minWidth: '50rem' }}
                                   paginator rows={10} rowsPerPageOptions={[10, 25, 50, 100]}
                                   stripedRows emptyMessage="No login history found."
                                   size="small"
                        >
                            <Column field="Date" header="Date" body={loginDateBody} />
                            <Column field="loginIP" header="IP Address" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </Card>
    )
}