import {Controller, useForm} from "react-hook-form";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import React from "react";
import axios from "axios";
import qs from "qs";
import {useSelector} from "react-redux";

const adminLevels = [
    { adminLevelDesc: 'Admin', adminLevelID: 1 },
    { adminLevelDesc: 'User', adminLevelID: 3 }
];

const activeLevels = [
    { id: "1", description: "Active" },
    { id: "2", description: "Inactive" }
]

export const UserManagementEdit = (props) => {
    const themeSettings = useSelector(state => state.theme);
    const apiURL = useSelector(state => state.mopriSettings.mopriAPIURL);

    const { control, handleSubmit, setValue,
        formState: { errors } } = useForm({
        defaultValues: {
            userPermission: props.userDetails.adminLevelID,
            userActive: props.userDetails.active
        }
    })

    const onSubmit = (data) => {
        console.log('data', data)

        axios({
            method: 'POST',
            url: `${apiURL}/api/updateUserDetails`,
            data: qs.stringify({
                userID: props.userDetails.userID,
                adminLevel: data.userPermission,
                userActive: data.userActive
            }),
            config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
        })
            .then((response) => {
                props.updateCompleted(response)
            })
            .catch((error) => {
                console.log(error);
            });

    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="p-fluid grid formgrid">
                    <div className="field col-6">
                        <span className="p-float-label" style={{fontSize: '12px', color: "#6c757d", paddingBottom: '5px'}}>
                             Select Permission Level
                        </span>
                        <Controller name="userPermission" control={control} rules={{required: 'Required.'}}
                                    render={({field, fieldState}) => (
                                        <Dropdown value={field.value} id={field.name}
                                                  options={adminLevels} optionLabel="adminLevelDesc"
                                                  optionValue="adminLevelID"
                                                  onChange={(e) => {
                                                      field.onChange(e.value)
                                                  }}
                                        />
                                    )}
                        />
                        {getFormErrorMessage('userPermission')}
                    </div>
                    <div className="field col-6">
                            <span className="p-float-label" style={{fontSize: '12px', color: "#6c757d", paddingBottom: '5px'}}>
                                 User Active?
                            </span>
                        <Controller name="userActive" control={control} rules={{required: 'Required.'}}
                                    render={({field, fieldState}) => (
                                        <Dropdown value={field.value} id={field.name}
                                                  options={activeLevels} optionLabel="description"
                                                  optionValue="id"
                                                  onChange={(e) => {
                                                      field.onChange(e.value)
                                                  }}
                                        />
                                    )}
                        />
                        {getFormErrorMessage('userActive')}
                    </div>
                </div>

                <div className="p-fluid grid formgrid">
                    <div className="field col-8"></div>
                    <div className="field col-4">
                        <Button label="Save" type="submit"
                                style={themeSettings.buttonColor ? {background: `#${themeSettings.buttonColor}`} : {background: '#f97316'}}
                        />
                    </div>
                </div>
            </form>
        </>
    )
}