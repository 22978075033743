import React, {useCallback, useEffect, useRef, useState} from 'react';
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Dashboard } from "./Pages/Dashboards/Dashboard";
import { Navbar } from "./Components/common/Navbar/Navbar";
import { Breadcrumb } from "./Components/common/Breadcrumb/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { AccountSettings } from "./Pages/AccountSettings/AccountSettings";
import { Login } from "./Pages/LoginRegister/Login/Login";
import { CompanySettings } from "./Pages/MopriSettings/CompanySettings/CompanySettings";
import { IntegrationSettings } from "./Pages/MopriSettings/IntegrationSettings/IntegrationSettings";
import { PaymentSettings } from "./Pages/MopriSettings/PaymentSettings/PaymentSettings";
import { UserManagement } from "./Pages/MopriSettings/UserManagement/UserManagement";
import { LoginHistory } from "./Pages/Reports/LoginHistory";
import { Topup } from "./Pages/Topup/Topup";
import { Register } from "./Pages/LoginRegister/Register/Register";
import { ForgotPassword } from "./Pages/LoginRegister/ForgotPassword/ForgotPassword";
import { ResetPassword } from "./Pages/LoginRegister/ResetPassword/ResetPassword";
import {
    updateAllowGuestRegistration, updateApiURL,
    updateCountryLocalization, updateMopriGatewaySettings,
    updateMopriIntegrationSettings, updateTermsConditions
} from "./Actions/MopriSettingActions";
import { Toast } from "primereact/toast";
import { PayGateReturn } from "./Pages/Topup/Widgets/PayGateReturn";
import {logout, updateUserBalance} from "./Actions/AuthActions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRotate} from "@fortawesome/free-solid-svg-icons";
import {EmailConfirm} from "./Pages/LoginRegister/EmailConfirm/EmailConfirm";
import {updateThemeSettings} from "./Actions/ThemeActions";
import {Footer} from "./Components/common/Footer/Footer";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { throttle } from "lodash";
import {Button} from "primereact/button";


export const App = () => {
    const [currency, setCurrency] = useState('');
    const userAuth = useSelector(state => state.userAuth);
    const internationalSettings = useSelector(state => state.mopriSettings.internationalSettings);
    const themeSettings = useSelector(state => state.theme);
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const apiURL = window.apiURL;
    const myToast = useRef(null)
    const warnTimeout = useRef(null);
    const logoutTimeout = useRef(null);
console.log('API URL ' + apiURL)
    const EVENTS = [
        "load",
        "mousemove",
        "mousedown",
        "click",
        "scroll",
        "keypress"
    ];

    const warn = () => {
        console.log("You will be logged out automatically in 1 minute.");
        confirmDialog({
            group: 'headless',
            message: 'You are about to be logged out due to inactivity?',
            header: 'Warning',
            icon: 'pi pi-spin pi-spinner',
            defaultFocus: 'accept',
        });
    };

    const startLogout = () => {
        console.log("Sending a logout request...");
        dispatch(logout());
    };

    const clearTimeouts = () => {
        clearTimeout(warnTimeout.current);
        clearTimeout(logoutTimeout.current);
    };

    const setTimeouts = () => {
        warnTimeout.current = setTimeout(warn, 1140000);  // Time to warning
        logoutTimeout.current = setTimeout(startLogout, 1200000); // Time to warning + 1 minute and do logout.
    };

    const resetTimeout = useCallback(() => {
        clearTimeouts();
        setTimeouts();
        //console.log("Calling resetTimeout");
    }, []);

    const throttledResetTimeout = useRef(throttle(resetTimeout, 1000));

    useEffect(() => {
        if (userAuth.isLoggedIn) {
            EVENTS.forEach((event) => {
                window.addEventListener(event, throttledResetTimeout.current);
            });

            setTimeouts();

            return () => {
                clearTimeouts();
                EVENTS.forEach((event) => {
                    window.removeEventListener(event, throttledResetTimeout.current);
                });
            };
        }
    }, [EVENTS, throttledResetTimeout]);

    useEffect(() => {
        dispatch(updateApiURL(apiURL));
        const unprotectedRoute = (location.pathname !== '/login' || location.pathname !== '/register' || location.pathname !== '/forgot' || location.pathname.substring(0,7) !== '/reset')

        getThemeSettings();
        fetchRegistrationDetails();
        getAllowGuestRegistration();
        getLocalizationSettings();
        getTermsConditions();

        if (!userAuth.isLoggedIn && !unprotectedRoute) {
            navigate("/login")
        } else if (userAuth.isLoggedIn && unprotectedRoute) {
            getMopriGatewaySettings();
            getUserBalance();
        } else {
            getMopriGatewaySettings();
            getUserBalance();
        }

    }, [])

    const fetchRegistrationDetails = () => {
        axios.get(`${apiURL}/api/getMopriIntegrationSettings`)
            .then((response) => {
                if (response && response.data.error) {
                    myToast.current.show({severity: 'error', summary: 'Error Message', detail: response.data.errorMessage, life: 5000});
                } else {
                    dispatch(updateMopriIntegrationSettings(response.data.integrationSettings[0]))
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        switch (internationalSettings.localizationCurrency) {
            case 'Euro':
                setCurrency('€')
                break;
            case 'Pound Sterling':
                setCurrency('£')
                break;
            case 'South African Rand':
                setCurrency('R')
                break;
            case 'Namibian Dollar':
                setCurrency('N$')
                break;
            default:
                setCurrency('R')
                break;
        }
    }, [internationalSettings.localizationCurrency])

    const getMopriGatewaySettings = () => {
        const url = `${apiURL}/api/getMopriGatewaySettings`;
        axios.get(url)
            .then((response) => {
                if (response && response.data.error) {
                    myToast.current.show({severity: 'error', summary: 'ERROR', detail: response.data.errorMessage, life: 5000});
                } else {
                    dispatch(updateMopriGatewaySettings(response.data.gatewaySettings));
                }
            })
            .catch((error) => {
                console.log(error);
                myToast.current.show({severity: 'error', summary: 'ERROR', detail: 'An error took place while retrieving the payment gateway data.', life: 5000});
            });
    }

    const getUserBalance = () => {
        if (userAuth.isLoggedIn) {
            fetch(`${apiURL}/api/getUserBalance`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userName: userAuth.userName
                })
            })
                .then(response => response.json())
                .then(data => {
                    let balance = parseFloat(data.balance).toFixed(2)
                    dispatch(updateUserBalance({value: balance}))
                })
                .catch(error => {
                    // Handle any errors that occur during the fetch
                    console.error(error);
                });

            setInterval(getUserBalance, 600000);  // 10 minutes
        }
    }

    const getThemeSettings = () => {
        axios({
            method: 'get',
            url: `${apiURL}/api/getThemeSettings`,
            config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
        })
            .then((response) => {
                if (response.data.error) {
                    console.log(response.data.errorMessage);
                } else {
                    dispatch(updateThemeSettings(response.data.buttonColor))
                }
            })
            .catch((error) => {
                myToast.current.show({severity: 'error', summary: 'ERROR', detail: 'There was an error while retrieving the theme settings.', life: 5000});
                console.log(error);
            });
    }

    const getAllowGuestRegistration = () => {
        axios({
            method: 'get',
            url: `${apiURL}/api/getAllowGuestRegistration`,
            config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
        })
            .then((response) => {
                if (response.data.error) {
                    console.log(response.data.errorMessage);
                } else {
                    dispatch(updateAllowGuestRegistration({
                        allowGuestRegistration: response.data.allowGuestRegistration
                    }))
                }
            })
            .catch((error) => {
                myToast.current.show({severity: 'error', summary: 'ERROR', detail: 'There was an error while retrieving the localization settings.', life: 5000});
                console.log(error);
            });
    }

    const getLocalizationSettings = () => {
        axios({
            method: 'get',
            url: `${apiURL}/api/getLocalizationSettings`,
            config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
        })
            .then((response) => {
                if (response.data.error) {
                    myToast.current.show({severity: 'error', summary: 'ERROR', detail: response.data.errorMessage, life: 5000});
                } else {
                    dispatch(updateCountryLocalization({
                        countryCode: response.data.localizationCountry,
                        countryCurrency: response.data.localizationCurrency,
                    }))
                }
            })
            .catch((error) => {
                myToast.current.show({severity: 'error', summary: 'ERROR', detail: 'There was an error while retrieving the localization settings.', life: 5000});
                console.log(error);
            });
    }

    const getTermsConditions = () => {
        axios({
            method: 'GET',
            url: `${apiURL}/api/getTermsConditions`,
            config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
        })
            .then((response) => {
                if (response.data.error) {
                    console.log(response.data.error)
                    //myToast.current.show({severity: 'error', summary: 'ERROR', detail: response.data.errorMessage, life: 5000});
                } else {
                    dispatch(updateTermsConditions(response.data.termsConditions))
                }
            })
            .catch((error) => {
                //myToast.current.show({severity: 'error', summary: 'ERROR', detail: 'There was an error while retrieving the localization settings.', life: 5000});
                console.log(error);
            });
    }



    if (userAuth.isLoggedIn) {
        return (
            <>
                <Toast ref={myToast}/>
                <div className="layout-main">
                    <div className="grid">
                        <div className="col-12">
                            <Navbar/>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-9">
                            <Breadcrumb/>
                        </div>
                        <div className="col-3" style={{flex: '0 0 auto', padding: '0.5rem'}}>
                            <span style={{background: '#ffffff', border: '1px solid #e5e7eb', borderRadius: '6px', padding: '14px', fontSize: '20px', fontWeight: 'bolder', display: 'block', height: '50px'}}>
                                <span style={{float: "right"}}>
                                    <FontAwesomeIcon icon={faRotate} style={{marginRight: '10px', cursor: 'pointer'}} onClick={(e) => getUserBalance()}/> {currency} {userAuth.balance}</span>
                            </span>
                        </div>
                    </div>
                    <div className="grid" style={{padding: '15px', paddingBottom: '0px'}}>
                        <div className="col-12">
                            <Routes>
                                <Route path="/" exact element={<Dashboard/>}/>

                                {/* USER ACCOUNT SETTINGS*/}
                                <Route path="/settings" element={<AccountSettings/>}/>


                                {/* MOPRI SETTINGS*/}
                                <Route path="/company-settings" element={<CompanySettings/>}/>
                                <Route path="/integration-settings" element={<IntegrationSettings/>}/>
                                <Route path="/payment-settings" element={<PaymentSettings/>}/>
                                <Route path="/user-management" element={<UserManagement/>}/>

                                {/*REPORTS*/}
                                <Route path="/login-history" element={<LoginHistory/>}/>

                                {/*TOPUP*/}
                                <Route path="/topup" element={<Topup/>}/>
                                <Route path="/paygate/:id?" element={<PayGateReturn/>}/>


                                {/*
                                    <Route path="/snapscan-confirm" component={SnapScanPayment}/>
                                    <Route path="/sticitt-confirm" component={SticittPayment}/>
                                    <Route path="/payfast-confirm" component={PayFastPayment}/>
                                    <Route path="/payfast-return" component={PayFastReturn}/>
                                    <Route path="/payfast-cancel" component={PayFastCancel}/>
                                    <Route path="/elavon-confirm" component={ElavonPayment}/>
                                    <Route path="/elavon-cancel" component={ElavonCancel}/>
                                    <Route path="/elavon-success" component={ElavonReturn}/>
                                    <Route path="/elavon-decline" component={ElavonDecline}/>
                                    <Route path="/opayo-success" component={OpayoSuccess}/>
                                    <Route path="/opayo-decline" component={OpayoDecline}/>

                                        <Route path="/content-settings" component={ContentSettings}/>
                                        <Route path="/general-settings" component={GeneralSettings}/>

                                <Route path="/help" component={Faq}/>

                                <Route path="/print-report" component={PrintReport} />
                                <Route path="/copy-report" component={CopyReport} />*/}
                            </Routes>
                        </div>
                    </div>

                    <Footer/>
                </div>

                <ConfirmDialog
                    group="headless"
                    content={({headerRef, contentRef, footerRef, hide, message}) => (
                        <div className="flex flex-column align-items-center p-5 surface-overlay border-round">
                            <div className="border-circle inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8" style={themeSettings.buttonColor ? {background: `#${themeSettings.buttonColor}`, color: '#FFFFFF', } : {background: '#f97316', color: '#FFFFFF', } }>
                                <i className="pi pi-stopwatch text-5xl"></i>
                            </div>
                            <span className="font-bold text-2xl block mb-2 mt-4" ref={headerRef}>
                                {message.header}
                            </span>
                            <p className="mb-0" ref={contentRef}>
                                {message.message}
                            </p>
                            <div className="flex align-items-center gap-8 mt-4" ref={footerRef}>
                                <Button label="Logout" style={themeSettings.buttonColor ? {background: `#${themeSettings.buttonColor}`} : {background: '#f97316'}}
                                    onClick={(event) => {
                                        hide(event);
                                        dispatch(logout());
                                    }}
                                />
                                <Button label="Continue" style={themeSettings.buttonColor ? {background: `#${themeSettings.buttonColor}`} : {background: '#f97316'}}
                                    onClick={(event) => hide(event) }
                                />
                            </div>
                        </div>
                    )}
                />
            </>
        )
    } else {
        return (
            <Routes>
                <Route path="/" exact element={<Login/>}/>
                <Route path="/login" element={<Login /> } />
                <Route path="/register" element={ <Register /> } />
                <Route path="/forgot" element={ <ForgotPassword /> } />
                <Route path="/reset/:uid?" element={ <ResetPassword /> } />
                <Route path="/emailconfirm/:uid?" element={ <EmailConfirm /> } />
            </Routes>
        )
    }

}


