import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {Controller, useForm} from "react-hook-form";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {ErrorMessage} from "../../../Components/common/Messages/ErrorMessage";
import {Toast} from "primereact/toast";
import useWindowSize from "../../../Utils/Auth/UseWindowSize";
import {LogRegSlider} from "../Widgets/LogRegSlider";
import {SuccessMessage} from "../../../Components/common/Messages/SuccessMessage";
import {ProgressSpinner} from "primereact/progressspinner";
import {useDispatch, useSelector} from "react-redux";
import {updateApiURL} from "../../../Actions/MopriSettingActions";


export const EmailConfirm = () => {
    const [validatingToken, setValidatingToken] = useState(true)
    const [tokenValid, setTokenValid] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [logoURL, setLogoURL] = useState('img/mopri-logo-large.png')
    const [showLogo, setShowLogo] = useState(true)
    const [fixFormPosition, setFixFormPosition] = useState(false)
    const myToast = useRef(null)
    const params = useParams();
    const uid = params.uid;
    const navigate = useNavigate();
    const size = useWindowSize();
    const dispatch = useDispatch();
    const apiURL = window.apiURL;
    //const apiURL = useSelector(state => state.mopriSettings.mopriAPIURL);

    useEffect(() => {
        dispatch(updateApiURL(apiURL));
        // check if the Token is valid
        verifyEmail()
        if (window.innerWidth >= 769 && window.innerWidth <=991) {
            setFixFormPosition(true)
        }
    }, []);

    const verifyEmail = () => {
        setValidatingToken(true)
        setShowErrorMessage(false)
        setErrorMessage('')

        axios({
            method: 'post',
            url: `${apiURL}/api/verifyEmail`,
            data: {
                uid: uid
            },
            config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
        })
            .then((response) => {
                if (response.data.error) {
                    setShowErrorMessage(true)
                    setErrorMessage(response.data.message)
                    myToast.current.show({severity: 'error', summary: 'ERROR', detail: response.data.message, life: 5000});
                } else {
                    setTokenValid(true)
                    myToast.current.show({severity: 'success', summary: 'SUCCESS', detail: `Your email address have been confirmed.  You will be redirected to login shortly.`, life: 5000});
                    setTimeout(() => {
                        navigate('/login')
                    }, 3000)
                }
                setValidatingToken(true)
            })
            .catch((error) => {
                myToast.current.show({severity: 'error', summary: 'ERROR', detail: 'An error took place while confirming your email address.  Please contact IT for assistance', life: 5000});
                console.log(error)
            })
    }

    return (
        <>
            <Toast ref={myToast} />
            <div className="layout-main">
                <div className="grid">
                    {(size.width >= 620) &&
                        <div className="col-fixed" style={{paddingRight: '200px', padding: '0px', width: '300px'}}>
                            <LogRegSlider />
                        </div>
                    }
                    <div className="col-6 md:col-12 lg:col-3" style={{paddingLeft: '40px'}}>
                        <div style={{position: 'absolute', top: '50%', transform: `${fixFormPosition ? 'translate(310px, -50%)' : 'translate(0px, -50%)'}`}}>
                            <div className="p-fluid grid formgrid" style={{paddingTop: '20px'}}>
                                <div className="field col-12">
                                    <div className="h5 modal-title text-center">
                                        {showLogo &&
                                            <>
                                                {logoURL ?
                                                    <img src={`${window.location.origin}/${logoURL}`} alt="Logo"/>
                                                    :
                                                    <img src={`${window.location.origin}/img/mopri-logo-large.png`} alt="Mopri"/>
                                                }
                                            </>
                                        }

                                        {validatingToken ?
                                            <div className="card flex justify-content-center">
                                                <ProgressSpinner/>
                                            </div>
                                            :
                                            <>
                                                <h4 className="mt-2" style={{paddingBottom: '100px'}}>
                                                    <div>Email Confirmation</div>
                                                </h4>

                                                {tokenValid &&
                                                    <SuccessMessage message="Your email has been verified. You will now be able to login."/>
                                                }

                                                {!tokenValid &&
                                                    <ErrorMessage message={errorMessage}/>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="md:col-12 lg:col-3"></div>
                </div>
            </div>
        </>
    )
}