import {
    UPDATE_COUNTRY_LOCALIZATION,
    UPDATE_TERMS_CONDITIONS,
    UPDATE_GATEWAYS,
    UPDATE_INTEGRATION_SETTINGS,
    UPDATE_SITE_GROUPS,
    UPDATE_PRINTER_INTEGRATION,
    UPDATE_ALLOW_GUEST_REGISTRATION,
    UPDATE_API_URL,
    UPDATE_GATEWAY_SETTINGS,



    FETCH_MOPRI_GATEWAYS,
    FETCH_MOPRI_GATEWAY_DATA,
    FETCH_MOPRI_INTEGRATION_DATA,
    FETCH_MOPRI_REGISTRATION_DATA,
    FETCH_ACTIVE_DIRECTORY_USERS,
    UPDATE_FEES_SETTINGS, UPDATE_SNAPSCAN_SETTINGS,
    UPDATE_SITE_USERS,
    UPDATE_SITE_SETTINGS,
    FETCH_COMPANY_SETTINGS,
    UPDATE_USER_GROUP,
    UPDATE_USER_GROUPS,
    ADD_OPAYO_MERCHANT_SESSION_KEYS
} from '../Actions/MopriSettingActions';
import {LOGOUT} from "../Actions/AuthActions";


const initialState = {
    internationalSettings: {
        countryCode: null,
        countryCurrency: null
    },
    systemIntegration: {
        printerIntegrationID: null,
        pinRegister: null,
        generatePinCode: null,
        changePinCode: null,
        useActiveDirectory: null,
        userRegType: null,
        regURL: null,
        lastAdminUpdated: null,
        lastUpdateDate:null,
        adAdminUpdate: null,
        adAdminUpdateDate: null,
    },
    gateways: {},
    gatewaySettings: {
        minTopupAmount: 0,
    },
    systemUsers: [],
    companySettings: [],
    siteGroups: [],
    termsConditions: null
};


export default (state = initialState, action) => {
    switch (action.type) {

        case LOGOUT:
            return {
                ...initialState
            };

        case UPDATE_COUNTRY_LOCALIZATION:
            return {
                ...state,
                internationalSettings: {
                    localizationCountry: action.values.countryCode,
                    localizationCurrency: action.values.countryCurrency
                }
            }

        case UPDATE_TERMS_CONDITIONS:
            return {
                ...state,
                termsConditions: action.values
            }

        case UPDATE_FEES_SETTINGS:
            return {
                ...state,
                gatewaySettings: {
                    ...state.gatewaySettings,
                    minTopupAmount: action.values.minTopupAmount
                }
            };

        case UPDATE_GATEWAYS:
            return {
                ...state,
                gatewaySettings: {
                    ...state.gatewaySettings,
                    ...action.values[0]
                }
            };

        case UPDATE_GATEWAY_SETTINGS:
            return {
                ...state,
                gatewaySettings: {
                    ...state.gatewaySettings,
                    ...action.values
                }
            }

            case UPDATE_INTEGRATION_SETTINGS:
                return {
                    ...state,
                    systemIntegration: {
                        ...state.systemIntegration,
                        ...action.values
                    }
                }

                case UPDATE_SITE_GROUPS:
                    return {
                        ...state,
                        siteGroups: action.values
                    }

        case UPDATE_PRINTER_INTEGRATION:
            return {
                ...state,
                systemIntegration: {
                    ...state.systemIntegration,
                    ...action.values
                }
            };

        case UPDATE_ALLOW_GUEST_REGISTRATION:
            return {
                ...state,
                systemIntegration: {
                    ...state.systemIntegration,
                    allowGuestRegistration: action.values.allowGuestRegistration
                }
            };

            case UPDATE_API_URL:
                return {
                    ...state,
                    mopriAPIURL: action.values
                }













        case FETCH_MOPRI_GATEWAYS:
            return {
                ...state,
                gateways: {
                    ...action.payload.gateways
                }
            }

        case FETCH_MOPRI_GATEWAY_DATA:
            return {
                ...state,
                gatewaySettings: {
                    snapScanActive: action.payload.gatewaySettings.snapScanActive,
                    snapScanMerchID: action.payload.gatewaySettings.snapScanMerchID,
                    snapScanAPIKey: action.payload.gatewaySettings.snapScanAPIKey,
                    snapScanEndpoint: action.payload.gatewaySettings.snapScanEndpoint,
                    snapScanVisaComm: action.payload.gatewaySettings.snapScanVisaComm,
                    snapScanVisaActive: action.payload.gatewaySettings.snapScanVisaActive,
                    payFastActive: action.payload.gatewaySettings.payFastActive,
                    payFastMerchId: action.payload.gatewaySettings.payFastMerchId,
                    payFastAPIKey: action.payload.gatewaySettings.payFastAPIKey,
                    payFastEFTComm: action.payload.gatewaySettings.payFastEFTComm,
                    payFastEFTFee: action.payload.gatewaySettings.payFastEFTFee,
                    payFastEFTActive: action.payload.gatewaySettings.payFastEFTActive,
                    payFastCcardComm: action.payload.gatewaySettings.payFastCcardComm,
                    payFastCcardFee: action.payload.gatewaySettings.payFastCcardFee,
                    payFastCcardActive: action.payload.gatewaySettings.payFastCcardActive,
                    payFastMasterPassComm: action.payload.gatewaySettings.payFastMasterPassComm,
                    payFastMasterPassFee: action.payload.gatewaySettings.payFastMasterPassFee,
                    payFastMasterPassActive: action.payload.gatewaySettings.payFastMasterPassActive,
                    payFastVisaElectronComm: action.payload.gatewaySettings.payFastVisaElectronComm,
                    payFastVisaElectronFee: action.payload.gatewaySettings.payFastVisaElectronFee,
                    payFastVisaElectronActive: action.payload.gatewaySettings.payFastVisaElectronActive,
                    payFastZapperComm: action.payload.gatewaySettings.payFastZapperComm,
                    payFastZapperActive: action.payload.gatewaySettings.payFastZapperActive,
                    payFastMobicredComm: action.payload.gatewaySettings.payFastMobicredComm,
                    payFastMobicredActive: action.payload.gatewaySettings.payFastMobicredActive,
                    payFastScodeComm: action.payload.gatewaySettings.payFastScodeComm,
                    payFastScodeFee: action.payload.gatewaySettings.payFastScodeFee,
                    payFastScodeActive: action.payload.gatewaySettings.payFastScodeActive,
                    saveSuccessMsg: null,
                    saveFailed: null,
                    saveFailMsg: null,
                    feeStructure: action.payload.gatewaySettings.feeStructure,
                    gatewayActive: true,
                    payFastSandbox: action.payload.gatewaySettings.payFastSandbox,
                    printerIntegrationID: action.payload.gatewaySettings.printerIntegrationID,
                    minTopupAmount: action.payload.gatewaySettings.minTopupAmount,
                    sticittActive: action.payload.gatewaySettings.sticittActive,
                    sticittSandbox: action.payload.gatewaySettings.sticittSandbox,
                    sticittMerchantID: action.payload.gatewaySettings.sticittMerchantID,
                    sticittMerchantName: action.payload.gatewaySettings.sticittMerchantName,
                    sticittMerchantAccountRef: action.payload.gatewaySettings.sticittMerchantAccountRef,
                    elavonActive: action.payload.gatewaySettings.elavonActive,
                    elavonMerchId: action.payload.gatewaySettings.elavonMerchId,
                    elavonSandbox: action.payload.gatewaySettings.elavonSandbox,
                    merchantPin: action.payload.gatewaySettings.merchantPin,
                    convergeUserID: action.payload.gatewaySettings.convergeUserID,
                    opayoActive: action.payload.gatewaySettings.opayoActive,
                    opayoProductionIntegrationKey: action.payload.gatewaySettings.opayoProductionIntegrationKey,
                    opayoProductionIntegrationPassword: action.payload.gatewaySettings.opayoProductionIntegrationPassword,
                    opayoProductionVendorName: action.payload.gatewaySettings.opayoProductionVendorName,
                    opayoSandboxIntegrationKey: action.payload.gatewaySettings.opayoSandboxIntegrationKey,
                    opayoSandboxIntegrationPassword: action.payload.gatewaySettings.opayoSandboxIntegrationPassword,
                    opayoSandboxVendorName: action.payload.gatewaySettings.opayoSandboxVendorName,
                    opayoSandbox: action.payload.gatewaySettings.opayoSandbox
                },
            };

        case FETCH_MOPRI_INTEGRATION_DATA:
            return {
                ...state,
                systemIntegration: {
                    isfetchingSoftwareIntegrations: false,
                    printerIntegrationID: action.payload.systemIntegration.printerIntegrationID,
                    printReleaseJobType: action.payload.systemIntegration.printReleaseJobType,
                    pinRegister: action.payload.systemIntegration.pinRegister,
                    generatePinCode: action.payload.systemIntegration.generatePinCode,
                    changePinCode: action.payload.systemIntegration.changePinCode,
                    useActiveDirectory: action.payload.systemIntegration.useActiveDirectory,
                    userRegType: action.payload.systemIntegration.userRegType,
                    regURL: action.payload.systemIntegration.regURL,
                    saveSuccess: action.payload.systemIntegration.saveSuccess,
                    saveErrorMsg: action.payload.systemIntegration.errorMsg,
                    lastAdminUpdated: action.payload.systemIntegration.lastAdminUpdated,
                    lastUpdateDate: action.payload.systemIntegration.lastUpdateDate,
                    userGroup: action.payload.systemIntegration.userGroup,
                    selectedUserGroup: action.payload.systemIntegration.selectedUserGroup
                },
                siteGroups: action.payload.siteGroups
            };

        case UPDATE_USER_GROUPS:
            return {
                ...state,
                siteGroups: action.siteGroups
        };

        case UPDATE_USER_GROUP:
            return {
                ...state,
                siteGroups: action.siteGroups
        };






        case FETCH_MOPRI_REGISTRATION_DATA:  // Used for the registration process to show applicable form fields
            return {
                ...state,
                systemIntegration: {
                    siteID: action.payload.data.systemIntegration.siteID,
                    printerIntegrationID: action.payload.data.systemIntegration.printerIntegrationID,
                    pinRegister: action.payload.data.systemIntegration.pinRegister,
                    selectedUserGroup: action.payload.data.systemIntegration.selectedUserGroup,
                    userRegistrationType: action.payload.data.systemIntegration.userRegistrationType
                }
            };

        case FETCH_ACTIVE_DIRECTORY_USERS:
            return {
                ...state,
            };



        case UPDATE_SNAPSCAN_SETTINGS:
            return {
                ...state,
                snapScanActive: action.snapScanActive,
                snapScanMerchID: action.snapScanMerchID,
                snapScanAPIKey: action.snapScanAPIKey
            };

        case UPDATE_SITE_SETTINGS:
            return {
                ...state,
                companyName: action.values.companyName
            };

        case FETCH_COMPANY_SETTINGS:
            return {
                ...state,
                siteSettings: action.payload.data.siteSettings
            };

        case UPDATE_SITE_USERS:
            return {
                ...state,
                systemUsers: action.values.systemUsers
            };


        case ADD_OPAYO_MERCHANT_SESSION_KEYS:
            return {
                ...state,
                merchantSessionKey: action.values.merchantSessionKey
            }
        default:
            return state
    }
};
