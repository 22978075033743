import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
//import EditMopriUser from './EditMopriUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import axios from "axios";
import {Toast} from "primereact/toast";
import {updateMopriUsersList} from "../../../../Actions/MopriSettingActions";
import {faPencil} from "@fortawesome/free-solid-svg-icons/faPencil";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Controller, useForm} from "react-hook-form";
import qs from "qs";
import {UserManagementEdit} from "./UserManagementEdit";



export const UserManagementSettings = () => {
    const [users, setUsers] = useState(useSelector(state => state.mopriSettings.systemUsers));
    const [visible, setVisible] = useState(false);
    const [userDetails, setUserDetails] = useState({})
    const myToast = useRef(null);
    const dispatch = useDispatch();
    const apiURL = useSelector(state => state.mopriSettings.mopriAPIURL);



    useEffect(() => {
        getSiteUsers();
    }, []);

    const getSiteUsers = () => {
        const url = `${apiURL}/api/getSiteUsers`;
        axios.get(url)
            .then((response) => {
                if (response && response.data.error) {
                    myToast.current.show({severity: 'error', summary: 'ERROR', detail: response.data.errorMessage, life: 5000});
                } else {
                    setUsers(response.data.users);
                }
            })
            .catch((error) => {
                console.log(error);
                myToast.current.show({severity: 'error', summary: 'ERROR', detail: 'An error took place retrieving the Mopri users', life: 5000});
            });
    }

    const editUserDetails = (rowData) => {
        setVisible(true)
        setUserDetails(rowData)
    }

    const updateCompleted = (response) => {
        if (response.data.error) {
            myToast.current.show({severity: 'error', summary: 'ERROR', detail: response.data.errorMessage, life: 5000});
        } else {
            getSiteUsers();
            setVisible(false)
            setUserDetails({})
            myToast.current.show({severity: 'success', summary: 'ERROR', detail: 'The users details where successfully updated.', life: 5000});
        }
    }

    const renderActiveDisplay = (rowData) => {
        return (
            <div>
                {rowData.active === "1" ?
                    <FontAwesomeIcon icon={faCheckCircle} className='success' style={{color: "green"}} />
                :
                    <FontAwesomeIcon icon={faTimesCircle} className="warning" style={{color: "red"}} />
                }
            </div>
        )
    }

    const renderGuestUserDisplay = (rowData) => {
        return (
            <div>
                {rowData.guestUser ?
                    <FontAwesomeIcon icon={faCheckCircle} className='success' style={{color: "green"}} />
                :
                    <FontAwesomeIcon icon={faTimesCircle} className="warning" style={{color: "red"}} />
                }
            </div>
        )
    }

    const renderAdminLevel = (rowData) => {
        return (
            <div>
                <FontAwesomeIcon icon={faPencil} className="warning" style={{color: "blue", cursor: 'pointer'}}
                                 onClick={() => editUserDetails(rowData)}
                />
            </div>
        )
    }


    return (
        <div style={{marginTop: '10px'}}>
            <Toast ref={myToast} />
            <DataTable value={users} tableStyle={{ minWidth: '50rem' }}
                       paginator rows={10} rowsPerPageOptions={[10, 25, 50, 100]}
                       stripedRows emptyMessage="No users found.">
                <Column field="firstName" header="First Name" />
                <Column field="lastName" header="Last Name" />
                <Column field="adminLevelDesc" header="Permission Level" />
                <Column field="guestUser" header="Guest User" body={renderGuestUserDisplay} />
                <Column field="userActive" header="Active" body={renderActiveDisplay} />
                <Column field="adminLevelDesc" header="Edit" body={renderAdminLevel} />
            </DataTable>





            <Dialog header={`Edit ${userDetails.firstName} ${userDetails.lastName}`}
                    visible={visible} style={{width: '500px'}}
                    onHide={() => setVisible(false)}
            >
                <UserManagementEdit userDetails={userDetails} updateCompleted={updateCompleted} />
            </Dialog>

        </div>
)

}