import { Base64 } from 'js-base64';
import {ADD_OPAYO_MERCHANT_SESSION_KEYS} from "../../Actions/MopriSettingActions";
import configureStore from "../../Store/store";
import axios from "axios";
import qs from "qs";
import {SHOW_3D_SECURE, TOPUP_GUID} from "../../Actions/MopriPaymentActions";



export class OpayoGatewayService {

    async getMerchantSessionKey() {
        const opayoSandbox = configureStore.getState().mopriSettings.gatewaySettings.opayoSandbox;
        let integrationKey, vendorName, password, subURL
        if (opayoSandbox === true) {
            integrationKey = configureStore.getState().mopriSettings.gatewaySettings.opayoSandboxIntegrationKey;
            vendorName = configureStore.getState().mopriSettings.gatewaySettings.opayoSandboxVendorName;
            password = configureStore.getState().mopriSettings.gatewaySettings.opayoSandboxIntegrationPassword;
            subURL = 'pi-test'
        } else {
            integrationKey = configureStore.getState().mopriSettings.gatewaySettings.opayoProductionIntegrationKey;
            vendorName = configureStore.getState().mopriSettings.gatewaySettings.opayoProductionVendorName;
            password = configureStore.getState().mopriSettings.gatewaySettings.opayoProductionIntegrationPassword;
            subURL = 'pi-live'
        }
        const opayoAuth = Base64.encode(`${integrationKey}:${password}`);

        return fetch(`https://${subURL}.sagepay.com/api/v1/merchant-session-keys`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': `Basic ${opayoAuth}`
            },
            body: JSON.stringify({
                vendorName: vendorName
            })
        })
            .then(res => res.json())
            .then((result) => {
                configureStore.dispatch({type: ADD_OPAYO_MERCHANT_SESSION_KEYS, values: result});
                return result
            })
    }

    async verifyMerchantKeyValidity() {
        const merchantSessionKey = configureStore.getState().mopriSettings.merchantSessionKey
        const opayoSandbox = configureStore.getState().mopriSettings.gatewaySettings.opayoSandbox;
        let integrationKey, vendorName, password, subURL
        if (opayoSandbox === true) {
            integrationKey = configureStore.getState().mopriSettings.gatewaySettings.opayoSandboxIntegrationKey;
            vendorName = configureStore.getState().mopriSettings.gatewaySettings.opayoSandboxVendorName;
            password = configureStore.getState().mopriSettings.gatewaySettings.opayoSandboxIntegrationPassword;
            subURL = 'pi-test'
        } else {
            integrationKey = configureStore.getState().mopriSettings.gatewaySettings.opayoProductionIntegrationKey;
            vendorName = configureStore.getState().mopriSettings.gatewaySettings.opayoProductionVendorName;
            password = configureStore.getState().mopriSettings.gatewaySettings.opayoProductionIntegrationPassword;
            subURL = 'pi-live'
        }
        const opayoAuth = Base64.encode(`${integrationKey}:${password}`);

        return fetch(`https://${subURL}.sagepay.com/api/v1/merchant-session-keys${merchantSessionKey}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': `Basic ${opayoAuth}`
            },
        })
            .then(res => res.json())
            .then((result) => {
                return result.merchantSessionKey === merchantSessionKey;
            })
    }

    async cardIdentifiers(values) {
        const merchantSessionKey = configureStore.getState().mopriSettings.merchantSessionKey;
        const mopriUID = configureStore.getState().userAuth.mopriUID;
        const opayoSandbox = configureStore.getState().mopriSettings.gatewaySettings.opayoSandbox;
        let subURL
        if (opayoSandbox === true) {
            subURL = 'pi-test'
        } else {
            subURL = 'pi-live'
        }


        return fetch(`https://${subURL}.sagepay.com/api/v1/card-identifiers`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${merchantSessionKey}`
            },
            body: JSON.stringify({
                cardDetails: {
                    cardholderName: values.cardholderName,
                    cardNumber: values.cardNumber,
                    expiryDate: values.expiryDate,
                    securityCode: values.securityCode
                }
            })
        })
            .then(res => res.json())
            .then((result) => {
                if (result.description === 'Authentication failed') {
                    //NotificationManager.error('The merchant was unable to Authenticate your card.  Your account has not been charged.', 'Error');

                    // Update the Database that the transaction failed.
                    const params = {
                        success: false,
                        opayoResponse: result
                    }
                    const opayoService = new OpayoGatewayService();
                    opayoService.updatePaymentDetails(params)

                } else {
                    // Create a pending Transaction and return the Payment GUID
                    axios({
                        method: 'post',
                        url: '/api/pmt/createPendingTransaction.asp',
                        data: qs.stringify({
                            topUpValue: values.pmtValue,
                            paymentGatewaysID: 6,
                            userID: mopriUID
                        }),
                        config: {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
                    })
                        .then(function (response) {
                            let topUpGUID = response.data.replace('{', '')
                            let cleanGUID = topUpGUID.replace('}', '')
                            configureStore.dispatch({type: TOPUP_GUID, values: cleanGUID})
                            const opayoService = new OpayoGatewayService();
                            opayoService.transactionRequest(values, result, cleanGUID);
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            })
            .catch((error) => {console.log(error)})
    }

    async transactionRequest(values, result, topUpGUID) {
        const merchantSessionKey = configureStore.getState().mopriSettings.merchantSessionKey;
        const firstName = configureStore.getState().userAuth.userFirstName;
        const lastName = configureStore.getState().userAuth.userLastName;
        const localizationCurrency = configureStore.getState().userAuth.localizationCurrency;
        const customPmtValue = configureStore.getState().topup.customPmtValue;
        let value = parseInt(customPmtValue) * 100

        const opayoSandbox = configureStore.getState().mopriSettings.gatewaySettings.opayoSandbox;
        let integrationKey, vendorName, password, subURL
        if (opayoSandbox === true) {
            integrationKey = configureStore.getState().mopriSettings.gatewaySettings.opayoSandboxIntegrationKey;
            password = configureStore.getState().mopriSettings.gatewaySettings.opayoSandboxIntegrationPassword;
            subURL = 'pi-test'
        } else {
            integrationKey = configureStore.getState().mopriSettings.gatewaySettings.opayoProductionIntegrationKey;
            password = configureStore.getState().mopriSettings.gatewaySettings.opayoProductionIntegrationPassword;
            subURL = 'pi-live'
        }
        const opayoAuth = Base64.encode(`${integrationKey}:${password}`);
        // Determine the currency
        let currency;
        switch (localizationCurrency) {
            case 'Pound Sterling':
                currency = 'GBP';
                break;

            case 'Euro':
                currency = 'EUR';
                break;

            default:
                currency = 'EUR';
                break
        }

        return fetch(`https://${subURL}.sagepay.com/api/v1/transactions`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, *!/!*',
                'Content-Type': 'application/json',
                'Authorization': `Basic ${opayoAuth}`
            },
            body: JSON.stringify(
                {
                    "transactionType":"Payment",
                    "paymentMethod":{
                        "card":{
                            "merchantSessionKey": `${merchantSessionKey}`,
                            "cardIdentifier": `${result.cardIdentifier}`
                        }
                    },
                    "vendorTxCode":`${topUpGUID}`,
                    "amount": value,
                    "currency": `${currency}`,
                    "description": "Mopri pre-paid printing credit purchase.",
                    "customerFirstName": `${firstName}`,
                    "customerLastName": `${lastName}`,
                    "entryMethod": "Ecommerce",
                    "apply3DSecure": "UseMSPSetting",
                    "billingAddress":{
                        "address1": `${values.line1}`,
                        "address2": `${values.line2}`,
                        "city": `${values.city}`,
                        "postalCode": `${values.postCode}`,
                        "country": `${values.country}`
                    }
                })
        })
            .then(res => res.json())
            .then((result) => {
                switch (result.statusCode) {
                    case '2007':
                    case '2021':
                        // Set the state to show the 3D Secure URL
                        configureStore.dispatch({
                            type: SHOW_3D_SECURE,
                            values: {
                                threeDSecure: true,
                                paReq: result.paReq ? result.paReq : null,
                                cReq: result.cReq ? result.cReq : null,
                                acsUrl: result.acsUrl ? result.acsUrl : null,
                                paRes: result.paRes ? result.paRes : null,
                                statusCode: result.statusCode,
                                transactionId: result.transactionId,
                                status: result.status,
                                acsTransId: result.acsTransId ? result.acsTransId : null,
                                dsTranId: result.dsTranId ? result.dsTranId : null,
                            }
                        })
                        return result

                    case '0000':
                        const params = {
                            success: true,
                            opayoResponse: result
                        }
                        const opayoService = new OpayoGatewayService();
                        opayoService.updatePaymentDetails(params);

                        return result

                    default:
                      // NotificationManager.error(`${result.statusDetail}`, 'Error');
                        return result

                }
            })
            .catch((error) => {console.log(error)})
    }

    async threeDSecureVerify(values) {
        const transactionId = configureStore.getState().topup.transactionId;
        const opayoSandbox = configureStore.getState().mopriSettings.gatewaySettings.opayoSandbox;
        let integrationKey, vendorName, password, subURL
        if (opayoSandbox === true) {
            integrationKey = configureStore.getState().mopriSettings.gatewaySettings.opayoSandboxIntegrationKey;
            password = configureStore.getState().mopriSettings.gatewaySettings.opayoSandboxIntegrationPassword;
            subURL = 'pi-test'
        } else {
            integrationKey = configureStore.getState().mopriSettings.gatewaySettings.opayoProductionIntegrationKey;
            password = configureStore.getState().mopriSettings.gatewaySettings.opayoProductionIntegrationPassword;
            subURL = 'pi-live'
        }
        const opayoAuth = Base64.encode(`${integrationKey}:${password}`);

        return fetch(`https://${subURL}.sagepay.com/api/v1/transactions/${transactionId}/3d-secure`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, *!/!*',
                'Content-Type': 'application/json',
                'Authorization': `Basic ${opayoAuth}`
            },
            body: JSON.stringify({
                "paRes": values
            })
        })
            .then(res => res.json())
            .then((result) => {
                if (result.status === 'Authenticated') {
                    return fetch(`https://${subURL}.sagepay.com/api/v1/transactions/${transactionId}`, {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json, text/plain, *!/!*',
                            'Content-Type': 'application/json',
                            'Authorization': `Basic ${opayoAuth}`
                        }
                    })
                        .then(res => res.json())
                        .then((result) => {
                            return result
                        })
                        .catch((error) => console.log(error))
                } else {
                    return false
                }
            })
            .catch((error) => console.log(error))
    }

    async updatePaymentDetails(value) {
        const mopriUID = configureStore.getState().userAuth.mopriUID;
        const customPmtValue = configureStore.getState().topup.customPmtValue;
        const topupGUID = configureStore.getState().topup.topupGUID;

        axios({
            method: 'post',
            url: '/api/pmt/Opayo/updateOpayoResponse.asp',
            data: qs.stringify({
                userID: mopriUID,
                amount: customPmtValue,
                transactionID: topupGUID,
                success: value.success,
                opayoResponse: JSON.stringify(value.opayoResponse),
            }),
            config: {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
        })
            .then((response) => {
                return response
            })
            .catch((error) => {
                console.log(error)
            })
    }

}