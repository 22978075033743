import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import axios from "axios";
import qs from "qs";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Tag} from "primereact/tag";
import {Toast} from "primereact/toast";
import {useDispatch, useSelector} from "react-redux";
import dateFormat, { masks } from "dateformat";


export const PaymentHistory = (props) => {
    const [currency, setCurrency] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [paymentHistory, setPaymentHistory] = useState([]);
    const myToast = useRef(null);
    const dispatch = useDispatch();
    const internationalSettings = useSelector(state => state.mopriSettings.internationalSettings);
    const apiURL = useSelector(state => state.mopriSettings.mopriAPIURL);

    useEffect(() => {
        switch (props.localizationCurrency) {
            case 'Euro':
                setCurrency('€')
                break;
            case 'Pound Sterling':
                setCurrency('£')
                break;
            case 'South African Rand':
                setCurrency('R')
                break;
            case 'Namibian Dollar':
                setCurrency('N$')
                break;
            default:
                setCurrency('R')
                break;
        }

        axios({
            method: 'post',
            url: `${apiURL}/api/getPaymentHistory`,
            data: qs.stringify({
                userID: props.mopriUID
            }),
            config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
        })
            .then((response) => {
                if (response.data.error) {
                    myToast.current.show({severity: 'error', summary: 'Error Message', detail: response.data.errorMessage, life: 5000});
                } else {
                    setPaymentHistory(response.data.paymentHistory);
                    dispatch({type: 'SET_PAYMENT_HISTORY', values: response.data.paymentHistory})
                }
                setIsLoading(false);
            })
            .catch((error) => console.log(error))
    }, []);

    const dateBodyTemplate = (rowData) => {
        return dateFormat(rowData.topUpDate, "fullDate") + ' ' + dateFormat(rowData.topUpDate, "shortTime"); //TODO Fix date format => dateFormat(rowData.topUpDate, "dddd, mmmm d, yyyy h:MM TT");
    }

    const descriptionBodyTemplate = (rowData) => {
        const currentDate = new Date();
        const rowDataDate = new Date(rowData.topUpDate)
        if (rowDataDate < currentDate && rowData.description === 'Pending') {
            rowData.description = 'Unsuccessful'
        }
        switch (rowData.description) {
            case 'Pending':
                return <Tag severity="warning" value="Pending" rounded></Tag>
            case 'Successful':
                return <Tag severity="success" value="Successful" rounded></Tag>
            case 'Unsuccessful':
                return <Tag severity="danger" value="Declined / Failed" rounded></Tag>
            default:
                return <Tag severity="warning" value="Pending" rounded></Tag>
        }
    }

    const topUpValueTemplate = (rowData) => {

        return getCurrencySymbol() + ' ' + rowData.topUpValue.toFixed(2)
    }

    const getCurrencySymbol = () => {
        switch (internationalSettings.localizationCurrency) {
            case 'Euro':
                return '€'
            case 'Pound Sterling':
                return '£'
            case 'South African Rand':
                return 'R'
            case 'Namibian Dollar':
                return 'N$'
            default:
                return 'R'
        }
    }

    return (
        <div style={{marginTop: '10px'}}>
            <Toast ref={myToast} />
            <h2 style={{paddingTop: '20px'}}>Topup History</h2>
            <DataTable value={paymentHistory} tableStyle={{ minWidth: '50rem' }}
                       paginator rows={10} rowsPerPageOptions={[10, 25, 50, 100]}
                       stripedRows emptyMessage="No topup history found."
            >
                <Column field="topUpDate" header="Date" body={dateBodyTemplate} />
                <Column field="paymentGatewayName" header="Pmt. Gateway" />
                <Column field="topUpValue" header="Value" body={topUpValueTemplate} />
                <Column field="description" header="Status" body={descriptionBodyTemplate} />
            </DataTable>
        </div>
    )

}

