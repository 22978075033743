import React, {useEffect, useRef, useState} from "react";
import { Editor } from "primereact/editor";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaintbrush} from "@fortawesome/free-solid-svg-icons/faPaintbrush";
import {Button} from "primereact/button";
import {useSelector} from "react-redux";
import axios from "axios";
import qs from "qs";
import {Toast} from "primereact/toast";


export const TermsConditions = (props) => {
    const [termsConditions, setTermsConditions] = useState(props.termsConditions);
    const themeSettings = useSelector(state => state.theme);
    const myToast = useRef(null)
    const apiURL = useSelector(state => state.mopriSettings.mopriAPIURL);

    /*useEffect(() => {
        setTermsConditions(.toString())
    }, []);*/

    const renderHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
            </span>
        );
    };

    const header = renderHeader();

    const updateTermsConditions = () => {

        axios({
            method: 'POST',
            url: `${apiURL}/api/updateTermsConditions`,
            data: qs.stringify({
                termsConditions: termsConditions
            }),
            config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
        })
            .then((response) => {
                if (response.data.error) {
                    myToast.current.show({severity: 'error', summary: 'ERROR', detail: response.data.errorMessage, life: 5000});
                } else {
                    myToast.current.show({severity: 'success', summary: 'SUCCESS', detail: response.data.message, life: 5000});
                }
            })
            .catch((error) => {
                myToast.current.show({severity: 'error', summary: 'ERROR', detail: 'There was an error while updating the terms & conditions.', life: 5000});
                console.log(error);
            });
    }

    return (
        <>
            <Toast ref={myToast} />
            <div className="p-fluid grid formgrid">
                <div className="field col-8">
                    <h4><FontAwesomeIcon icon={faPaintbrush} style={{paddingRight: '10px'}}/> Terms & Conditions</h4>
                    <Editor value={termsConditions} headerTemplate={header} style={{height: '320px'}}
                            onTextChange={(e) => setTermsConditions(e.htmlValue)}
                    />
                </div>
                <div className="field col-4"></div>
            </div>

            <div className="p-fluid grid formgrid">
                <div className="field col-10"></div>
                <div className="field col-2">
                    <Button label="Save" type="button"
                            onClick={(e) => updateTermsConditions()}
                            style={themeSettings.buttonColor ? {background: `#${themeSettings.buttonColor}`} : {background: '#f97316'}}
                    />
                </div>
            </div>
        </>
    )
}

// 169 H20

// 34.88