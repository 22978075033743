import axios from 'axios';
import configureStore from "../Store/store";
import qs from 'qs';
//import {NotificationManager} from "react-notifications";
import {CLEAR_TOPUP_STATE} from "./MopriPaymentActions";

export const UPDATE_COUNTRY_LOCALIZATION = 'UPDATE_COUNTRY_LOCALIZATION';
export const UPDATE_TERMS_CONDITIONS = 'UPDATE_TERMS_CONDITIONS';
export const UPDATE_GATEWAYS = 'UPDATE_GATEWAYS';
export const UPDATE_GATEWAY_SETTINGS = 'UPDATE_GATEWAY_SETTINGS';
export const UPDATE_INTEGRATION_SETTINGS = 'UPDATE_INTEGRATION_SETTINGS';
export const UPDATE_SITE_GROUPS = 'UPDATE_SITE_GROUPS';
export const UPDATE_PRINTER_INTEGRATION = 'UPDATE_PRINTER_INTEGRATION';
export const UPDATE_ALLOW_GUEST_REGISTRATION = 'UPDATE_ALLOW_GUEST_REGISTRATION';
export const UPDATE_API_URL = 'UPDATE_API_URL';



/* LEGACY BELOW*/
export const FETCH_MOPRI_GATEWAYS = 'FETCH_MOPRI_GATEWAYS';
export const FETCH_MOPRI_GATEWAY_DATA = 'FETCH_MOPRI_GATEWAY_DATA';
export const UPDATE_SNAPSCAN_SETTINGS = 'UPDATE_SNAPSCAN_SETTINGS';


export const FETCH_MOPRI_REGISTRATION_DATA = 'FETCH_MOPRI_REGISTRATION_DATA';
export const FETCH_MOPRI_INTEGRATION_DATA = 'FETCH_MOPRI_INTEGRATION_DATA';
export const FETCH_ACTIVE_DIRECTORY_USERS = 'FETCH_ACTIVE_DIRECTORY_USERS';
export const UPDATE_USER_ACCESS = 'UPDATE_USER_ACCESS';
export const UPDATE_FEES_SETTINGS = 'UPDATE_FEES_SETTINGS';
export const UPDATE_SITE_USERS = 'UPDATE_SITE_USERS';
export const UPDATE_SITE_SETTINGS = 'UPDATE_SITE_SETTINGS';
export const FETCH_COMPANY_SETTINGS = 'FETCH_COMPANY_SETTINGS';
export const UPDATE_USER_GROUP = 'UPDATE_USER_GROUP';
export const UPDATE_USER_GROUPS = 'UPDATE_USER_GROUPS';

export const ADD_OPAYO_MERCHANT_SESSION_KEYS = 'ADD_OPAYO_MERCHANT_SESSION_KEYS';
//export const UPDATE_GATEWAY_SETTINGS = 'UPDATE_GATEWAY_SETTINGS';


export const updateCountryLocalization = (values) =>
    ({ type: UPDATE_COUNTRY_LOCALIZATION, values: values })

export const updateTermsConditions = (values) =>
    ({ type: UPDATE_TERMS_CONDITIONS, values: values })

export const updateFeesSettings = (values) =>
    ({ type: UPDATE_FEES_SETTINGS, values: values })

export const updateMopriGatewaySettings = (values) =>
    ({ type: UPDATE_GATEWAYS, values: values })

export const updateGatewaySettings = (values) =>
    ({ type: UPDATE_GATEWAY_SETTINGS, values: values })

export const updateAllowGuestRegistration = (values) =>
    ({ type: UPDATE_ALLOW_GUEST_REGISTRATION, values: values })

export const updateMopriIntegrationSettings = (values) =>
    ({ type: UPDATE_INTEGRATION_SETTINGS, values: values })

export const updateMopriSiteGroups = (values) =>
    ({ type: UPDATE_SITE_GROUPS, values: values })

export const updatePrinterIntegrationSettings = (values) =>
    ({ type: UPDATE_PRINTER_INTEGRATION, values: values })

export const updateApiURL = (values) =>
    ({ type: UPDATE_API_URL, values: values })




export const updatePrintGroup = (values, dispatch) => {
    let mopriUID = configureStore.getState().userAuth.mopriUID;

    axios({
        method: 'post',
        url: '/api/settings/updatePrintGroup.asp',
        data: qs.stringify({
            groupID: values.groupID,
            groupDescription: values.groupDescription,
            groupActive: values.groupActive,
            mopriUID: mopriUID
        }),
        config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
    })
        .then((response) => {
            if (response.data.groupUpdated === false) {
              //  NotificationManager.error('The Print User Group Description already exists', 'Error');
            } else {
                dispatch(createAction(UPDATE_USER_GROUP, response.data));
             //   NotificationManager.success('The Print User Group was successfully updated.', 'Success');
            }
        })
        .catch((error) => {
            console.log(error);
          //  NotificationManager.error('There was an error while attempting to update the Print User Group.', 'Error');
        });
};



export const updateSnapScanSettings = (values, dispatch) => {
    axios({
        method: 'post',
        url: '/api/settings/saveSnapScanSettings.asp',
        data: qs.stringify({
            snapScanActive: values.snapScanActive,
            snapScanMerchID: values.snapScanMerchID,
            snapScanAPIKey: values.snapScanAPIKey
        }),
        config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
    })
        .then((response) => {
            dispatch(createAction(UPDATE_SNAPSCAN_SETTINGS, response.data));
           // NotificationManager.success('The SnapScan settings where successfully updated.', 'Success');
        })
        .catch((error) => {
            console.log(error);
          //  NotificationManager.error('There was an error while attempting to update the SnapScan settings.', 'Error');
        });

    return dispatch
};

export const updateOpayoSettings = (values, dispatch) => {
    axios({
        method: 'post',
        url: '/api/settings/saveOpayoSettings.asp',
        data: qs.stringify({
            opayoActive: values.opayoActive,
            opayoSandbox: values.opayoSandbox,
            opayoProductionVendorName: values.opayoProductionVendorName,
            opayoProductionIntegrationKey: values.opayoProductionIntegrationKey,
            opayoProductionIntegrationPassword: values.opayoProductionIntegrationPassword,
            opayoSandboxVendorName: values.opayoSandboxVendorName,
            opayoSandboxIntegrationKey: values.opayoSandboxIntegrationKey,
            opayoSandboxIntegrationPassword: values.opayoSandboxIntegrationPassword
        }),
        config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
    })
        .then((response) => {
           // dispatch(createAction(UPDATE_GATEWAY_SETTINGS, response.data.gatewaySettings));
          //  NotificationManager.success('The Opayo Payment Gateway settings where successfully updated.', 'Success');
        })
        .catch((error) => {
            console.log(error);
           // NotificationManager.error('There was an error updating the Opayo Payment Gateway settings', 'Error');
        });
}

export const updateElavonSettings = (values, dispatch) => {
    axios({
        method: 'post',
        url: '/api/settings/saveElavonSettings.asp',
        data: qs.stringify({
            elavonActive: values.elavonActive,
            elavonMerchId: values.elavonMerchId,
            merchantPin: values.merchantPin,
            convergeUserID: values.convergeUserID,
            elavonSandbox: values.elavonSandbox
        }),
        config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
    })
        .then((response) => {
          //  dispatch(createAction(UPDATE_GATEWAY_SETTINGS, response.data.gatewaySettings));
          //  NotificationManager.success('The Elavon Payment Gateway settings where successfully updated.', 'Success');
        })
        .catch((error) => {
            console.log(error);
          //  NotificationManager.error('There was an error updating the Elavon Payment Gateway settings', 'Error');
        });
}


export const updateSticittSettings = (values, dispatch) => {
    axios({
        method: 'post',
        url: '/api/settings/saveSticittSettings.asp',
        data: qs.stringify({
            sticittActive: values.sticittActive,
            sticittSandbox: values.sticittSandbox,
            sticittMerchantID: values.sticittMerchantID,
            sticittMerchantName: values.sticittMerchantName,
            sticittMerchantAccountRef: values.sticittMerchantAccountRef
        }),
        config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
    })
        .then((response) => {
          //  dispatch(createAction(UPDATE_GATEWAY_SETTINGS, response.data.gatewaySettings));
          //  NotificationManager.success('The Sticitt settings where successfully updated.', 'Success');
        })
        .catch((error) => {
            console.log(error);
          //  NotificationManager.error('There was an error updating the Sticitt settings', 'Error');
        });
    return dispatch;
};

export function getPaymentGatewayDetails(id) {

    /*return dispatch => {
        const url = `/api/settings/getPaymentGateways.asp`;
        axios.get(url)
            .then(function (response) {
                dispatch({
                    type:  FETCH_MOPRI_GATEWAYS,
                    payload: response
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }*/
}

export function fetchMopriGateways() {
    return dispatch => {
        const url = `/api/settings/getPaymentGateways.asp`;
        axios.get(url)
            .then(function (response) {
                dispatch({
                    type:  FETCH_MOPRI_GATEWAYS,
                    payload: response.data
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function clearTopupState() {
    return dispatch => {
        dispatch({
            type: CLEAR_TOPUP_STATE
        })
    }
}

export function fetchRegistrationDetails() {
    return dispatch => {
        const url = `/api/settings/getregistrationsettings.asp`;
        axios.get(url)
            .then(function (response) {
                dispatch({
                    type: FETCH_MOPRI_REGISTRATION_DATA,
                    payload: response
                })
            })
            .catch(function (error) {
                console.log(error);
            })
    }
}

export function fetchMopriIntegrationSettings() {
    return dispatch => {
        //let userName = configureStore.getState().userAuth.userName;
        const url = `/api/settings/getsysintegrationsettings.asp`;
        axios.get(url)
            .then(function (response) {
                dispatch({
                    type:  FETCH_MOPRI_INTEGRATION_DATA,
                    payload: response.data
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}


export function addUserGroups(values, dispatch) {
    let mopriUID = configureStore.getState().userAuth.mopriUID;
    //console.log(values);

    axios({
        method: 'post',
        url: '/api/settings/addusergroups.asp',
        data: qs.stringify({
            mopriUID: mopriUID,
            newUserGroup: values.newUserGroup
        }),
        config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
    })
        .then((response) => {
            if (response.data.groupAdded === false) {
              //  NotificationManager.error('The User Group already exists', 'Error');
            } else {
                dispatch(createAction(UPDATE_USER_GROUPS, response.data));
              //  NotificationManager.success('The new User Group was successfully saved.', 'Success');
            }
        })
        .catch((error) => {
            console.log(error);
          //  NotificationManager.error('There was an error while attempting to save the new User Group.', 'Error');
        });

    return dispatch;
}

export function doPrintDemo() {
   // return dispatch => {
    let userName = configureStore.getState().userAuth.userName;
        const url = `/api/settings/doprintdemo.asp?userName=${userName}`;
        axios.get(url)
            .then(() => {
              //  NotificationManager.success('Demo Print Submitted', 'Success');
            })
            .catch((error) => {
                console.log(error);
              //  NotificationManager.error('There was an error while submitting the Demo Print', 'Error');
            })
   // }
}

export function getCompanySettings() {
    return dispatch => {
        const url = `/api/settings/getcompanysettings.asp`;
        axios.get(url)
            .then(function (response) {
                dispatch({
                    type:  FETCH_COMPANY_SETTINGS,
                    payload: response.data
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export const updateLocalization = (values, dispatch) => {
    //console.log(values)
}

export const updateSiteSettings = (values, dispatch) => {
    let mopriUID = configureStore.getState().userAuth.mopriUID;
    //console.log(values);
    axios({
        method: 'post',
        url: '/api/settings/updatesitesettings.asp',
        data: qs.stringify({
            companyName: values.companyName,
            mopriUID: mopriUID,
        }),
        config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
    })
        .then(function (response) {
            dispatch(createAction(UPDATE_SITE_SETTINGS, response));
        })
        .catch(function (error) {
            console.log(error);
        });

    return dispatch
};


export const updateMopriUser = (values, dispatch) => {
    let mopriUID = configureStore.getState().userAuth.mopriUID;
    axios({
        method: 'post',
        url: '/api/settings/userUpdate.asp',
        data: qs.stringify({
            firstName: values.firstName,
            lastName: values.lastName,
            userActive: values.userActive,
            userID: values.userID,
            adminLevelID: values.adminLevelID,
            adminUpdated: mopriUID
        }),
        config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
    })
        .then((response) => {
            dispatch(createAction(UPDATE_SITE_USERS, response.data));
          //  NotificationManager.success('The users details have been updated', 'Success');
        })
        .catch((error) => {
            console.log(error);
         //   NotificationManager.error('There was an error while updating the users details', 'Error');
        })
};



//create_action
export const createAction = (type, values) => {
    return {
        type,
        values
    };
};
