import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {Toast} from "primereact/toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelopeCircleCheck} from "@fortawesome/free-solid-svg-icons/faEnvelopeCircleCheck";
import {Controller, useForm} from "react-hook-form";
import {InputText} from "primereact/inputtext";
import {Checkbox} from "primereact/checkbox";
import {Button} from "primereact/button";
import axios from "axios";
import qs from "qs";
import {Dialog} from "primereact/dialog";
import {isValidEmailAddress, nodeMailerErrorCodes} from "../../../../Utils/HelperFunctions";



export const SMTPSettings = (props) => {
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
    const [visible, setVisible] = useState(false);
    const [testEmail, setTestEmail] = useState('');
    const [isValidTestEmail, setIsValidTestEmail] = useState(false)
    const [showErrorResponse, setShowErrorResponse] = useState(false)
    const [errorResponse, setErrorResponse] = useState('')
    const myToast = useRef(null)
    const themeSettings = useSelector(state => state.theme);
    const apiURL = useSelector(state => state.mopriSettings.mopriAPIURL);

    const { control, handleSubmit, setValue, getValues, watch,
        formState: { errors } } = useForm({
        defaultValues: {
            smtpHost: props.smtpSettings.smtpHost,
            smtpPort: props.smtpSettings.smtpPort,
            sendFromEmail: props.smtpSettings.sendFromEmail,
            smtpCredentialsRequired: props.smtpSettings.smtpCredentialsRequired,
            smtpUsername: props.smtpSettings.smtpUsername,
            smtpPassword: props.smtpSettings.smtpPassword
        }
    })

    const smtpAuthRequired = watch("smtpCredentialsRequired")

    const onSubmit = (data) => {
        setSubmitButtonDisabled(true)
        // Prevent undefined values
        /*if (!data.smtpCredentialsRequired) {
            data.smtpUsername = "NULL"
            data.smtpPassword = "NULL"
        }*/

        axios({
            method: 'POST',
            url: `${apiURL}/api/addMopriSmtpSettings`,
            data: qs.stringify({ ...data }),
            config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
        })
            .then((response) => {
                console.log(response);
                if (response.error) {
                    myToast.current.show({severity: 'error', summary: 'ERROR', detail: response.errorMessage, life: 5000});
                } else {
                    myToast.current.show({severity: 'success', summary: 'SUCCESS', detail: `The SMTP settings have been successfully updated.`, life: 5000});
                }
                setSubmitButtonDisabled(true)
            })
            .catch((error) => {
                console.log(error);
                myToast.current.show({severity: 'error', summary: 'ERROR', detail: `An error took place while updating the SMTP details.`, life: 5000});
                setSubmitButtonDisabled(true)
            });
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const sendTestEmail = () => {
        setSubmitButtonDisabled(true)
        setShowErrorResponse(false);
        setErrorResponse('')

        axios({
            method: 'POST',
            url: `${apiURL}/api/sendTestEmail`,
            data: qs.stringify({
                testEmail: testEmail,
                smtpHost: getValues('smtpHost'),
                smtpPort: getValues('smtpPort'),
                sendFromEmail: getValues('sendFromEmail'),
                smtpCredentialsRequired: getValues('smtpCredentialsRequired'),
                smtpUsername: getValues('smtpUsername'),
                smtpPassword: getValues('smtpPassword'),
            }),
            config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
        })
            .then((response) => {
                console.log(response)
                if (response && response.status >= 500) {
                    myToast.current.show({severity: 'error', summary: 'ERROR', detail: 'The test email was unsuccessful.', life: 5000});
                } else {
                    myToast.current.show({severity:'success', summary: 'SUCCESS', detail: `A test email has been sent to ${testEmail}.`, life: 5000});
                }
                setSubmitButtonDisabled(false);
                setVisible(false);
            })
            .catch((e) => {
                if (e.response) {
                    console.log('Response Data:', e.response.data);
                    if (e.response.data.msg) {
                        setShowErrorResponse(true);
                        setErrorResponse(e.response.data.msg)
                    }
                }
                console.log(e)
                setSubmitButtonDisabled(false);
                setVisible(false);
                myToast.current.show({severity: 'error', summary: 'ERROR', detail: 'The test email was unsuccessful.', life: 5000});
            })
    }

    const updateTestEmailAddress = (email) => {
        setTestEmail(email)
        setIsValidTestEmail(isValidEmailAddress(email))
    }



    return (
        <>
            <Toast ref={myToast} />
            <div className="p-fluid grid formgrid">
                <div className="field col-12">
                    <h4><FontAwesomeIcon icon={faEnvelopeCircleCheck} style={{paddingRight: '10px'}}/> SMTP Settings</h4>
                </div>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="p-fluid grid formgrid">
                    <div className="field col-6" style={{paddingTop: '27px'}}>
                        <span className="p-float-label">
                            <Controller name="smtpHost" control={control} rules={{required: 'Required.'}}
                                        render={({field, fieldState}) => (
                                            <InputText id={field.name} {...field} />
                                        )}
                            />
                            <label htmlFor="smtpHost">Host</label>
                        </span>
                        {getFormErrorMessage('smtpHost')}
                    </div>
                    <div className="field col-6" style={{paddingTop: '27px'}}>
                        <span className="p-float-label">
                            <Controller name="smtpPort" control={control} rules={{required: 'Required.'}}
                                        render={({field, fieldState}) => (
                                            <InputText id={field.name} {...field} />
                                        )}
                            />
                            <label htmlFor="smtpPort">Port</label>
                        </span>
                        {getFormErrorMessage('smtpPort')}
                    </div>
                </div>

                <div className="p-fluid grid formgrid">
                    <div className="field col-6" style={{paddingTop: '27px'}}>
                        <span className="p-float-label">
                            <Controller name="sendFromEmail" control={control} rules={{required: 'Required.'}}
                                        render={({field, fieldState}) => (
                                            <InputText id={field.name} {...field} />
                                        )}
                            />
                            <label htmlFor="sendFromEmail">Send from email address.</label>
                        </span>
                        {getFormErrorMessage('sendFromEmail')}
                    </div>
                    <div className="field col-6" style={{paddingTop: '40px'}}>
                        <Controller
                            name="smtpCredentialsRequired"
                            control={control}
                           // rules={{}}
                            render={({field, fieldState}) => (
                                <>

                                    <Checkbox inputId={field.name} checked={field.value} inputRef={field.ref} onChange={(e) => field.onChange(e.checked)}/>
                                    <label htmlFor={field.name} style={{paddingLeft: '15px', marginBottom: '0px'}}>SMTP uses auth credentials?</label>
                                    {/*{getFormErrorMessage(field.name)}*/}
                                </>
                            )}
                        />
                    </div>
                </div>


                {smtpAuthRequired &&
                    <div className="p-fluid grid formgrid">
                        <div className="field col-6" style={{paddingTop: '27px'}}>
                            <span className="p-float-label">
                                <Controller name="smtpUsername" control={control} rules={{required: 'Required.'}}
                                            render={({field, fieldState}) => (
                                                <InputText id={field.name} {...field} />
                                            )}
                                />
                                <label htmlFor="smtpUsername">SMTP UserName</label>
                            </span>
                            {getFormErrorMessage('smtpUsername')}
                        </div>
                        <div className="field col-6" style={{paddingTop: '27px'}}>
                            <span className="p-float-label">
                                <Controller name="smtpPassword" control={control} rules={{required: 'Required.'}}
                                            render={({field, fieldState}) => (
                                                <InputText id={field.name} {...field} />
                                            )}
                                />
                                <label htmlFor="smtpPassword">SMTP Password</label>
                            </span>
                            {getFormErrorMessage('smtpPassword')}
                        </div>
                    </div>
                }


                <div className="col-12">
                    <div className="p-fluid grid">
                        <div className="field col-12 md:col-6"></div>
                        <div className="field col-12 md:col-3">
                            <Button label="Send Test Email" severity="info" type="button" disabled={submitButtonDisabled}
                                    onClick={() => setVisible(true)}
                            />
                        </div>
                        <div className="field col-12 md:col-3">
                            <Button label="Save" severity="warning"
                                    disabled={submitButtonDisabled}
                                    style={themeSettings.buttonColor ? {background: `#${themeSettings.buttonColor}`} : {background: '#f97316'}}
                            />
                        </div>
                    </div>
                </div>

            </form>

            {showErrorResponse &&
                <>
                    <h5 style={{marginBottom: '5px', marginTop: '0px'}}>SMTP Error Response:</h5>
                    <div className="p-fluid grid formgrid">
                        <div className="field col-12">
                            <pre style={{margin: '2px', padding: '5px', backgroundColor: "#f8d9d9"}}>
                                {JSON.stringify(errorResponse, null, 2)}
                            </pre>
                        </div>
                    </div>
                    <div className="p-fluid grid formgrid">
                        <div className="field col-3" style={{fontWeight: 'bold'}}>Error Code Detail ({errorResponse.code})</div>
                        <div className="field col-9">{nodeMailerErrorCodes(errorResponse.code)}</div>
                    </div>
                </>
            }

            <Dialog header="Send Test Email" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                <div className="p-fluid grid formgrid">
                    <div className="field col-12">
                        <InputText value={testEmail} placeholder="Enter Test Email Address"
                                   onChange={(e) => updateTestEmailAddress(e.target.value)} />
                    </div>
                    <div className="field col-12">
                        <div className="p-fluid grid">
                            <div className="field col-12 md:col-9"></div>
                            <div className="field col-12 md:col-3">
                                <Button label="Send" severity="info"
                                        disabled={!isValidTestEmail}
                                        onClick={() => sendTestEmail()}
                                        //style={themeSettings.buttonColor ? {background: `#${themeSettings.buttonColor}`} : {background: '#f97316'}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>

        </>
    )
}