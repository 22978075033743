import React, {useEffect, useRef, useState} from 'react';
import {Toast} from "primereact/toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Controller, useForm} from "react-hook-form";
import {Button} from "primereact/button";
import {InputNumber} from "primereact/inputnumber";
import axios from "axios";
import qs from "qs";
import {useDispatch, useSelector} from "react-redux";
import {updateFeesSettings} from "../../../../Actions/MopriSettingActions";
import {faWallet} from "@fortawesome/free-solid-svg-icons/faWallet";


export const GatewaySettings = (gatewaySettings) => {
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
    const [minTopupAmount, setMinTopupAmount] = useState(useSelector(state => state.mopriSettings.gatewaySettings.minTopupAmount));
    const currencyDesc = useSelector(state => state.mopriSettings.internationalSettings.localizationCurrency);
    const myToast = useRef(null)
    const dispatch = useDispatch();
    const apiURL = useSelector(state => state.mopriSettings.mopriAPIURL);
    const themeSettings = useSelector(state => state.theme);

    const { control, handleSubmit, setValue,
        formState: { errors } } = useForm({
        defaultValues: {
            minTopupAmount: minTopupAmount
        }
    })

    const onSubmit = (data) => {
        setSubmitButtonDisabled(true)

        axios({
            method: 'post',
            url: `${apiURL}/api/saveFeesSettings`,
            data: qs.stringify({
                minTopupAmount: data.minTopupAmount
            }),
            config: {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
        })
            .then((response) => {
                if (response.data && response.data.error) {
                    myToast.current.show({severity: 'error', summary: 'ERROR', detail: response.data.errorMessage, life: 5000});
                } else {
                    myToast.current.show({severity: 'success', summary: 'SUCCESS', detail: 'The payment settings where successfully updated.', life: 5000});
                    setMinTopupAmount(data.minTopupAmount);
                    dispatch(updateFeesSettings(data))
                }
                //dispatch(createAction(UPDATE_FEES_SETTINGS, response.data.gatewaySettings));
                setSubmitButtonDisabled(false)
            })
            .catch((error) => {
                console.log(error);
                setSubmitButtonDisabled(false)
                myToast.current.show({severity: 'error', summary: 'ERROR', detail: 'There was an error while attempting to update the payment settings.', life: 5000});
            });
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    }

    return (
        <>
            <Toast ref={myToast} />
            <div className="p-fluid grid formgrid">
                <div className="field col-12">
                    <h4><FontAwesomeIcon icon={faWallet} style={{paddingRight: '10px'}}/> Fees Settings</h4>
                </div>
            </div>

            <div className="p-fluid grid formgrid">
                <div className="field col-12">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="p-fluid grid formgrid" >
                            <div className="field col-6" style={{paddingTop: '27px'}}>
                                <span className="p-float-label">
                                    <Controller name="minTopupAmount" control={control}
                                                render={({ field, fieldState }) => (
                                                    <InputNumber id={field.name} inputRef={field.ref} value={field.value} onBlur={field.onBlur}
                                                                 onValueChange={(e) => field.onChange(e)}
                                                    />
                                                )}
                                    />
                                    <label htmlFor="minTopupAmount">Minimum Amount Payable by Client ({currencyDesc})</label>
                                </span>
                                {getFormErrorMessage('minTopupAmount')}
                            </div>
                            <div className="field col-6" style={{paddingTop: '27px'}}> </div>
                        </div>

                        <div className="col-12">
                            <div className="p-fluid grid">
                                <div className="field col-12 md:col-9"></div>
                                <div className="field col-12 md:col-3">
                                    <Button label="Save"
                                            severity="warning"
                                            disabled={submitButtonDisabled}
                                            style={themeSettings.buttonColor ? {background: `#${themeSettings.buttonColor}`} : {background: '#f97316'}}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
