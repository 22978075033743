import {
    UPDATE_LOGO_URL,
    LOGIN,
    LOGIN_ERROR,
    LOGOUT,
    START_REGISTER,
    REGISTER_CLIENT,
    CLIENT_REGISTERED,
    PASSWORD_RESET,
    PASSWORD_RESET_ERROR,
    CHANGE_PRINTER_PIN,
    CHANGE_PRINTER_PIN_ERROR,
    START_UPDATE_REALTIME_DATA,
    FETCH_REALTIME_DATA,
    PASSWORD_RESET_SUCCESS,
    FETCH_MOPRI_PIN_SETTINGS,
    UPDATE_COMPANY_LOGO_URL,
    UPDATE_USER_BALANCE
} from '../Actions/AuthActions';


const initialState = {
    balance: 0,
    isLoggedIn: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_LOGO_URL:
            return {
                ...state,
                logoURL: action.values.logoURL
            }

        case LOGIN:
            return {
                ...state,
                creditBalance: action.values.balance, //TODO
                isLoggedIn: true,
                userName: action.values.userName,
                adminLevel: action.values.adminLevel,
                //editSiteSettings: action.values.editSiteSettings,
                mopriUID: action.values.userID,
                firstName: action.values.firstName,
                lastName: action.values.lastName,
                email: action.values.email,
               /* printerIntegrationID: action.values.printerIntegrationID,
                printGraphHistory: action.values.printGraphHistory,
                totalPrinted: action.values.totalPrinted,
                totalSevenDayPrinted: action.values.totalSevenDayPrinted,
                totalYesterdayPrinted: action.values.totalYesterdayPrinted,
                totalTodayPrinted: action.values.totalTodayPrinted,
                totalPrintCost: action.values.totalPrintCost,
                totalSevenDayPrintedCost: action.values.totalSevenDayPrintedCost,
                totalYesterdayPrintedCost: action.values.totalYesterdayPrintedCost,
                totalTodayPrintedCost: action.values.totalTodayPrintedCost,
                newUserPIN: action.values.userPIN,
                showNewPin: action.values.showNewPin,
                localizationCountry: action.values.localizationCountry,
                localizationCurrency: action.values.localizationCurrency,
                logoURL: action.values.logoURL,*/
                address1: action.values.address1,
                address2: action.values.address2,
                address3: action.values.address3,
                city: action.values.city,
                postalCode: action.values.postalCode,
                country: action.values.country,
                state: action.values.state,
            };

        case LOGIN_ERROR:
            return {
                ...state,
                isLoggingIn: false,
                loginErrorMsg: action.values,
                loginError: true,
                showLoginErrorMsg: true
            };

        case START_UPDATE_REALTIME_DATA:
            return {
                ...state,
                isRefreshingBalance: true,
                isRefreshingDashboard: true,
                isRefreshingTopupHistory: true,
            };

        case FETCH_REALTIME_DATA:
            return {
                ...state,
                isRefreshingBalance: false,
                //isRefreshingDashboard: false,
                isRefreshingTopupHistory: false,
                creditBalance: action.values.data.balance,
                /*totalPrinted: action.values.data.totalPrinted,
                totalSevenDayPrinted: action.values.data.totalSevenDayPrinted,
                totalYesterdayPrinted: action.values.data.totalYesterdayPrinted,
                totalTodayPrinted: action.values.data.totalTodayPrinted,
                printGraphHistory: action.values.data.printGraphHistory,
                totalPrintCost: action.values.data.totalPrintCost,
                totalSevenDayPrintedCost: action.values.data.totalSevenDayPrintedCost,
                totalYesterdayPrintedCost: action.values.data.totalYesterdayPrintedCost,
                totalTodayPrintedCost: action.values.data.totalTodayPrintedCost*/
            };

        case FETCH_MOPRI_PIN_SETTINGS:
            return {
                ...state,
                allowPINChange: action.values.data.allowPINChange
            };

        case LOGOUT:
            return {
                ...initialState
            };

        case START_REGISTER:
            return {
                ...state,
                isRegistering: true,
                isLoggingIn: false,
                isLoggedIn: false,
                accountExists: null,
                accountExistsMsg: null,
                loginError: null,
                accCreated: null,
                accCreatedMsg: null,
                showLoginErrorMsg: null
            };

        case CLIENT_REGISTERED:
            return {
                ...state,
                isLoggingIn: false,
                loginError: null,
                isLoggedIn: false,
                resetPassword: false,
                userFirstName: null,
                userLastName: null,
                email: null,
                userPIN: null,
                isRegistering: false,
                accountExists: true,
                accountExistsMsg: action.values.data.error,
                accCreated: false,
                accCreatedMsg: null,
                showLoginErrorMsg: false
            };

        case REGISTER_CLIENT:
            return {
                ...state,
                isLoggingIn: false,
                loginError: null,
                isLoggedIn: false,
                resetPassword: false,
                userFirstName: null,
                userLastName: null,
                email: action.values.data.email,
                userPIN: null,
                isRegistering: false,
                accountExists: JSON.parse(action.values.data.accountExists),
                accountExistsMsg: null,
                accCreated: true,
                accCreatedMsg: 'Your account has been created, please login',
                showLoginErrorMsg: false
            };

        case PASSWORD_RESET:
            return {
                ...state,
                passwordUpdated: action.values.data.success,
                passwordUpdatedMsg: action.values.data.updateMsg
            };

        case PASSWORD_RESET_ERROR:
            return {
                ...state,
                passwordUpdatedError: action.values.data.passwordUpdated,
                passwordUpdatedErrorMsg: action.values.data.passwordUpdatedErrorMsg
            };

        case CHANGE_PRINTER_PIN:
            return {
                ...state,
                newPINError: false,
                newPIN: action.values.data.userPIN,
                newPINSuccess: true
            };

        case CHANGE_PRINTER_PIN_ERROR:
            return {
                ...state,
                newPINError: true,
                newPinErrorMsg: 'There was an error while resetting your new Printer PIN Code'
            };

        case PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                passwordUpdated: action.values.error,
                passwordUpdatedMsg: action.values.message
            };

        case UPDATE_COMPANY_LOGO_URL:
            return {
                ...state,
                logoURL: action.values.logoURL
            };

        case UPDATE_USER_BALANCE:
            return {
                ...state,
                balance: action.values.value
            };

        default:
            return state
    }
};
