

export const ErrorMessage = (props) => {

    return (
        <div className="p-message p-component p-message-error enter-done" data-pc-name="messages" data-pc-section="root">
            <div className="p-message-wrapper" data-pc-section="wrapper">
                <span className="p-message-summary" data-pc-section="summary">Error</span>
                <span className="p-message-detail" data-pc-section="detail">{props.message}</span>
            </div>
        </div>
    )
}