import {Menubar} from "primereact/menubar";
import {useNavigate} from "react-router-dom";
import {logout} from "../../../Actions/AuthActions";
import {useDispatch, useSelector} from "react-redux";


export const Navbar = () => {
    const userAuth = useSelector(state => state.userAuth);
    const themeSettings = useSelector(state => state.theme);
    const navigate = useNavigate();
    const dispatch = useDispatch();


    let items = [
        {label: 'Topup Account', icon: 'pi pi-fw pi-wallet', command: () => {navigate('/topup')} },
        {label: 'Account Settings', icon: 'pi pi-fw pi-cog', command: () => {navigate('/settings')} },
        {label: 'Mopri Settings', icon: 'pi pi-fw pi-pencil', items: [
                {label: 'Company Settings', icon: 'pi pi-fw pi-trash', command: () => {navigate('/company-settings')} },
                {label: 'Integration Settings', icon: 'pi pi-fw pi-sync', command: () => {navigate('/integration-settings')} },
                {label: 'Payment Settings', icon: 'pi pi-fw pi-shopping-cart', command: () => {navigate('/payment-settings')} },
                {label: 'User Management', icon: 'pi pi-fw pi-user-edit', command: () => {navigate('/user-management')} }
            ]
        },
        {label: 'Reports',
            icon: 'pi pi-fw pi-print', items: [
                {label: 'Login History', icon: 'pi pi-fw pi-lock-open', command: () => {navigate('/login-history')} }
            ]
        }
    ];

    if (userAuth.adminLevel !== 1) {
        items = items.filter(item => item.label !== 'Mopri Settings');
    }

    const start = <img alt="logo" src={`${window.location.origin}/img/mopri-logo-large.png`} height="40" className="mr-2" onClick={() => {navigate('/') }} style={{cursor: 'pointer', marginRight: '80px'}}></img>;
    const end = <span style={{minWidth: '135px'}}>
                                <i className="pi pi-power-off" style={{ fontSize: '24px', paddingRight: '10px', fontWeight: 'bolder', cursor: 'pointer', color: themeSettings.buttonColor ? `#${themeSettings.buttonColor}` : '#f97316' }}
                                        onClick={() => {
                                            dispatch(logout());
                                            navigate('/login');
                                        }}
                                ></i>
                            </span>

    return (
        <div className="card">
            <Menubar model={items} start={start} end={end} />
        </div>
    )
}