import React, {useEffect, useState} from 'react';
import {connect, useSelector} from 'react-redux'
import {Card} from "primereact/card";

export const OrderSummary = (props) => {
    const [currency, setCurrency] = useState('');
    const localizationCurrency = useSelector(state => state.mopriSettings.internationalSettings.localizationCurrency);


    useEffect(() => {
        switch (localizationCurrency) {
            case 'Euro':
                setCurrency('€');
                break;
            case 'Pound Sterling':
                setCurrency('€');
                break;
            case 'South African Rand':
                setCurrency('R');
                break;
            case 'Namibian Dollar':
                setCurrency('N$');
                break;
            default:
                break;
        }
    }, []);

        return (
            <Card title="Order Summary">
                <div className="grid">
                    <div className="col-6">Printing Topup</div>
                    <div className="col-6">{`${currency} ${props.value}`}</div>
                </div>
            </Card>
        )

}