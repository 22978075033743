import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import PasswordChecklist from "react-password-checklist"
import {useDispatch, useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {ErrorMessage} from "../../../Components/common/Messages/ErrorMessage";
import {Toast} from "primereact/toast";
import useWindowSize from "../../../Utils/Auth/UseWindowSize";
import {LogRegSlider} from "../Widgets/LogRegSlider";
import {updateApiURL} from "../../../Actions/MopriSettingActions";


export const ResetPassword = () => {
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)
    const [validatingToken, setValidatingToken] = useState(true)
    const [tokenValid, setTokenValid] = useState(false)
    const [userEmail, setUserEmail] = useState('')
    const [userID, setUserID] = useState('')

    const [errorMessage, setErrorMessage] = useState('')
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [logoURL, setLogoURL] = useState('img/mopri-logo-large.png')
    const [showLogo, setShowLogo] = useState(true)
    const [fixFormPosition, setFixFormPosition] = useState(false)
    //const dispatch = useDispatch();
    const myToast = useRef(null)
    const params = useParams();
    const uid = params.uid;
    const navigate = useNavigate();
    const size = useWindowSize();
    //const apiURL = useSelector(state => state.mopriSettings.mopriAPIURL);
    const themeSettings = useSelector(state => state.theme);
    const dispatch = useDispatch();
    const apiURL = window.apiURL;

    useEffect(() => {
        dispatch(updateApiURL(apiURL));
       // check if the Token is valid
        verifyToken()
        if (window.innerWidth >= 769 && window.innerWidth <=991) {
            setFixFormPosition(true)
        }
    }, []);

    const verifyToken = () => {
        setShowErrorMessage(false)
        setErrorMessage('')

        axios({
            method: 'post',
            url: `${apiURL}/api/verifyToken`,
            data: {
                uid: uid
            },
            config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
        })
            .then((response) => {
                if (response.data.error) {
                    setShowErrorMessage(true)
                    setErrorMessage(response.data.message)
                } else {
                    setTokenValid(true)
                    setUserEmail(response.data.email)
                    setUserID(response.data.userID)
                }
                setSubmitButtonDisabled(false)
                setValidatingToken(true)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const { control, handleSubmit, watch, formState: { errors } } = useForm({
        defaultValues: {
            newPassword: '',
            newPasswordAgain: ''
        }
    })

    const newPasswordWatch = watch("newPassword")
    const newPasswordAgainWatch = watch("newPasswordAgain")

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const onSubmit = (data) => {
        setSubmitButtonDisabled(true)
        setShowErrorMessage(false)
        setErrorMessage('')

        if (data.newPassword === data.newPasswordAgain) {
            const params = {
                uid: userID,
                email: userEmail,
                pw: data.newPassword,
                forgotPW: true
            }

            axios({
                method: 'post',
                url: `${apiURL}/api/changePassword`,
                data: {...params},
                config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
            })
                .then((response) => {
                    if (response.data.error) {
                        setShowErrorMessage(true);
                        setErrorMessage(response.data.message);
                        myToast.current.show({severity: 'error', summary: 'ERROR', detail: response.data.errorMessage, life: 5000});
                    } else {
                        myToast.current.show({severity: 'info', summary: 'INFO', detail: response.data.message, life: 5000});

                        setTimeout(() => {
                            navigate('/login')
                        }, 3000);
                    }
                })
                .catch((error) => {

                    console.log(error);
                    myToast.current.show({severity: 'info', summary: 'INFO', detail: error, life: 5000});
                })
            setSubmitButtonDisabled(false);
        } else {
            setShowErrorMessage(true)
            setSubmitButtonDisabled(false)
            setErrorMessage('The new passwords do not match.')
            myToast.current.show({severity: 'error', summary: 'ERROR', detail: 'The new passwords do not match.', life: 5000});
        }

    }

    return (
        <>
            <Toast ref={myToast} />
            <div className="layout-main">
                <div className="grid">
                    {(size.width >= 620) &&
                        <div className="col-fixed" style={{paddingRight: '200px', padding: '0px', width: '300px'}}>
                            <LogRegSlider />
                        </div>
                    }
                    <div className="col-6 md:col-12 lg:col-3" style={{paddingLeft: '40px'}}>
                        <div style={{position: 'absolute', top: '50%', transform: `${fixFormPosition ? 'translate(310px, -50%)' : 'translate(0px, -50%)'}`}}>
                            <div className="p-fluid grid formgrid" style={{paddingTop: '20px'}}>
                                <div className="field col-12" style={{width: '310px'}}>
                                    <div className="h5 modal-title text-center">
                                        {showLogo &&
                                            <>
                                                {logoURL ?
                                                    <img src={`${window.location.origin}/${logoURL}`} alt="Logo"/>
                                                    :
                                                    <img src={`${window.location.origin}/img/mopri-logo-large.png`} alt="Mopri"/>
                                                }
                                            </>
                                        }

                                        {tokenValid &&
                                            <h4 className="mt-2">
                                                <div>Reset your Password?</div>
                                                <span>Use the form below to reset your password.</span>
                                            </h4>
                                        }
                                    </div>

                                    {(!userID || !userEmail || !tokenValid) ?
                                        <ErrorMessage message={errorMessage}/>
                                    :
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="p-fluid grid formgrid" style={{paddingTop: '20px', width: '310px'}}>
                                                <div className="field col-12">
                                                    <span className="p-float-label">
                                                        <Controller name="newPassword" control={control} rules={{required: 'Required.'}}
                                                                    render={({field, fieldState}) => (
                                                                        <InputText id={field.name} {...field} type="password"
                                                                                   className={errors.newPassword && 'p-invalid block'}
                                                                        />
                                                                    )}
                                                        />
                                                        <label htmlFor="newPassword">Enter new password</label>
                                                    </span>
                                                    {getFormErrorMessage('newPassword')}
                                                </div>
                                            </div>
                                            <div className="p-fluid grid formgrid" style={{paddingTop: '20px'}}>
                                                <div className="field col-12">
                                                    <span className="p-float-label">
                                                        <Controller name="newPasswordAgain" control={control} rules={{required: 'Required.'}}
                                                                    render={({field, fieldState}) => (
                                                                        <InputText id={field.name} {...field} type="password"
                                                                                   className={errors.newPasswordAgain && 'p-invalid block'}
                                                                        />
                                                                    )}
                                                        />
                                                        <label htmlFor="newPasswordAgain">Enter new password again</label>
                                                    </span>
                                                    {getFormErrorMessage('newPasswordAgain')}
                                                </div>
                                            </div>

                                            <div className="p-fluid grid formgrid" style={{paddingTop: '20px'}}>
                                                <div className="field col-12">
                                                    <span className="p-float-label">
                                                        <PasswordChecklist
                                                            rules={["minLength", "specialChar", "number", "capital", "match"]}
                                                            minLength={6}
                                                            value={newPasswordWatch}
                                                            valueAgain={newPasswordAgainWatch}
                                                            onChange={(isValid) => {
                                                                isValid ? setSubmitButtonDisabled(false) : setSubmitButtonDisabled(true)
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="p-fluid grid" style={{paddingTop: '20px'}}>
                                                <div className="field col-12 md:col-12">
                                                    <Button label="UPDATE PASSWORD"
                                                            disabled={submitButtonDisabled}
                                                            style={themeSettings.buttonColor ? {background: `#${themeSettings.buttonColor}`} : {background: '#f97316'}}
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    }

                                    <div style={{paddingTop: '25px', overflow: "hidden"}}>
                                        <div style={{float: "left", cursor: 'pointer', color: themeSettings.buttonColor ? `#${themeSettings.buttonColor}` : '#f97316', fontWeight: 'bolder'}}
                                             onClick={() => navigate('/register')}
                                        >
                                            Register Account
                                        </div>
                                        <div style={{float: "right", marginLeft: 'auto', marginRight: 0, cursor: 'pointer', color: themeSettings.buttonColor ? `#${themeSettings.buttonColor}` : '#f97316', fontWeight: 'bolder'}}
                                             onClick={() => navigate('/login')}
                                        >
                                            Login
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="md:col-12 lg:col-3"></div>
                </div>
            </div>
        </>
    )
}