import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {Toast} from "primereact/toast";
import {ErrorMessage} from "../../../Components/common/Messages/ErrorMessage";
import {WarningMessage} from "../../../Components/common/Messages/WarningMessage";
import {InfoMessage} from "../../../Components/common/Messages/InfoMessage";
import {SuccessMessage} from "../../../Components/common/Messages/SuccessMessage";
import {useDispatch, useSelector} from "react-redux";
import {Card} from "primereact/card";
import {updateUserBalance} from "../../../Actions/AuthActions";

export const PayGateReturn = () => {
    const user = useSelector(state => state.userAuth);
    //const apiURL = useSelector(state => state.mopriSettings.mopriAPIURL);
    const params = useParams();
    const dispatch = useDispatch()
    const apiURL = window.apiURL;

    useEffect(() => {
        getUserBalance()
    }, []);

    const getUserBalance = () => {
        fetch(`${apiURL}/api/getUserBalance`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userName: user.userName
            })
        })
            .then(response => response.json())
            .then(data => {
                let balance = parseFloat(data.balance).toFixed(2)
                dispatch(updateUserBalance({value: balance}))
            })
            .catch(error => {
                // Handle any errors that occur during the fetch
                console.error(error);
            });
    }



    return (
        <>
            <div className="p-fluid grid formgrid">
                <div className="field col-12">
                    <Card title="PayGate Payment Status">
                        <>

                            {params.id === "0" &&
                                <ErrorMessage message="Payment was not done, please contact your admin if in error. Your credit balance has not been increased."/>
                            }
                            {params.id === "1" &&
                                <SuccessMessage message="Payment was successful, your credit balance will update shortly."/>
                            }
                            {params.id === "2" &&
                                <ErrorMessage message="Payment was declined. Your credit balance has not been increased."/>
                            }
                            {params.id === "3" &&
                                <WarningMessage message="Payment was cancelled. Your credit balance has not been increased."/>
                            }
                            {params.id === "4" &&
                                <InfoMessage message="You cancelled the payment. Your credit balance has not been increased."/>
                            }
                            {params.id === "5" &&
                                <InfoMessage message="The payment has been received by PayGate. Your credit balance has not been increased."/>
                            }
                            {params.id === "7" &&
                                <ErrorMessage message="Settlement voided. Your credit balance has not been increased."/>
                            }

                        </>
                    </Card>
                </div>
            </div>
        </>
    )
}