import React, {useEffect, useRef, useState} from 'react';
import {TopupValueForm} from "./Widgets/TopupValueForm";
import {useSelector} from "react-redux";
import {CheckoutForm} from './Widgets/CheckoutForm';
//import {fetchMopriGatewaySettings, fetchMopriGateways, clearTopupState} from "../../Actions/MopriSettingActions";
import {Card} from "primereact/card";
import {Toast} from "primereact/toast";


export const Topup = () => {
    const [showCheckout, setShowCheckout] = useState(false);
    const [topupValue, setTopupValue] = useState(0);
    const user = useSelector(state => state.userAuth);
    const myToast = useRef(null)

    useEffect(() => {
       // clearTopupState(); // Clear Payment Details Form to ensure there is no historical data.
        //fetchMopriGateways();
        //fetchMopriGatewaySettings();
    }, []);

    const updatePaymentValue = (value) => {
        setShowCheckout(true)
        setTopupValue(value)
    }

    const cancelTopup = () => {
        setTopupValue(null)
        setShowCheckout(false)
    }

    return(
        <>
            <Toast ref={myToast} />
            <Card title="Account Topup">
                <div className="grid">
                    <div className="col-12">
                        <div style={{marginTop: '10px'}}>
                            {showCheckout ?
                                <CheckoutForm
                                    address1={user.address1}
                                    address2={user.address2}
                                    city={user.city}
                                    postalCode={user.postalCode}
                                    country={user.country}
                                    value={topupValue}
                                    cancelTopup={cancelTopup}
                                />
                            :
                                <TopupValueForm
                                    updatePaymentValue={updatePaymentValue}
                                />
                            }
                        </div>
                    </div>
                </div>
            </Card>
        </>
    )
}
