import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from 'react-router-dom';
//import {login, clearLogin} from '../../../Actions/AuthActions';
import axios from "axios";
import {Controller, useForm} from "react-hook-form";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
//import {LogRegSlider} from "../Widgets/LogRegSlider";
import {ErrorMessage} from "../../../Components/common/Messages/ErrorMessage";
import {login} from "../../../Actions/AuthActions";
import {Toast} from "primereact/toast";
import {updateApiURL, updateMopriGatewaySettings} from "../../../Actions/MopriSettingActions";
import {LogRegSlider} from "../Widgets/LogRegSlider";
import useWindowSize from "../../../Utils/Auth/UseWindowSize";


export const Login = () => {
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [logoURL, setLogoURL] = useState('mopri-logo-large.png')
    const [showLogo, setShowLogo] = useState(true)
    const [fixFormPosition, setFixFormPosition] = useState(false)
    //const dispatch = useDispatch();
    const myToast = useRef(null)
    const userAuth = useSelector(state => state.userAuth);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const size = useWindowSize();
    //const apiURL = useSelector(state => state.mopriSettings.mopriAPIURL);
    const themeSettings = useSelector(state => state.theme);
    const apiURL = window.apiURL;
   // console.log('apiURL ' + apiURL)

    useEffect(() => {
        //getMopriLogoUrl()
        dispatch(updateApiURL(apiURL));

        if (window.innerWidth >= 769 && window.innerWidth <=991) {
            setFixFormPosition(true)
        }
    }, []);

    const getMopriLogoUrl = () => {
        /*axios.get(`/api/settings/getmopriLogoUrl.asp`)
            .then((response) => {
                if (!response.data.error) {
                    setLogoURL(response.data.logoURL)
                    setShowLogo(true)
                } else {
                    setShowLogo(true)
                }
            })
            .catch((e) => {
                console.log(e);
                setShowLogo(true)
            });*/
    }

    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            userName: '',
            password: ''
        }
    })

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const onSubmit = async (data) => {
        setSubmitButtonDisabled(true)
        setShowErrorMessage(false)
        setErrorMessage('')

        const res = await axios.get("https://api.ipify.org/?format=json");
        data.ipAddress = res.data.ip;

        fetch(`${apiURL}/api/login`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then((result) => {
                if (result.error) {
                    setShowErrorMessage(true)
                    setErrorMessage(result.errorMessage)
                } else {
                    dispatch(login(result))
                    getMopriGatewaySettings();
                    navigate('/')
                }
                setSubmitButtonDisabled(false)
            })
            .catch((e) => {
                setSubmitButtonDisabled(false)
                console.log(e)
            })
    }

    const getMopriGatewaySettings = () => {
        const url = `${apiURL}/api/getMopriGatewaySettings`;
        axios.get(url)
            .then((response) => {
                if (response && response.data.error) {
                    //myToast.current.show({severity: 'error', summary: 'ERROR', detail: response.data.errorMessage, life: 5000});
                } else {
                    dispatch(updateMopriGatewaySettings(response.data.gatewaySettings));
                }
            })
            .catch((error) => {
                console.log(error);
                //myToast.current.show({severity: 'error', summary: 'ERROR', detail: 'An error took place while retrieving the payment gateway data.', life: 5000});
            });
    }

    const convertToLowerCase = value => value.toLowerCase();

    return (
        <>
            <Toast ref={myToast} />
            <div className="layout-main">
                <div className="grid">
                    {(size.width >= 620) &&
                        <div className="col-fixed" style={{paddingRight: '200px', padding: '0px', width: '300px'}}>
                            <LogRegSlider />
                        </div>
                    }
                    <div className="col-6 md:col-12 lg:col-3" style={{paddingLeft: '40px'}}>
                        <div style={{position: 'absolute', top: '50%', transform: `${fixFormPosition ? 'translate(310px, -50%)' : 'translate(0px, -50%)'}`}}>
                            <div className="p-fluid grid formgrid" style={{paddingTop: '20px'}}>
                                <div className="field col-12">
                                    <div className="h5 modal-title text-center">
                                        {showLogo &&
                                            <>
                                                {logoURL ?
                                                    <img src={`img/${logoURL}`} alt="Logo"/>
                                                :
                                                    <img src="img/mopri-logo-large.png" alt="Mopri"/>
                                                }
                                            </>
                                        }
                                        <h4 className="mt-2">
                                            <div>Welcome back,</div>
                                            <span>Please sign in to your account below.</span>
                                        </h4>
                                    </div>

                                    {showErrorMessage &&
                                        <ErrorMessage message={errorMessage} />
                                    }

                                    {userAuth.accountExists &&
                                        <div className="alert alert-danger fade show" role="alert">
                                            {userAuth.accountExistsMsg}
                                        </div>
                                    }
                                    {userAuth.accCreated &&
                                        <div className="alert alert-success fade show" role="alert">
                                            {userAuth.accCreatedMsg}
                                        </div>
                                    }
                                    {userAuth.passwordUpdated &&
                                        <div className="alert alert-success fade show" role="alert">
                                            {userAuth.passwordUpdatedMsg}
                                        </div>
                                    }
                                </div>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="p-fluid grid formgrid" style={{paddingTop: '20px'}}>
                                    <div className="field col-12">
                                        <span className="p-float-label">
                                            <Controller name="userName" control={control}  rules={{ required: 'Required.' }}
                                                        render={({ field, fieldState }) => (
                                                            <InputText id={field.name} {...field}
                                                                       className={errors.userName && 'p-invalid block'}
                                                            />
                                                        )}
                                            />
                                            <label htmlFor="userName">Username</label>
                                         </span>
                                        {getFormErrorMessage('userName')}
                                    </div>
                                </div>
                                <div className="p-fluid grid formgrid" style={{paddingTop: '20px'}}>
                                    <div className="field col-12">
                                        <span className="p-float-label">
                                            <Controller name="password" control={control}  rules={{ required: 'Required.' }}
                                                        render={({ field, fieldState }) => (
                                                            <InputText id={field.name} {...field} type="password"
                                                                       className={errors.password && 'p-invalid block'}
                                                            />
                                                        )}
                                            />
                                            <label htmlFor="password">Password</label>
                                        </span>
                                        {getFormErrorMessage('password')}
                                    </div>
                                </div>

                                <div className="p-fluid grid" style={{paddingTop: '20px'}}>
                                    <div className="field col-12 md:col-12">
                                        <Button label="LOGIN"
                                                disabled={submitButtonDisabled}
                                                style={themeSettings.buttonColor ? {background: `#${themeSettings.buttonColor}`} : {background: '#f97316'}}
                                        />
                                    </div>
                                </div>
                            </form>

                            <div style={{paddingTop: '25px', overflow: "hidden"}}>
                                <div style={{float: "left", cursor: 'pointer', color: themeSettings.buttonColor ? `#${themeSettings.buttonColor}` : '#f97316', fontWeight: 'bolder'}}
                                     onClick={() => navigate('/register')}
                                >
                                    Register Account
                                </div>
                                <div style={{float: "right", marginLeft: 'auto', marginRight: 0, cursor: 'pointer', color: themeSettings.buttonColor ? `#${themeSettings.buttonColor}` : '#f97316', fontWeight: 'bolder'}}
                                     onClick={() => navigate('/forgot')}
                                >
                                    Forgot Password
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="md:col-12 lg:col-3"> </div>
                </div>
            </div>
        </>
    );
}
