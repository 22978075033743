import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import qs from "qs";
import {ProgressSpinner} from "primereact/progressspinner";
import {Card} from "primereact/card";
import {PrintSummary} from "./Widgets/PrintSummary";
import DailyPrintChart from "./Widgets/DailyPrintChart";
import {PaymentHistory} from "./Widgets/PaymentHistory";
import {updateUserBalance} from "../../Actions/AuthActions";


export const Dashboard = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [printStats, setPrintStats] = useState(0);
    const [graphHistory, setGraphHistory] = useState([]);
    const userAuth = useSelector(state => state.userAuth);
    const mopriSettings = useSelector(state => state.mopriSettings);
    const apiURL = useSelector(state => state.mopriSettings.mopriAPIURL);
    const dispatch = useDispatch()

    useEffect(() => {
        getUserBalance()
        getPrintStatistics()
        getPrintHistory()
        setIsLoading(false)
    }, [])

    const getUserBalance = () => {
        fetch(`${apiURL}/api/getUserBalance`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userName: userAuth.userName
            })
        })
            .then(response => response.json())
            .then(data => {
                let balance = parseFloat(data.balance).toFixed(2)
                dispatch(updateUserBalance({value: balance}))
            })
            .catch(error => {
                // Handle any errors that occur during the fetch
                console.error(error);
            });
    }

    const getPrintStatistics = () => {
        axios({
            method: 'post',
            url: `${apiURL}/api/getPrintStatistics`,
            data: qs.stringify({
                userName: userAuth.userName
            }),
            config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
        })
            .then((response) => {
                setPrintStats(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getPrintHistory = () => {
        axios({
            method: 'post',
            url: `${apiURL}/api/getDashboardChartData`,
            data: qs.stringify({
                userID: userAuth.mopriUID,
                userName: userAuth.userName
            }),
            config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
        })
            .then((response) => {
                setIsLoading(false)
                setGraphHistory(response.data)
            })
            .catch((error) => {
                setIsLoading(false)
                console.log(error)
            })
    }

    return (
        <>
            <Card title="Dashboard">

                {(userAuth.showNewPin && userAuth.newUserPIN) &&
                    <div className="alert alert-info fade show" role="info">
                        <h4>Your new printer PIN code is {userAuth.newUserPIN}</h4>
                    </div>
                }

                <PrintSummary
                    printStats={printStats}
                    localizationCurrency={mopriSettings?.internationalSettings?.localizationCurrency}
                />


                <div className="grid">
                    <div className="col-12">
                        <Card title="Last 12 Months Activity Chart">
                            {isLoading ?
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <ProgressSpinner style={{width: '80px', height: '80px'}}/>
                                </div>
                                :
                                <DailyPrintChart
                                    copyHistory={graphHistory?.copyHistory}
                                    printHistory={graphHistory?.printHistory}
                                />
                            }
                        </Card>
                    </div>
                </div>

                <div className="grid">
                    <div className="col-12">
                        <PaymentHistory
                            mopriUID={userAuth.mopriUID}
                            localizationCurrency={mopriSettings?.internationalSettings?.localizationCurrency}
                        />
                    </div>
                </div>

                {(mopriSettings.gatewaySettings.payGateActive &&( mopriSettings.internationalSettings.localizationCountry === 'ZAF' ||  mopriSettings.internationalSettings.localizationCountry === 'NAD')) &&
                    <div className="grid" style={{paddingTop: '30px'}}>
                        <div className="col-12">
                            <div className="card flex justify-content-center">
                                <img src="img/paygate_cards.png" alt="PayGate" width={'600px'} />
                            </div>
                        </div>
                    </div>
                }

            </Card>
        </>
    )
}


