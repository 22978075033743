import React from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom"

export const Breadcrumb = () => {
    const navigate = useNavigate();
    const location = useLocation();

    let breadCrumb
    switch (location.pathname) {
        case '/':
            breadCrumb = [{label: `Dashboard`}]
            break;
        case '/settings':
            breadCrumb = [{label: `Account Settings`}]
            break;

        case '/company-settings':
            breadCrumb = [{label: `Mopri Settings`}, {label: `Company Settings`}]
            break;
        case '/integration-settings':
            breadCrumb = [{label: `Mopri Settings`}, {label: `Integration Settings`}]
            break;
        case '/payment-settings':
            breadCrumb = [{label: `Mopri Settings`}, {label: `Payment Settings`}]
            break;
        case '/user-management':
            breadCrumb = [{label: `Mopri Settings`}, {label: `User Management`}]
            break;

        case '/login-history':
            breadCrumb = [{label: `Reports`}, {label: `Login History`}]
            break;

        case '/topup':
            breadCrumb = [{label: `Account Balance Topup`}]
            break;

        case (location.pathname.slice(0,8) === '/paygate'):
            breadCrumb = [{label: `PayGate Payment Status`}]
            break;

        default:
            if (location.pathname.slice(0,8) === '/paygate') {
                breadCrumb = [{label: `PayGate Payment Status`}]
            } else {
                breadCrumb = [{label: ''}]
            }
    }

    const home = { icon: 'pi pi-home', command: () => {navigate('/')} }

    return (
        <BreadCrumb
            model={breadCrumb}
            home={home}
        />
    )
}