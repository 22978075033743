import React, {useEffect} from 'react';
import {getCompanySettings} from "../../../Actions/MopriSettingActions";
import {useSelector} from "react-redux";
import {Card} from "primereact/card";
import {CompanyLogo} from "./Widgets/CompanyLogo";
import {Localization} from "./Widgets/Localization";
import {ThemeSettings} from "./Widgets/ThemeSettings";
import {TermsConditions} from "./Widgets/TermsConditions";

export const CompanySettings = () => {
    const mopriSettings = useSelector(state => state.mopriSettings);

    useEffect(() => {
        getCompanySettings();
    }, []);


    return (
        <Card title="Company Settings">
            <div className="grid">
                <div className="col-12">
                    <Localization />
                </div>
            </div>
            <hr />

            {/*<div className="grid">
                <div className="col-12">
                     <CompanyLogo siteSettings={mopriSettings.siteSettings}/>
                </div>
            </div>
            <hr/>*/}

            <div className="grid">
                <div className="col-12">
                    <ThemeSettings />
                </div>
            </div>
            <hr />

            <div className="grid">
                <div className="col-12">
                    <TermsConditions
                        termsConditions={mopriSettings.termsConditions ? mopriSettings.termsConditions : ''}
                    />
                </div>
            </div>
            <hr />

        </Card>
    )
}

