import React, {useEffect, useRef, useState} from 'react';
import {Card} from "primereact/card";
import {UserGroups} from "./Widgets/UserGroups";
import {PrinterIntegrationSettings} from "./Widgets/PrinterIntegrationSettings";
import {useDispatch, useSelector} from "react-redux";
import {FETCH_MOPRI_INTEGRATION_DATA, fetchMopriIntegrationSettings, updateMopriIntegrationSettings, updateMopriSiteGroups} from "../../../Actions/MopriSettingActions";
import axios from "axios";
import {ProgressSpinner} from "primereact/progressspinner";
import {Toast} from "primereact/toast";
import {SMTPSettings} from "./Widgets/SMTPSettings";

export const IntegrationSettings = () => {
    const [integrationSettings, setIntegrationSettings] = useState({});
    const [isLoadingIntegration, setIsLoadingIntegration] = useState(true);
    const [isLoadingGroups, setIsLoadingGroups] = useState(true);
    const [isLoadingSmtpSettings, setIsLoadingSmtpSettings] = useState(true);
    const [siteGroups, setSiteGroups] = useState([]);
    const [activeSiteGroups, setActiveSiteGroups] = useState([]);
    const [smtpSettings, setSmtpSettings] = useState({})
    const mopriSettings = useSelector(state => state.mopriSettings);
    const myToast = useRef(null);
    const dispatch = useDispatch();
    const apiURL = useSelector(state => state.mopriSettings.mopriAPIURL);

    useEffect(() => {
        getMopriIntegrationSettings();
        getMopriPrintUserGroup();
        getSMTPDetails();
    }, []);

    const getMopriIntegrationSettings = () => {
        const url = `${apiURL}/api/getMopriIntegrationSettings`;
        axios.get(url)
            .then((response) => {
                if (response && response.data.error) {
                    myToast.current.show({severity: 'error', summary: 'Error Message', detail: response.data.errorMessage, life: 5000});
                } else {
                    setIntegrationSettings(response.data.integrationSettings[0])
                    dispatch(updateMopriIntegrationSettings(response.data.integrationSettings[0]))
                }
                setIsLoadingIntegration(false)
            })
            .catch((error) => {
                setIsLoadingIntegration(false)
                console.log(error);
            });
    }

    const getMopriPrintUserGroup = () => {
        const url = `${apiURL}/api/getMopriPrintUserGroup`;
        axios.get(url)
            .then((response) => {
                if (response && response.data.error) {
                    myToast.current.show({severity: 'error', summary: 'Error Message', detail: response.data.errorMessage, life: 5000});
                } else {
                    setSiteGroups(response.data.siteGroups)

                    setActiveSiteGroups(response.data.siteGroups.filter((each) => {return each.siteGroupActive}))
                    dispatch(updateMopriSiteGroups(response.data.siteGroups))
                }
                setIsLoadingGroups(false)
            })
            .catch((error) => {
                setIsLoadingGroups(false)
                console.log(error);
            });
    }

    const getSMTPDetails = () => {
        // Get the details from the DB
        const url = `${apiURL}/api/getMopriSmtpSettings`;
        axios.get(url)
            .then((response) => {
                if (response && response.data.error) {
                    myToast.current.show({severity: 'error', summary: 'ERROR', detail: response.data.errorMessage, life: 5000});
                } else {
                    setSmtpSettings(response.data)
                }
                setIsLoadingSmtpSettings(false)
            })
            .catch((error) => {
                console.log(error);
                setIsLoadingSmtpSettings(false)
            });
    }

    return (
        <Card title="Integration Settings">
            <Toast ref={myToast} />
            <div className="grid">
                <div className="col-12">
                    {(isLoadingIntegration || isLoadingGroups) ?
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <ProgressSpinner style={{width: '40px', height: '40px'}}/>
                        </div>
                    :
                        <>
                            <PrinterIntegrationSettings
                                siteGroups={siteGroups}
                                integrationSettings={integrationSettings}
                                activeSiteGroups={activeSiteGroups}
                            />
                            <hr/>


                            {isLoadingSmtpSettings ?
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <ProgressSpinner style={{width: '40px', height: '40px'}}/>
                                </div>
                            :
                                <SMTPSettings smtpSettings={smtpSettings} />
                            }
                            <hr/>


                            {(integrationSettings?.printerIntegrationID && parseInt(integrationSettings.printerIntegrationID) === 1) &&
                                <>
                                    {/*<UserGroups />*/}
                                </>
                            }
                        </>
                    }
                </div>
            </div>
        </Card>
    )
}

