import React, {useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFingerprint} from "@fortawesome/free-solid-svg-icons/faFingerprint";
import {Controller, useForm} from "react-hook-form";
import {Button} from "primereact/button";
import {Checkbox} from "primereact/checkbox";
import {Toast} from "primereact/toast";
import axios from "axios";
import qs from "qs";
import {SuccessMessage} from "../../../Components/common/Messages/SuccessMessage";

export const GeneratePrinterPIN = () => {
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
    const [showNewPin, setShowNewPin] = useState(false)
    const [newPin, setNewPin] = useState('')
    const myToast = useRef(null)
    const dispatch = useDispatch();
    const userAuth = useSelector(state => state.userAuth);
    const apiURL = useSelector(state => state.mopriSettings.mopriAPIURL);
    const themeSettings = useSelector(state => state.theme);

    const { control, handleSubmit, watch,
        formState: { errors } } = useForm({
        defaultValues: {
            confirmPINChange: false
        }
    })

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const onSubmit = (data) => {
        if (data.confirmPINChange) {
            setShowNewPin(false)
            axios({
                method: 'post',
                url: `${apiURL}/api/changePrinterPIN`,
                data: qs.stringify({
                    userId: userAuth.mopriUID,
                    userName: 'Jason'//,userAuth.userName
                }),
                config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
            })
                .then((response) => {
                    if (response && response.data.error) {
                        myToast.current.show({severity: 'error', summary: 'ERROR', detail: response.data.errorMessage, life: 5000});
                    } else {
                        myToast.current.show({severity: 'success', summary: 'Success', detail: 'Printer pin changed successfully', life: 5000});
                        setNewPin(response.data.pin)
                        setShowNewPin(true)
                    }
                })
                .catch((e) => {
                    console.log(e);
                    myToast.current.show({severity: 'error', summary: 'ERROR', detail: 'An error took place while resetting the pin.', life: 5000});
                });

        } else {
            myToast.current.show({severity: 'error', summary: 'Error Message', detail: 'Please check the textbox to continue', life: 5000});
        }

        setSubmitButtonDisabled(false)
    }



    return (
        <>
            <Toast ref={myToast} />
            <div className="p-fluid grid formgrid">
                <div className="field col-12">
                    <h4><FontAwesomeIcon icon={faFingerprint} style={{paddingRight: '10px'}}/> Change Printer Pin Code</h4>
                </div>
            </div>

            {showNewPin &&
                <SuccessMessage message={`Your new printer PIN code is ${newPin}`} />
            }

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="p-fluid grid formgrid" style={{paddingTop: '20px'}}>
                    <div className="field col-12">
                    <span className="p-float-label">
                        <Controller name="confirmPINChange" control={control}  rules={{ required: 'Required.' }}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <Checkbox inputId={field.name} checked={field.value} inputRef={field.ref} onChange={(e) => field.onChange(e.checked)} />
                                            <label htmlFor={field.name} style={{paddingLeft: '25px'}} >Change my PIN code.</label>
                                        </>
                                    )}
                        />
                        <label htmlFor="confirmPINChange"></label>
                    </span>
                        {getFormErrorMessage('confirmPINChange')}
                    </div>
                </div>

                <div className="p-fluid grid">
                    <div className="field col-12 md:col-9"></div>
                    <div className="field col-12 md:col-3">
                        <Button label="SAVE"
                                disabled={submitButtonDisabled}
                                style={themeSettings.buttonColor ? {background: `#${themeSettings.buttonColor}`} : {background: '#f97316'}}
                        />
                    </div>
                </div>
            </form>
        </>
    )
}

