import React, {useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faKey} from "@fortawesome/free-solid-svg-icons";
import {Toast} from "primereact/toast";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import axios from "axios";
import qs from "qs";
import PasswordChecklist from "react-password-checklist";

export const ChangePassword = () => {
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
    const userAuth = useSelector(state => state.userAuth);
    const myToast = useRef(null)
    const dispatch = useDispatch();
    const apiURL = useSelector(state => state.mopriSettings.mopriAPIURL);
    const themeSettings = useSelector(state => state.theme);

    const { control, handleSubmit, watch,
        formState: { errors } } = useForm({
        defaultValues: {
            password: '',
            password2: ''
        }
    })

    const newPasswordWatch = watch("password")
    const newPasswordAgainWatch = watch("password2")

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const onSubmit = (data) => {
        setSubmitButtonDisabled(true)
        if (data.password === data.password2) {
            setSubmitButtonDisabled(false)

            axios({
                method: 'post',
                url: `${apiURL}/api/changePassword`,
                data: qs.stringify({
                    pw: data.password,
                    uid: userAuth.mopriUID,
                    email: userAuth.email
                }),
                config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
            })
                .then((response) => {
                    if (response && response.data.error) {
                        myToast.current.show({severity: 'error', summary: 'ERROR', detail: response.data.errorMessage, life: 5000});
                    } else {
                        myToast.current.show({severity: 'success', summary: 'Success', detail: 'Password changed successfully', life: 5000});
                    }
                })
                .catch((e) => {
                    console.log(e);
                });

        } else {
            myToast.current.show({severity: 'error', summary: 'Error Message', detail: 'Passwords do not match', life: 5000});
            setSubmitButtonDisabled(false)
        }
    }

    return (
        <>
            <Toast ref={myToast} />
            <div className="p-fluid grid formgrid">
                <div className="field col-12">
                    <h4><FontAwesomeIcon icon={faKey} style={{paddingRight: '10px'}}/> Change Password</h4>
                </div>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="p-fluid grid formgrid" style={{paddingTop: '20px'}}>
                    <div className="field col-6">
                        <span className="p-float-label">
                            <Controller name="password" control={control} rules={{required: 'Required.'}}
                                        render={({field, fieldState}) => (
                                            <InputText id={field.name} {...field} type="password"
                                                       className={errors.password && 'p-invalid block'}
                                            />
                                        )}
                            />
                            <label htmlFor="password">Password</label>
                        </span>
                        {getFormErrorMessage('password')}
                    </div>

                    <div className="field col-6">
                        <span className="p-float-label">
                            <Controller name="password2" control={control} rules={{required: 'Required.'}}
                                        render={({field, fieldState}) => (
                                            <InputText id={field.name} {...field} type="password"
                                                       className={errors.password2 && 'p-invalid block'}
                                            />
                                        )}
                            />
                            <label htmlFor="password2">Confirm Password</label>
                        </span>
                        {getFormErrorMessage('password2')}
                    </div>
                </div>

                <div className="p-fluid grid formgrid" style={{paddingTop: '20px'}}>
                    <div className="field col-12">
                        <span className="p-float-label">
                            <PasswordChecklist
                                rules={["minLength", "specialChar", "number", "capital", "match"]}
                                minLength={6}
                                value={newPasswordWatch}
                                valueAgain={newPasswordAgainWatch}
                                onChange={(isValid) => {
                                    isValid ? setSubmitButtonDisabled(false) : setSubmitButtonDisabled(true)
                                }}
                            />
                        </span>
                    </div>
                </div>

                <div className="p-fluid grid">
                    <div className="field col-12 md:col-9"></div>
                    <div className="field col-12 md:col-3">
                        <Button label="SAVE"
                                disabled={submitButtonDisabled}
                                style={themeSettings.buttonColor ? {background: `#${themeSettings.buttonColor}`} : {background: '#f97316'}}
                        />
                    </div>
                </div>
            </form>


        </>
    )
}

