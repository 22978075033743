import {
    UPDATE_THEME_SETTINGS
} from '../Actions/ThemeActions';


const initialState = {
    buttonColor: 'f97316'
};


export default (state = initialState, action) => {
    switch (action.type) {

        case UPDATE_THEME_SETTINGS:
            return {
                ...state,
                buttonColor: action.values
            }


        default:
            return state
    }
};
