import {Toast} from "primereact/toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCreditCard} from "@fortawesome/free-solid-svg-icons/faCreditCard";
import {Controller, useForm} from "react-hook-form";
import {Dropdown} from "primereact/dropdown";
import React from "react";
import {Button} from "primereact/button";
import axios from "axios";
import qs from "qs";
import {useDispatch, useSelector} from "react-redux";
import {InputText} from "primereact/inputtext";
import {updateGatewaySettings} from "../../../../Actions/MopriSettingActions";


const payGateStatuses = [{value: true, description: 'Active'}, {value: false, description: 'Inactive'}];
const payGateEnvironments = [{value: true, description: 'Sandbox Environment'}, {value: false, description: 'Live Environment'}];
const boolSelect = [{value: true, description: 'Yes'}, {value: false, description: 'No'}];

export const PayGateSettings = (props) => {
    const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState(false)
    const myToast = React.useRef(null)
    const gatewaySettings = useSelector(state => state.mopriSettings.gatewaySettings);
    const apiURL = useSelector(state => state.mopriSettings.mopriAPIURL);
    const themeSettings = useSelector(state => state.theme);
    const dispatch = useDispatch();


    const { control, handleSubmit, watch,
        formState: { errors } } = useForm({
        defaultValues: {
            payGateActive: gatewaySettings.payGateActive,
            payGateSandbox: gatewaySettings.payGateSandbox,
            payGateId: gatewaySettings.payGateId,
            payGateEncryptionKey: gatewaySettings.payGateEncryptionKey,
        }
    })

    const payGateServiceActive = watch("payGateActive")

    const onSubmit = (data) => {
        setSubmitButtonDisabled(true)

        axios({
            method: 'post',
            url: `${apiURL}/api/gateway/updatePayGate`,
            data: qs.stringify({
                ...data
            }),
            config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
        })
            .then((response) => {
                if (response && response.data.error) {
                    myToast.current.show({severity: 'error', summary: 'ERROR', detail: response.data.errorMessage, life: 5000});
                } else {
                    myToast.current.show({severity: 'success', summary: 'Success', detail: 'PayGate settings updated', life: 5000});
                    dispatch(updateGatewaySettings(data))
                }
                setSubmitButtonDisabled(false)
            })
            .catch((e) => {
                setSubmitButtonDisabled(false)
                myToast.current.show({severity: 'error', summary: 'ERROR', detail: `An unexpected error took place`, life: 5000});
                console.log(e);
            });
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    return (
        <>
            <Toast ref={myToast}/>
            <div className="p-fluid grid formgrid">
                <div className="field col-12">
                    <h4><FontAwesomeIcon icon={faCreditCard} style={{paddingRight: '10px'}}/> PayGate Settings</h4>
                </div>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="p-fluid grid formgrid">
                    <div className="field col-12">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="p-fluid grid formgrid">
                                <div className="field col-6">
                                    <span className="p-float-label" style={{fontSize: '12px', color: "#6c757d", paddingBottom: '5px'}}>
                                         PayGate Status
                                    </span>
                                    <Controller name="payGateActive" control={control} //rules={{required: 'Required.'}}
                                                render={({field, fieldState}) => (
                                                    <Dropdown value={field.value} id={field.name}
                                                              options={payGateStatuses} optionLabel="description"
                                                              optionValue="value"
                                                              onChange={(e) => {
                                                                  field.onChange(e.value)
                                                              }}
                                                    />
                                                )}
                                    />
                                    {getFormErrorMessage('payGateActive')}
                                </div>
                                <div className="field col-6">
                                    {payGateServiceActive &&
                                        <>
                                            <span className="p-float-label" style={{fontSize: '12px', color: "#6c757d", paddingBottom: '5px'}}>
                                                 PayGate Sandbox Test Environment
                                            </span>
                                            <Controller name="payGateSandbox" control={control} //rules={{required: 'Required.'}}
                                                        render={({field, fieldState}) => (
                                                            <Dropdown value={field.value} id={field.name}
                                                                      options={payGateEnvironments} optionLabel="description"
                                                                      optionValue="value"
                                                                      onChange={(e) => {
                                                                          field.onChange(e.value)
                                                                      }}
                                                            />
                                                        )}
                                            />
                                            {getFormErrorMessage('payGateSandbox')}
                                        </>
                                    }
                                </div>
                            </div>

                            {payGateServiceActive &&
                                <>
                                    <div className="p-fluid grid formgrid">
                                        <div className="field col-6" style={{paddingTop: '27px'}}>
                                            <span className="p-float-label">
                                                <Controller name="payGateEncryptionKey" control={control}
                                                            render={({field, fieldState}) => (
                                                                <InputText id={field.name} {...field}
                                                                />
                                                            )}
                                                />
                                                <label htmlFor="payGateEncryptionKey">PayGate Encryption Key</label>
                                            </span>
                                            {getFormErrorMessage('payGateEncryptionKey')}
                                        </div>
                                        <div className="field col-6" style={{paddingTop: '27px'}}>
                                            <span className="p-float-label">
                                                <Controller name="payGateId" control={control}
                                                            render={({field, fieldState}) => (
                                                                <InputText id={field.name} {...field}
                                                                />
                                                            )}
                                                />
                                                <label htmlFor="payGateId">PayGate ID</label>
                                            </span>
                                            {getFormErrorMessage('payGateId')}
                                        </div>
                                    </div>
                                </>
                            }
                        </form>
                    </div>
                </div>

                <div className="col-12">
                    <div className="p-fluid grid">
                        <div className="field col-12 md:col-9"></div>
                        <div className="field col-12 md:col-3">
                            <Button label="Update PayGate Settings"
                                    severity="warning"
                                    disabled={submitButtonDisabled}
                                    style={themeSettings.buttonColor ? {background: `#${themeSettings.buttonColor}`} : {background: '#f97316'}}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}