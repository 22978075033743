import React, {useRef, useState} from 'react';
import {Toast} from "primereact/toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Controller, useForm} from "react-hook-form";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {faCreditCard} from "@fortawesome/free-solid-svg-icons/faCreditCard";
import {useSelector} from "react-redux";

const snapScanStatuses = [{value: true, description: 'Active'}, {value: false, description: 'Inactive'}];

export const SnapScanSettings = (props) => {
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
    const themeSettings = useSelector(state => state.theme);
    const myToast = useRef(null)

    const { control, handleSubmit, watch, getValues,
        formState: { errors } } = useForm({
        defaultValues: {
            snapScanActive: true,
            snapScanMerchID: '',
            snapScanAPIKey: '',
        }
    })

    const onSubmit = (data) => {
        setSubmitButtonDisabled(true)

    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const snapScanServiceActive = watch("snapScanActive")

    return (
        <>
            <Toast ref={myToast} />
            <div className="p-fluid grid formgrid">
                <div className="field col-12">
                    <h4><FontAwesomeIcon icon={faCreditCard} style={{paddingRight: '10px'}}/> SnapScan Settings</h4>
                </div>
            </div>

            <div className="p-fluid grid formgrid">
                <div className="field col-12">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="p-fluid grid formgrid" >
                            <div className="field col-6">
                                <span className="p-float-label" style={{fontSize: '12px', color: "#6c757d", paddingBottom: '5px'}}>
                                     SnapScan Status
                                </span>
                                <Controller name="snapScanActive" control={control} rules={{ required: 'Required.' }}
                                            render={({ field, fieldState }) => (
                                                <Dropdown value={field.value} id={field.name}
                                                          options={snapScanStatuses} optionLabel="description"
                                                          optionValue="value"
                                                          onChange={(e) => {
                                                              field.onChange(e.value)
                                                          }}
                                                />
                                            )}
                                />
                                {getFormErrorMessage('snapScanActive')}
                            </div>
                            <div className="field col-6"> </div>
                        </div>

                        {snapScanServiceActive &&
                            <>
                                <div className="p-fluid grid formgrid" >
                                    <div className="field col-6" style={{paddingTop: '27px'}}>
                                        <span className="p-float-label">
                                            <Controller name="snapScanMerchID" control={control}
                                                        render={({ field, fieldState }) => (
                                                            <InputText id={field.name} {...field}
                                                            />
                                                        )}
                                            />
                                            <label htmlFor="snapScanMerchID">SnapScan Merchant ID</label>
                                        </span>
                                        {getFormErrorMessage('snapScanMerchID')}
                                    </div>
                                    <div className="field col-6" style={{paddingTop: '27px'}}>
                                        <span className="p-float-label">
                                            <Controller name="snapScanAPIKey" control={control}
                                                        render={({ field, fieldState }) => (
                                                            <InputText id={field.name} {...field}
                                                            />
                                                        )}
                                            />
                                            <label htmlFor="snapScanAPIKey">SnapScan API Key</label>
                                        </span>
                                        {getFormErrorMessage('snapScanAPIKey')}
                                    </div>
                                </div>

                                <div className="p-fluid grid formgrid" >
                                    <div className="field col-12" style={{paddingTop: '27px'}}>
                                        <span className="p-float-label">
                                            <Controller name="snapScanMerchID" control={control}
                                                        render={({ field, fieldState }) => (
                                                            <InputText id={field.name} value={`http://demo.mopri.net/api/snapscan.asp`} {...field}
                                                                       disabled={true}
                                                            />
                                                        )}
                                            />
                                            <label htmlFor="snapScanMerchID">Snapscan Endpoint</label>
                                        </span>
                                    </div>
                                </div>
                            </>
                        }

                        <div className="col-12">
                            <div className="p-fluid grid">
                                <div className="field col-12 md:col-9"></div>
                                <div className="field col-12 md:col-3">
                                    <Button label="Update SnapScan Settings"
                                            severity="warning"
                                            disabled={submitButtonDisabled}
                                            style={themeSettings.buttonColor ? {background: `#${themeSettings.buttonColor}`} : {background: '#f97316'}}
                                    />
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </>
    )
}