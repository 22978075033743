import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from "react-redux";
import {topup, resetTopupAmount, verifySticittPayment} from "../../../Actions/MopriPaymentActions";
//import OrderSummary from "./OrderSummary";
import {OpayoGatewayService} from "../../../Services/Gateways/OpayoGatewayService";
import axios from "axios";
import qs from "qs";
import {Toast} from "primereact/toast";
import {useNavigate} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import {RadioButton} from "primereact/radiobutton";
import {Button} from "primereact/button";
import {OrderSummary} from "./OrderSummary";
import {ErrorMessage} from "../../../Components/common/Messages/ErrorMessage";
import {Dialog} from "primereact/dialog";
import {isBoolean} from "../../../Utils/HelperFunctions";

export const CheckoutForm = (props) => {
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
    const [pmtVendor, setPmtVendor] = useState(null);
    const [showElavonIframe, setShowElavonIframe] = useState(false);
    const [showOpayoIframe, setShowOpayoIframe] = useState(false);
    const [disableOpayoForm, setDisableOpayoForm] = useState(false);
    const [threeDAttempts, setThreeDAttempts] = useState(0);
    //const [countryList, setCountryList] = useState([]);
    const [address1, setAddress1] = useState(props.address1);
    const [address2, setAddress2] = useState(props.address2);
    const [city, setCity] = useState(props.city);
    const [postalCode, setPostalCode] = useState(props.postalCode);
    const [country, setCountry] = useState(props.country);
    const [visible, setVisible] = useState(false);
    const topup = useSelector(state => state.topup);
    const userAuth = useSelector(state => state.userAuth);
    const mopriSettings = useSelector(state => state.mopriSettings);
    const myToast = useRef(null)
    const navigate = useNavigate();
    const apiURL = useSelector(state => state.mopriSettings.mopriAPIURL);


    useEffect(() => {
        autoSelectGateway();

        /*axios({
            method: 'post',
            url: `${apiURL}/api/getCountries`,
            data: qs.stringify({}),
            config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
        })
            .then((response) => {
                setCountryList(response.data)
            })
            .catch((error) => {console.log(error) })*/

        let isScriptLoded = isScriptLoaded("https://sdk-test.sticitt.co.za/js/lib/sdk.min.js");

        if (isScriptLoded === false) {
            const script = document.createElement("script");
            script.id = 'sticitt-pay-sdk';
            script.src = "https://sdk-test.sticitt.co.za/js/lib/sdk.min.js";
            script.setAttribute('data-client-id', 'sample-app');
            script.setAttribute('data-client-secret', 'I3zAapIw3IRyVPTZvJb2o48TzEaKEMAUkJrGDy3zO5F4eD3xBuj0rhTuhuXWj6si');
            script.async = true;
            //script.onload = () => this.scriptLoaded();
            document.body.appendChild(script);
        }
    }, []);

    const autoSelectGateway = () => {
        console.log('mopriSettings.gatewaySettings', mopriSettings.gatewaySettings)
        if (!isBoolean(mopriSettings.gatewaySettings.payGateActive && mopriSettings.gatewaySettings.payFastActive)) {
            setValue('value', 'payFast');
            setPmtVendor('PayFast')
        }

        if (!isBoolean(mopriSettings.gatewaySettings.payFastActive && mopriSettings.gatewaySettings.payGateActive)) {
            setValue('values', 'payGate');
            setPmtVendor('PayFast')
        }

    }

    const showTermsConditions = () => {
        setVisible(true)
    }


    const isScriptLoaded = (src) => {
        //return document.querySelector('script[src="' + src + '"]') ? true : false;
    };

    const scriptLoaded = () => {
        window.A.sort();
    }

    const updatePmtVendor = (vendor) => {

        // IF Sticitt then get a paymentID if we don't have one.
        setPmtVendor(vendor)

        if (vendor === 'opayo') {
            setShowOpayoIframe(true)
            const opayoService = new OpayoGatewayService();

            opayoService.getMerchantSessionKey()
                .then((result) => {})
                .catch((error) => console.log(error))
        }

        if (vendor === 'elavon') {
            setShowOpayoIframe(false)
        }

        // If we have a previously rendered a sticitt button then register one now.
        if (document.getElementById('sticittPay')) {
            document.getElementById('sticittPay').style.visibility = 'visible';
            document.getElementById('sticittWallet').style.visibility = 'visible';
        }


        if (vendor === 'sticitt') {
            if (topup.sticittPaymentID) {
                if (!document.getElementById('sticittPay')) {
                    // Add the wallet button
                    /*let walletBtn = document.createElement("button");
                    walletBtn.innerText = "View Wallet";
                    walletBtn.id = "sticittWallet";
                    document.getElementById("sticitt").appendChild(walletBtn);*/
                    // Add the Pay with Sticitt Button
                    let sticittBtn = document.createElement("button");
                    sticittBtn.innerText = "Pay With Sticitt";
                    sticittBtn.id = "sticittPay";
                    sticittBtn.className = "sticitt-pay-button btn-shadow btn-wide float-right btn-pill btn-hover-shine btn btn-primary";
                    sticittBtn.setAttribute('data-payment-id', topup.sticittPaymentID);
                    sticittBtn.setAttribute('data-credit-card-enabled', 'true');
                    document.getElementById("sticitt").appendChild(sticittBtn);
                    window.SticittPaySDK.AddButton(new window.SticittPaySDK.PayButton(sticittBtn,testPaid,testClosed));
                } else {
                    // Show the existing sticitt button
                    document.getElementById('sticittPay').style.visibility = 'visible';
                    document.getElementById('sticittWallet').style.visibility = 'visible';
                }
            }

            function testClosed(btn, payment_id) {}

            function testPaid(btn, payment_id) {
                verifySticittPayment();
            }
        } else {
            if (document.getElementById('sticittPay')) {
                document.getElementById('sticittPay').style.visibility = 'hidden';
                document.getElementById('sticittWallet').style.visibility = 'hidden';
            }

            setPmtVendor(vendor)
        }
    };

    const doOpayoPayment = () => {
        let cardError = false;
        let addressError = false;
        setDisableOpayoForm(true)
        const customPmtValue = topup.customPmtValue;
        // Card Details
        let cardholderName = document.querySelector('[data-card-details="cardholder-name"]').value;
        let cardNumber = document.querySelector('[data-card-details="card-number"]').value;
        let expiryDate = document.querySelector('[data-card-details="expiry-date"]').value;
        let securityCode = document.querySelector('[data-card-details="security-code"]').value;
        // Address Details
        let line1 = document.querySelector('[data-address-details="address1"]').value;
        let line2 = document.querySelector('[data-address-details="address2"]').value;
        let city = document.querySelector('[data-address-details="city"]').value;
        let postCode = document.querySelector('[data-address-details="postalCode"]').value;
        let country = document.querySelector('[data-address-details="country"]').value;
        let saveAddress = document.querySelector('[data-address-details="saveAddress"]').value;

        if (cardholderName.length < 3 || cardNumber.length < 10 || expiryDate.length !== 4 || securityCode.length >= 5) {
            cardError = true
            myToast.current.show({severity: 'error', summary: 'Error', detail: 'Please ensure all fields are completed correctly to continue.', life: 5000});
        }

        if (line1 === '' || city === '' || postCode === '' || country === '') {
            addressError = true;
            myToast.current.show({severity: 'error', summary: 'Error', detail: 'There where errors with your address details. Please complete your address details before continuing.', life: 5000});
        }

        if (cardError === false && addressError === false) {
            const params = {
                cardholderName: cardholderName,
                cardNumber: cardNumber,
                expiryDate: expiryDate,
                securityCode: securityCode,
                pmtValue: customPmtValue,
                line1: address1,
                line2: address2,
                postCode: postalCode,
                country: country
            }
            const opayoService = new OpayoGatewayService();
            opayoService.cardIdentifiers(params)
                .then((response) => {
                    // Check to see if the transaction is 3D Verified
                    let interval = setInterval(verify3DSecure, 5000)
                    // Save the address if required.
                    if (saveAddress === 'true') {
                        const mopriUID = userAuth.mopriUID;
                        axios({
                            method: 'post',
                            url: '/api/printdirector/saveUserAddress.asp',
                            data: qs.stringify({
                                address1: address1,
                                address2: address2,
                                postalCode: postalCode,
                                city: city,
                                country: country,
                                userID: mopriUID
                            }),
                            config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
                        })
                            .then((response) => {
                                if (response.data.success === true) {
                                    myToast.current.show({severity: 'success', summary: 'success', detail: 'Your address has been successfully saved.', life: 5000});
                                }
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                    }
                })
                .catch((error) => {
                    console.log(error)
                    clearInterval(this.interval);
                })
        } else {
            setDisableOpayoForm(false)
        }
    }

    const verify3DSecure = () => {
        const tid = topup.topupGUID;

        if (threeDAttempts < 100) {

            axios.get(`/api/pmt/Opayo/get3DSecureResponse.asp?tid=${tid}`)
                .then((response) => {
                    if (response.data.found === true && response.data.opayoResponse.length > 1) {
                        // Unsubscribe from the verify3DSecure search
                        clearInterval(this.interval);

                        // Check with Opayo if successful
                        const opayoService = new OpayoGatewayService();
                        opayoService.threeDSecureVerify(response.data.opayoResponse)
                            .then((response) => {
                                if (response.statusCode === "0000" && response.status === 'Ok') {
                                    const params = {
                                        success: true,
                                        opayoResponse: response
                                    }
                                    const opayoService = new OpayoGatewayService();
                                    opayoService.updatePaymentDetails(params)
                                    .then(() => {
                                        setShowOpayoIframe(false)
                                    })
                                    myToast.current.show({severity: 'success', summary: 'success', detail: 'Your payment was successful.', life: 5000});
                                    // Redirect to the success Page
                                    navigate('/opayo-success');
                                } else {
                                    myToast.current.show({severity: 'success', summary: 'success', detail: 'There was an error processing your payment.', life: 5000});
                                    setShowOpayoIframe(false)
                                    // Redirect to the unsuccessful page
                                    navigate('/opayo-decline')
                                }
                            })

                    }
                    setThreeDAttempts(threeDAttempts + 1)
                })
                .catch((error) => {
                    console.log(error)
                    clearInterval(this.interval);
                })
        } else {
            myToast.current.show({severity: 'success', summary: 'success', detail: 'Your address has been successfully saved.', life: 5000});
            clearInterval(this.interval);
        }

    }

    const handleAddress1Change = (value) => {
        setAddress1(value)
    }
    const handleAddress2Change = (value) => {
        setAddress2(value)
    }
    const handleCityChange = (value) => {
        setCity(value)
    }
    const handlePostalCodeChange = (value) => {
        setPostalCode(value)
    }
    const handleCountryChange = (value) => {
        setCountry(value)
    }

    const { control, handleSubmit, watch, getValues, setValue,
        formState: { errors } } = useForm({
        defaultValues: {
            values: ''
        }
    })

    const onSubmit = (data) => {
console.log(data)
        switch (pmtVendor) {
            case 'PayGate':
                // Start the Process.  Save the transaction and get the payment request ID.
                axios({
                    method: 'post',
                    url: `${apiURL}/api/gateway/payGatePayment`,
                    data: qs.stringify({
                        value: props.value,
                        uid: userAuth.mopriUID,
                        email: userAuth.email,
                        currencyCode: mopriSettings.internationalSettings.localizationCountry,
                        baseURL: window.location.origin
                    }),
                    config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
                })
                    .then((response) => {
                        if (response && response.data.error) {
                            myToast.current.show({severity: 'error', summary: 'ERROR', detail: response.data.errorMessage, life: 5000});
                        } else {
                            myToast.current.show({severity: 'success', summary: 'Success', detail: 'You will be redirected to PayGate to complete the topup.', life: 5000});
                            redirectToPayGate(response.data.payGateResponse)
                        }
                        setSubmitButtonDisabled(false)
                    })
                    .catch((e) => {
                        myToast.current.show({severity: 'error', summary: 'ERROR', detail: 'An unspecified error took place while attempting to do the PayGate checkout..', life: 5000});
                        setSubmitButtonDisabled(false)
                        console.log(e);
                    });
                break;

            case 'PayFast':
                axios({
                    method: 'post',
                    url: `${apiURL}/api/gateway/payFastPayment`,
                    data: qs.stringify({
                        value: props.value,
                        uid: userAuth.mopriUID,
                        email: userAuth.email,
                        currencyCode: mopriSettings.internationalSettings.localizationCountry,
                        baseURL: window.location.origin
                    }),
                    config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
                })
                    .then((response) => {
                        if (response && response.data.error) {
                            myToast.current.show({severity: 'error', summary: 'ERROR', detail: response.data.errorMessage, life: 5000});
                        } else {
                            myToast.current.show({severity: 'success', summary: 'Success', detail: 'You will be redirected to PayFast to complete the topup.', life: 5000});
                            //redirectToPayGate(response.data.payGateResponse)
                            console.log('RESPONSE', response.data)
                        }
                        setSubmitButtonDisabled(false)
                    })
                    .catch((e) => {
                        myToast.current.show({severity: 'error', summary: 'ERROR', detail: 'An unspecified error took place while attempting to do the PayFast checkout..', life: 5000});
                        setSubmitButtonDisabled(false)
                        console.log(e);
                    });
                break;

            default:
                console.error('PAYMENT METHOD NOT FOUND')
                break;
        }

    };

    const redirectToPayGate = (payGateResponse) => {
        const form = document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('action', 'https://secure.paygate.co.za/payweb3/process.trans');

        const input1 = document.createElement('input');
        input1.setAttribute('name', 'PAY_REQUEST_ID');
        input1.setAttribute('value', payGateResponse.PAY_REQUEST_ID);
        input1.setAttribute('hidden', true);

        const input2 = document.createElement('input');
        input2.setAttribute('name', 'CHECKSUM');
        input2.setAttribute('value', payGateResponse.CHECKSUM);
        input2.setAttribute('hidden', true);

        form.appendChild(input1);
        form.appendChild(input2);

        document.body.appendChild(form);

        form.submit();
    }
    console.log(mopriSettings.gatewaySettings.payFastActive)
    console.log(mopriSettings.internationalSettings.localizationCountry)
    return (
        <>
            <Toast ref={myToast} />

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid">
                    <div className="col-9">
                        <Controller
                            name="value"
                            control={control}
                            rules={{ required: 'Value is required.' }}
                            render={({ field }) => (
                                <>
                                    <div>How would you like to pay?</div>
                                    <div style={{paddingTop: '20px'}}>
                                        {(mopriSettings.gatewaySettings.sticittActive && mopriSettings.internationalSettings.localizationCountry === 'ZAF') &&
                                            <div className="grid">
                                                <div className="col-12">
                                                    <div className="row" style={{width: '30px', float: 'left', paddingTop: '10px'}}>
                                                        <div className="flex align-items-center">
                                                            <RadioButton inputId="f1" {...field} value="Sticitt" checked={field.value === 'Sticitt'} defaultValue={null}
                                                                         onClick={(e) => updatePmtVendor('sticitt')}
                                                            />
                                                            <label htmlFor="f1" className="ml-1 mr-3" style={{paddingLeft: '10px'}}>
                                                                Pay with Sticitt
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }


                                        {(isBoolean(mopriSettings.gatewaySettings.payFastActive) && mopriSettings.internationalSettings.localizationCountry === 'ZAF') &&
                                            <div className="grid">
                                                <div className="col-12">
                                                    <div className="row" style={{width: '30px', float: 'left', paddingTop: '10px'}}>
                                                        <RadioButton inputId="f2" {...field} value="payFast" checked={field.value === 'payFast'} defaultValue={null}
                                                                     onClick={(e) => updatePmtVendor('PayFast')}
                                                        />
                                                    </div>
                                                    <div style={{float: 'left'}}>
                                                        <label htmlFor="f2" className="ml-1 mr-3" style={{paddingLeft: '30px'}}>
                                                            <img src="img/payfast-logo.png" alt="PayGate" height={"50px"}/>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {(mopriSettings.gatewaySettings.payGateActive && (mopriSettings.internationalSettings.localizationCountry === 'ZAF' || mopriSettings.internationalSettings.localizationCountry === 'NAD')) &&
                                            <div className="grid">
                                                <div className="col-12">
                                                    <div className="row" style={{width: '30px', float: 'left', paddingTop: '10px'}}>
                                                        <RadioButton inputId="f3" {...field} inputRef={field.ref} value="PayGate" checked={field.value === 'PayGate'}
                                                                     onClick={(e) => updatePmtVendor('PayGate')}
                                                        />
                                                    </div>
                                                    <div style={{float: 'left'}}>
                                                        <label htmlFor="f3" className="ml-1 mr-3" style={{paddingLeft: '10px'}}>
                                                            <img src="img/PayGate-Card-Brand-Logos-PayGate.png" alt="PayGate" height={"50px"}/>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {(mopriSettings.gatewaySettings.snapScanActive && mopriSettings.internationalSettings.localizationCountry === 'ZAF') &&
                                            <div className="flex align-items-center">
                                                <RadioButton inputId="f4" {...field} inputRef={field.ref} value="snapscan" checked={field.value === 'snapscan'}
                                                             onClick={(e) => updatePmtVendor('snapscan')}
                                                />
                                                <label htmlFor="f4" className="ml-1 mr-3" style={{paddingLeft: '10px'}}>
                                                    Pay with Snapscan
                                                </label>
                                            </div>
                                        }

                                        {(mopriSettings.gatewaySettings.elavonActive && mopriSettings.internationalSettings.localizationCountry === 'IRL') &&
                                            <div className="flex align-items-center">
                                                <RadioButton inputId="f5" {...field} inputRef={field.ref} value="elavon" checked={field.value === 'elavon'}
                                                             onClick={(e) => updatePmtVendor('elavon')}
                                                />
                                                <label htmlFor="f5" className="ml-1 mr-3" style={{paddingLeft: '10px'}}>
                                                    Pay with Elavon
                                                </label>
                                            </div>
                                        }

                                        {(mopriSettings.gatewaySettings.opayoActive && (mopriSettings.internationalSettings.localizationCountry === 'IRL' || mopriSettings.internationalSettings.localizationCountry === 'GBR')) &&
                                            <div className="flex align-items-center">
                                                <RadioButton inputId="f6" {...field} inputRef={field.ref} value="opayoActive" checked={field.value === 'opayoActive'}
                                                             onClick={(e) => updatePmtVendor('opayoActive')}
                                                />
                                                <label htmlFor="f6" className="ml-1 mr-3" style={{paddingLeft: '10px'}}>
                                                    Pay with Opayo
                                                </label>
                                            </div>
                                        }
                                                </div>
                                            </>
                                            )}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <OrderSummary
                                            value={props.value.toFixed(2)}
                                        />
                                    </div>

                                    {(!mopriSettings.gatewaySettings.snapScanActive && !mopriSettings.gatewaySettings.payFastActive && !mopriSettings.gatewaySettings.sticittActive && !mopriSettings.gatewaySettings.payGateActive && (
                                            mopriSettings.internationalSettings.localizationCountry === 'ZAF' || mopriSettings.internationalSettings.localizationCountry === 'NAD')) &&
                                        <div className="col-12">
                                            No payment methods available. Please contact your system administrator.
                                        </div>
                                    }
                                    {((!mopriSettings.gatewaySettings.elavonActive && !mopriSettings.gatewaySettings.opayoActive) && mopriSettings.internationalSettings.localizationCountry === 'IRL') &&
                        <div className="col-12">
                            <ErrorMessage message="No payment methods available.  Please contact your system administrator." />
                        </div>
                    }
                    {(!mopriSettings.internationalSettings.localizationCountry) &&
                        <div className="col-12">
                            <ErrorMessage message="No country setup for Mopri.  Please contact your system administrator." />
                        </div>
                    }

                    {pmtVendor &&
                        <>
                            <div className="col-12">
                                <div className="p-fluid grid">
                                    <div className="field col-8"> </div>
                                    <div className="field col-2">
                                        <Button label="Cancel"
                                                severity="info" type="button"
                                                disabled={submitButtonDisabled}
                                                onClick={() => props.cancelTopup()}
                                        />
                                    </div>
                                    <div className="field col-2">
                                        <Button label="Checkout" severity="success"
                                                disabled={submitButtonDisabled}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="p-fluid grid">
                                    <div className="field col-8"></div>
                                    <div className="field col-4" style={{fontWeight: 'bold', cursor: 'pointer', float: 'right'}}
                                         onClick={() => showTermsConditions()}
                                    >
                                        Terms & Conditions
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                </div>
            </form>
            <Dialog header="Terms & Conditions" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                <div dangerouslySetInnerHTML={{__html: mopriSettings.termsConditions}} />
            </Dialog>
        </>
    )

}
