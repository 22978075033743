import axios from "axios";
import qs from "qs";
import configureStore from "../Store/store";


export const UPDATE_PMT_VALUE = 'UPDATE_PMT_VALUE';
export const CHANGE_PAYMENT_TYPE = 'CHANGE_PAYMENT_TYPE';
export const RESET_TOPUP_AMOUNT = 'RESET_TOPUP_AMOUNT';
export const COMPLETE_STICITT_PAYMENT = 'COMPLETE_STICITT_PAYMENT';
export const CANCEL_TOPUP = 'CANCEL_TOPUP';
// SnapScan Actions
export const SHOW_SNAPSCAN_QR_CODE = 'SHOW_SNAPSCAN_QR_CODE';
export const HIDE_SNAPSCAN_QR_CODE = 'HIDE_SNAPSCAN_QR_CODE';

// Sticitt ACTIONS
export const UPDATE_STICITT_TOKEN = 'UPDATE_STICITT_TOKEN';  // Save Token to state
export const UPDATE_STICITT_PAYMENT = 'UPDATE_STICITT_PAYMENT';  // Save the payment details to state
export const VERIFY_STICITT_PAYMENT = 'VERIFY_STICITT_PAYMENT';  // Update that the transaction is completed.

export const TOPUP_REQUESTED = 'TOPUP_REQUESTED';
export const TOPUP = 'TOPUP';
export const REMOVE_SNAPSCAN = 'REMOVE_SNAPSCAN';
export const TOPUP_LOADED = 'TOPUP_LOADED';
export const UPDATE_PMT_TYPE = 'UPDATE_PMT_TYPE';
export const CLEAR_TOPUP_STATE = 'CLEAR_TOPUP_STATE';

export const TOPUP_GUID = 'TOPUP_GUID';
export const SHOW_3D_SECURE = 'SHOW_3D_SECURE';



// Update the payment value if the customer decides to enter a custom amount to pay
export function updatePaymentValue(values, dispatch) {
    //let sticittToken = configureStore.getState().topup.sticittToken;

    // Get a Sticitt payment Token if we dont have one yet
    /*if (!sticittToken) {
        axios({
            method: 'POST',
            url: 'https://moprimail.mopri.net/sticittToken.php',
            data: qs.stringify({
                client_id: 'sample-host',
                client_secret: 'b8smhDYhho0T1uBSaZp7grRx20GPTEgseuDNVQQwcxHDKjkAmU4iElkiEZqWSsg8',
                grant_type: 'client_credentials',
                scope: 'pay-sdk-api'
            }),
            config: {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    "Access-Control-Allow-Origin": "*"
                }
            }
        })
            .then((response) => {
                // TODO: Create an entry for the payment in the Mopri DB.  Set status to incomplete.

                // Get a Token for Authentication from sticitt
                if (response.status === 200) {
                    dispatch(createAction(UPDATE_STICITT_TOKEN, response.data.access_token));
                    axios({
                        method: 'POST',
                        url: 'https://moprimail.mopri.net/createSticittPayment.php',
                        data: qs.stringify({
                            MerchantID: 'a3044ff2-2a2e-4885-acaf-81d42fb3c8c6',
                            Amount: values.customPmtValue * 100,
                            Reference: 'TestPayment',
                            bearerToken: `Authorization: Bearer ${response.data.access_token}`
                        }),
                        config: {
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded',
                                "Access-Control-Allow-Origin": "*"
                            }
                        }
                    })
                        .then((response) => {
                            dispatch(createAction(UPDATE_STICITT_PAYMENT, response.data));
                        })
                        .then(() => {
                            // Create an incomplete topup transaction.
                            let siteID = configureStore.getState().userAuth.siteID;
                            let mopriUID = configureStore.getState().userAuth.mopriUID;
                            let sticittPaymentID = configureStore.getState().topup.sticitt.paymentID;
                            axios({
                                method: 'POST',
                                url: '/api/sticittpendingtransaction.asp',
                                data: qs.stringify({
                                    mopriUID: mopriUID,
                                    amount: values.customPmtValue,
                                    sticittPaymentID: sticittPaymentID,
                                    siteID: siteID,
                                }),
                                config: {
                                    headers: {
                                        'Content-Type': 'application/x-www-form-urlencoded',
                                        "Access-Control-Allow-Origin": "*"
                                    }
                                }
                            })
                        })
                        .catch((error) => {
                            console.log('error getting payment...');
                        })
                }
            })
            .catch((error) => {
                console.log('error getting token...');
                console.log(error);
            });
    } else {
        console.log(' we already have a sticitt token');
    }*/
    dispatch(createAction(UPDATE_PMT_VALUE, values));
    return dispatch;
}

export const changePaymentType = () => {
    return dispatch => {
      //  history.push('/topup')
        dispatch({
            type: CHANGE_PAYMENT_TYPE
        });
    }
};

export const verifyPayment = (values, dispatch) => {
    let userName = configureStore.getState().userAuth.userName;
    let creditBalance = configureStore.getState().userAuth.creditBalance;
    axios({
        method: 'post',
        url: '/api/snapscan-pmt-verify.asp',
        data: qs.stringify({
            userName: userName,
            creditBalance: creditBalance
        }),
        config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
    })
        .then(function (response) {
            //console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        });

    return dispatch
};

/*export const createPendingTransaction = (values, dispatch) => {
    let userName = configureStore.getState().userAuth.userName;
    let creditBalance = configureStore.getState().userAuth.creditBalance;
    axios({
        method: 'post',
        url: '/api/pmt/createPendingTransaction.asp',
        data: qs.stringify({
            topUpValue: userName,
            paymentGatewaysID: creditBalance
        }),
        config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
    })
        .then(function (response) {
            //console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        });

    return dispatch
};*/


export const topup = (values, dispatch) => {
    let customPmtValue = configureStore.getState().topup.customPmtValue;

    dispatch(createAction(TOPUP_REQUESTED, values));
    switch (values.pmtType) {
        case 'snapscan': {
            // Convert the price to cents for SnapScan
            let priceCents = (customPmtValue * 100);
            // Generate a random order id
            let orderID = ('Mopri'+Math.floor((Math.random() * 100000000) +1));
            let snapData = {priceCents: priceCents, orderID: orderID};

            dispatch(createAction(SHOW_SNAPSCAN_QR_CODE, snapData));
          //  history.push('/snapscan-confirm');
            break;
        }
        case 'sticitt': {
            //let sticittToken = configureStore.getState().topup.sticittToken;

            // Get a Sticitt payment Token if we dont have one yet
            /*if (!sticittToken) {
                axios({
                    method: 'POST',
                    url: 'https://moprimail.mopri.net/sticittToken.php',
                    data: qs.stringify({
                        client_id: 'sample-host',
                        client_secret: 'b8smhDYhho0T1uBSaZp7grRx20GPTEgseuDNVQQwcxHDKjkAmU4iElkiEZqWSsg8',
                        grant_type: 'client_credentials',
                        scope: 'pay-sdk-api'
                    }),
                    config: {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            "Access-Control-Allow-Origin": "*"
                        }
                    }
                })
                    .then((response) => {
                        // TODO: Create an entry for the payment in the Mopri DB.  Set status to incomplete.

                        // Get a Token for Authentification from sticitt
                        if (response.status === 200) {
                            dispatch(createAction(UPDATE_STICITT_TOKEN, response.data.access_token));
                            axios({
                                method: 'POST',
                                url: 'https://moprimail.mopri.net/createSticittPayment.php',
                                data: qs.stringify({
                                    MerchantID: 'a3044ff2-2a2e-4885-acaf-81d42fb3c8c6',
                                    Amount: customPmtValue * 100,
                                    Reference: 'TestPayment',
                                    bearerToken: `Authorization: Bearer ${response.data.access_token}`
                                }),
                                config: {
                                    headers: {
                                        'Content-Type': 'application/x-www-form-urlencoded',
                                        "Access-Control-Allow-Origin": "*"
                                    }
                                }
                            })
                                .then((response) => {
                                    dispatch(createAction(UPDATE_STICITT_PAYMENT, response.data));
                                })
                                .catch((error) => {
                                    console.log('error getting payment...');
                                    console.log(error);
                                })
                        }
                    })
                    .catch((error) => {
                        console.log('error getting token...');
                        console.log(error);
                    });
            } else {
               console.log(' we already have a sticitt token');
            }*/

            break;
        }
        // PayFast Payment Types
        case 'eft':
        case 'cc':
        case 'dc':
        case 'za':
        case 'mp':
        case 'mc':
        case 'sc':
         //   history.push('/payfast-confirm');
            break;
        case 'elavon':
          //  history.push('/elavon-confirm');
            break;
        /*case 'opayo':
          //  history.push('/opayo-confirm');
            break;*/

        default: {
           // NotificationManager.error('There was an error while attempting to complete your payment.', 'Error');
        }
    }
};

/*export function getSticittPaymentID() {

    let customPmtValue = configureStore.getState().topup.customPmtValue;
    let sticittToken = configureStore.getState().topup.sticittToken;

    return dispatch => {
        if (!sticittToken) {
            axios({
                method: 'POST',
                url: 'https://moprimail.mopri.net/sticittToken.php',
                data: qs.stringify({
                    client_id: 'sample-host',
                    client_secret: 'b8smhDYhho0T1uBSaZp7grRx20GPTEgseuDNVQQwcxHDKjkAmU4iElkiEZqWSsg8',
                    grant_type: 'client_credentials',
                    scope: 'pay-sdk-api'
                }),
                config: {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        "Access-Control-Allow-Origin": "*"
                    }
                }
            })
                .then((response) => {
                    // TODO: Create an entry for the payment in the Mopri DB.  Set status to incomplete.

                    // Get a Token for Authentication from sticitt
                    if (response.status === 200) {
                        dispatch({
                            type:  UPDATE_STICITT_TOKEN,
                            values: response.data.access_token
                        });
                        // dispatch(createAction(UPDATE_STICITT_TOKEN, response.data.access_token));
                        axios({
                            method: 'POST',
                            url: 'https://moprimail.mopri.net/createSticittPayment.php',
                            data: qs.stringify({
                                MerchantID: 'a3044ff2-2a2e-4885-acaf-81d42fb3c8c6',
                                Amount: customPmtValue * 100,
                                Reference: 'TestPayment',
                                bearerToken: `Authorization: Bearer ${response.data.access_token}`
                            }),
                            config: {
                                headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded',
                                    "Access-Control-Allow-Origin": "*"
                                }
                            }
                        })
                            .then((response) => {
                                dispatch({
                                    type:  UPDATE_STICITT_PAYMENT,
                                    values: response.data
                                });

                                // dispatch(createAction(UPDATE_STICITT_PAYMENT, response.data));
                            })
                            .catch((error) => {
                                console.log('error getting payment...');
                                console.log(error);
                            })
                    }
                })
                .catch((error) => {
                    console.log('error getting token...');
                    console.log(error);
                });
        }
    }
};*/


export const verifySticittPayment = (values, dispatch) => {
    let siteID = configureStore.getState().userAuth.siteID;
    let userName = configureStore.getState().userAuth.userName;
    let sticittToken = configureStore.getState().topup.sticittToken;
    let sticittPaymentID = configureStore.getState().topup.sticitt.paymentID;

    //  Verify the payment
    axios({
        method: 'POST',
        url: 'https://moprimail.mopri.net/verifySticittPayment.php',
        data: qs.stringify({
            bearerToken: `Authorization: Bearer ${sticittToken}`,
            paymentID: sticittPaymentID
        }),
        config: {headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                "Access-Control-Allow-Origin": "*"
                }
            }
    })
        .then((response) => {
            axios({
                method: 'POST',
                url: '/api/sticittPaymentUpdate.asp',
                data: qs.stringify({
                    siteID: siteID,
                    userName: userName,
                    amount: response.data.amount / 100,  // Convert from cents to rands.
                    authorizedAt: response.data.authorizedAt,
                    canceledAt: response.data.canceledAt,
                    createdAt: response.data.createdAt,
                    cardPaymentFee: response.data.cardPaymentFee / 100,  // convert from cents to rands.
                    clientID: response.data.clientID,
                    merchantAccountReference: response.data.merchantAccountReference,
                    merchantID: response.data.merchantID,
                    merchantName: response.data.merchantName,
                    paymentID: response.data.paymentID,
                    reference: response.data.reference,
                    status: response.data.status
                }),
                config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
            })
                .then((response) => {
                    switch (response.data.transactionStatus) {
                        case 0:
                          //  NotificationManager.success('Your payment status is PENDING and will be updated once completed.', 'Success');
                          //  history.push('/payfast-return');
                            return dispatch => {
                                dispatch({
                                    type: COMPLETE_STICITT_PAYMENT,
                                    values: response.data
                                });
                            };
                        case 1:
                         //   NotificationManager.success('Your payment status is AUTHORIZED and will be updated once completed.', 'Success');
                         //   history.push('/payfast-return');
                            return dispatch => {
                                dispatch({
                                    type: COMPLETE_STICITT_PAYMENT,
                                    values: response.data
                                });
                            };
                        case 2:
                          //  NotificationManager.success('You have successfully completed the topup transaction.', 'Success');
                          //  history.push('/payfast-return');
                            return dispatch => {
                                dispatch({
                                    type: COMPLETE_STICITT_PAYMENT,
                                    values: response.data
                                });
                            };
                        case 3:
                         //   NotificationManager.success('Your payment could not be completed and has expired.', 'Error');
                         //   history.push('/payfast-cancel');
                            return dispatch => {
                                dispatch({
                                    type: COMPLETE_STICITT_PAYMENT,
                                    values: response.data
                                });
                            };
                        case 4:
                          //  NotificationManager.error('You CANCELLED your topup transaction.', 'Error');
                         //   history.push('/payfast-cancel');
                            return dispatch => {
                                dispatch({
                                    type: COMPLETE_STICITT_PAYMENT,
                                    values: response.data
                                });
                            };
                        default:
                          //  NotificationManager.error('There was an error while updating your payment.', 'Error');
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        })
        .catch((error) => {
            console.log(error);
        })
};


export const updatePmtType = (values) => {
    return dispatch => {
        dispatch({
            type: UPDATE_PMT_TYPE,
            values: values.pmtType
        });
    }
};

export const resetTopupAmount = () => {
    return dispatch => {
        dispatch({
            type: RESET_TOPUP_AMOUNT
        });
    }
};



//create_action
export const createAction = (type, values) => {
    return {
        type,
        values
    };
};
