import axios from 'axios';

export const GET_USER = 'GET_USER';
export const UPDATE_LOGO_URL = 'UPDATE_LOGO_URL';
export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const UPDATE_AUTH_LOGIN_STATE = 'UPDATE_AUTH_LOGIN_STATE';
export const UPDATE_USER_BALANCE = 'UPDATE_USER_BALANCE';

export const PASSWORD_RESET = 'PASSWORD_RESET';
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR';
export const CHANGE_PRINTER_PIN = 'CHANGE_PRINTER_PIN';
export const CHANGE_PRINTER_PIN_ERROR = 'CHANGE_PRINTER_PIN_ERROR';
export const LOGOUT = 'LOGOUT';

export const START_UPDATE_REALTIME_DATA = 'START_UPDATE_REALTIME_DATA';
export const FETCH_REALTIME_DATA = 'FETCH_REALTIME_DATA';

export const START_REGISTER = 'START_REGISTER';
export const CLIENT_REGISTERED = 'CLIENT_REGISTERED';
export const REGISTER_CLIENT = 'REGISTER_CLIENT';
export const REGISTER_COMPLETE = 'REGISTER_COMPLETE';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const VERIFY_EMAIL_ADDRESS = 'VERIFY_EMAIL_ADDRESS';
export const EMAIL_ADDRESS_NOT_VERIFIED = 'EMAIL_ADDRESS_NOT_VERIFIED';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';

export const UPDATE_AUTH_DETAILS_STATE = 'UPDATE_AUTH_DETAILS_STATE';

export const FETCH_MOPRI_PIN_SETTINGS = 'FETCH_MOPRI_PIN_SETTINGS';
export const UPDATE_COMPANY_LOGO_URL = 'UPDATE_COMPANY_LOGO_URL';


export const login = (values) =>
    ({ type: LOGIN, values: values })

export const logout = () =>
    ({ type: LOGOUT })

export const updateUserBalance = (values) =>
    ({ type: UPDATE_USER_BALANCE, values: values})
