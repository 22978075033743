import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";


export const Footer = () => {
    const [visible, setVisible] = useState(false);
    const mopriSettings = useSelector(state => state.mopriSettings);
    const currentDate = new Date();
    const year = currentDate.getFullYear();

    const showTermsConditions = () => {
        setVisible(true)
    }

    return (
        <div style={{position: "fixed", left: "0", bottom: "0", width: "100%", background: "#6c6969", padding: "5px", color: '#FFFFFF', fontSize: '12px'}}>
            <div className="grid">
                <div className="col-5">
                    © {year} Mopri. All rights reserved.
                </div>
                <div className="col-4">
                    <span style={{cursor: 'pointer'}} onClick={() => showTermsConditions()}>Terms & Conditions</span>
                </div>
                <div className="col-3" style={{display: 'flex', justifyContent: 'flex-end'}}>
                    V: {process.env.REACT_APP_VERSION}
                </div>
            </div>

            <Dialog header="Terms & Conditions" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                <div dangerouslySetInnerHTML={{__html: mopriSettings.termsConditions}} />
            </Dialog>

        </div>
    )
}