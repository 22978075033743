import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Toast} from "primereact/toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faKey} from "@fortawesome/free-solid-svg-icons";
import {Controller, useForm} from "react-hook-form";
import {Button} from "primereact/button";
import {InputNumber} from "primereact/inputnumber";
import axios from "axios";
import {updateCountryLocalization} from "../../../Actions/MopriSettingActions";
import {ProgressSpinner} from "primereact/progressspinner";

export const TopupValueForm = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)
    const [customPmtValue, setCustomPmtValue] = useState(0);
    const dispatch = useDispatch()
    const mopriSettings = useSelector(state => state.mopriSettings);
    const themeSettings = useSelector(state => state.theme);
    const internationalSettings = mopriSettings.internationalSettings;
    const gatewaySettings = mopriSettings.gatewaySettings;
    const apiURL = useSelector(state => state.mopriSettings.mopriAPIURL);
    //const sticittActive = gatewaySettings.sticittActive;
    const myToast = useRef(null)

    useEffect(() => {
        if (!internationalSettings.localizationCountry) {
            getLocalizationSettings()
        } else {
            setIsLoading(false)
        }
    }, []);

    const getLocalizationSettings = () => {
        axios({
            method: 'get',
            url: `${apiURL}/api/getLocalizationSettings`,
            config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
        })
            .then((response) => {
                if (response.data.error) {
                    myToast.current.show({severity: 'error', summary: 'ERROR', detail: response.data.errorMessage, life: 5000});
                } else {
                    dispatch(updateCountryLocalization({
                        countryCode: response.data.localizationCountry,
                        countryCurrency: response.data.localizationCurrency,
                    }))
                    setIsLoading(false)
                }
            })
            .catch(function (error) {
                setIsLoading(false)
                myToast.current.show({severity: 'error', summary: 'ERROR', detail: 'There was an error while retrieving the localization settings.', life: 5000});
                console.log(error);
            });
    }

    const { control, handleSubmit, watch, getValues,
        formState: { errors } } = useForm({
        defaultValues: {
            customPmtValue: null
        }
    })

    const onSubmit = (data) => {
        setSubmitButtonDisabled(true)
    }

    const handleTopUpValueUpdate = (value) => {
        if (value >= gatewaySettings.minTopupAmount) {
            setCustomPmtValue(value);
            setSubmitButtonDisabled(false)
        } else {
            myToast.current.show({severity: 'error', summary: 'ERROR', detail: `Your top up amount of ${value} is less than the minimum ${gatewaySettings.minTopupAmount} amount required.`, life: 5000});
        }
    }

    const setSelectPmtGateway = () => {
        props.updatePaymentValue(customPmtValue)
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    return (
        <>
            <Toast ref={myToast} />
            <div className="p-fluid grid formgrid">
                <div className="field col-12">
                    <h4><FontAwesomeIcon icon={faKey} style={{paddingRight: '10px'}}/> Topup Printing Credit</h4>
                </div>
            </div>
            {isLoading ?
                <div style={{display: "flex", justifyContent: "center"}}>
                    <ProgressSpinner style={{width: '80px', height: '80px'}}/>
                </div>
            :
                <div className="p-fluid grid formgrid">
                    <div className="field col-12">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="p-fluid grid formgrid">
                                <div className="field col-6" style={{paddingTop: '27px'}}>
                                <span className="p-float-label">
                                    <Controller name="customPmtValue" control={control}
                                                render={({field, fieldState}) => (
                                                    <InputNumber id={field.name} inputRef={field.ref} value={field.value} onBlur={field.onBlur}
                                                                 mode="currency" currency={internationalSettings.localizationCountry} currencyDisplay="code"
                                                                 onValueChange={(e) => {
                                                                     field.onChange(e);
                                                                     handleTopUpValueUpdate(e.target.value);
                                                                 }}
                                                    />
                                                )}
                                    />
                                    <label htmlFor="customPmtValue">Enter the amount you want to top up with?</label>
                                </span>
                                    {getFormErrorMessage('customPmtValue')}
                                </div>
                                <div className="field col-2" style={{paddingTop: '27px'}}>
                                    <Button label="Confirm"
                                            severity="warning"
                                            disabled={submitButtonDisabled}
                                            onClick={(e) => setSelectPmtGateway()}
                                            style={themeSettings.buttonColor ? {background: `#${themeSettings.buttonColor}`} : {background: '#f97316'}}
                                    />
                                </div>
                                <div className="field col-4" style={{paddingTop: '27px'}}></div>
                            </div>

                            {/*<div className="col-12">
                            <div className="p-fluid grid">
                                <div className="field col-12 md:col-10"> </div>
                                <div className="field col-12 md:col-2">

                                </div>
                            </div>
                        </div>*/}
                        </form>
                    </div>
                </div>
            }
        </>
    )
}
