import React, {useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faKey} from "@fortawesome/free-solid-svg-icons";
import {Toast} from "primereact/toast";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import axios from "axios";
import qs from "qs";
import {faDownload} from "@fortawesome/free-solid-svg-icons/faDownload";

export const Downloads = () => {
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
    const userAuth = useSelector(state => state.userAuth);
    const myToast = useRef(null)
    const dispatch = useDispatch();
    const apiURL = useSelector(state => state.mopriSettings.mopriAPIURL);
    const themeSettings = useSelector(state => state.theme);


    return (
        <>
            <Toast ref={myToast} />
            <div className="p-fluid grid formgrid">
                <div className="field col-12">
                    <h4><FontAwesomeIcon icon={faDownload} style={{paddingRight: '10px'}}/> Downloads</h4>
                </div>
            </div>


            <a href={`${window.location.origin}/downloads/PDAgentInstaller.msi`} target="_blank">
                <Button label="Guest BYOD Printing Software" icon="pi pi-cloud-download"
                        style={themeSettings.buttonColor ? {background: `#${themeSettings.buttonColor}`} : {background: '#f97316'}}
                />
            </a>

        </>
    )
}

