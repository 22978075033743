import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from "react-dom/client";

import store, {Persistor} from './Store/store';
import ErrorFallback from "./Components/common/Error/ErrorFallback";
import 'primereact/resources/themes/lara-light-amber/theme.css';
//import 'primereact/resources/themes/bootstrap4-dark-blue/theme.css'
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css'
//import './App.css'; //override css,
import {BrowserRouter} from "react-router-dom";
import {App} from './app';
import { PersistGate } from 'redux-persist/integration/react'




/*const persistedState = loadState();
// Redux store
const store = configureStore(persistedState);*/


/*store.subscribe(() => {
    saveState({
        ...store.getState()
    });
});*/

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={Persistor} >
            <BrowserRouter>
                <ErrorFallback>
                    <App />
                </ErrorFallback>
            </BrowserRouter>
        </PersistGate>
    </Provider>
)
    //document.getElementById('root'));
//serviceWorker.register();
