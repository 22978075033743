import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from "react-redux";
import {Card} from "primereact/card";
//import {FETCH_MOPRI_PIN_SETTINGS, fetchMopriPinSettings} from '../../Actions/AuthActions';
import {GeneratePrinterPIN} from "./Widgets/GeneratePrinterPIN";
import {ChangePassword} from "./Widgets/ChangePassword";
import axios from "axios";
import {Toast} from "primereact/toast";
import {Downloads} from "./Widgets/Downloads";



export const AccountSettings = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [allowPINChange, setAllowPINChange] = useState(true);
    const myToast = useRef(null)
    const apiURL = useSelector(state => state.mopriSettings.mopriAPIURL);

    useEffect(() => {
        fetchMopriPinSettings()
    }, []);

    const fetchMopriPinSettings = () => {
        const url = `${apiURL}/api/getMopriSiteSettings`;
        axios.get(url)
            .then((response) => {
                if (response && response.data.error) {
                    myToast.current.show({severity: 'error', summary: 'ERROR', detail: response.data.errorMessage, life: 5000});
                } else {
                    setAllowPINChange(response.data?.pinSettings[0]?.pinChange === 1)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }


    return(
        <Card title="Account Settings">
            <Toast ref={myToast} />
            <div className="grid">
                <div className="col-12">
                    <ChangePassword/>
                </div>
                {allowPINChange &&
                    <div className="col-12">
                        <GeneratePrinterPIN/>
                    </div>
                }
                <div className="col-12">
                    <Downloads />
                </div>
            </div>
        </Card>
)
}

