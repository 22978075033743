


const initialState = {
    errorImageUrl: './img/mopri_error_image.jpg',
};


export default (state = initialState, action) => {
    switch (action.type) {


        default:
            return state
    }
};
