export function generateId() {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 15; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}

export function generateShortId() {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 5; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}

export const isValidEmailAddress = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export const isBoolean = (value) => {
    switch (value) {
        case 'true':
        case true:
        case '1':
        case 1:
            return true
        default:
            return false
    }
}

export const nodeMailerErrorCodes = (code) => {
    switch (code) {
        case 'EENVELOPE':
            return "Unable to determine the sender or recipient of the message.";
        case 'EENVELOPEFORMAT':
            return "The format of the sender or recipient email address is invalid.";
        case 'EENCODE':
            return "Unable to encode the message content correctly."
        case 'EMESSAGEID':
            return "Unable to generate a unique message ID."
        case 'ETXTBSY':
            return "The message file is locked by another process."
        case 'EFILE':
            return "Unable to access the message file."
        case 'ECONNECTION':
            return "Unable to establish a connection to the email server."
        case 'EAUTH':
            return "Authentication failed during the attempt to connect to the email server."
        case 'ENOAUTH':
            return "No authentication credentials were provided for the connection to the email server."
        case 'ETLS':
            return "Unable to start a TLS connection with the email server."
        case 'ESTARTTLS':
            return "Unable to start STARTTLS during the connection to the email server."
        case 'EUPGRADE':
            return "Unable to upgrade the TLS connection to a secure connection."
        case 'EENOTFOUND':
            return "Unable to find the specified email server."
        case 'EENOTEMPTY':
            return "Unable to send an empty message."
        case 'EMSGBIG':
            return "The message is too large to be sent."
        case 'EINVALIDDATE':
            return "The message date is not valid or not in the correct format."
        case 'ETOOMANYTOS':
            return "Too many recipients specified in the message."
        case 'ETOOMANYMSGS':
            return "Too many messages sent in a single call to Nodemailer."
        case 'EDNS':
            return "Indicates an issue with resolving the hostname (e.g., smtp.example.com) to an IP address using the Domain Name System (DNS)."
        default:
            return "No additional data for this error code found."
    }
}